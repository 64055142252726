import React, { useRef, useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'utils';

export default function PlatformReports () {
  const { t } = useTranslation();
  const componentIsMounted = useRef(true);
  let query = useQuery();
  const [loading, setLoading] = useState(false);
  const [searchQ, setSearchQ] = useState(query.get('q') || '');

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      <div className="max-w-full">
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">{t('Platform Reports')}</h2>
          </div>
        </div>
        <div className="md:flex md:items-center md:justify-between my-5">
        </div>
      </div>
    </div>
  )
}
