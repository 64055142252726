import styled from 'styled-components';
import { format, parse } from "date-fns";

const Spacer = styled.div`
  height: ${(props) => props.height || '20px'};
  width: 100%;
`

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

function monthName(month, customFormat = "MMMM") {
  return format(parse(month, "M", new Date()), customFormat);
}

function cleanObject(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
}

export { Spacer, classNames, kFormatter, monthName, cleanObject }
