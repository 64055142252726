import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';

import { AuthContext } from 'stores/auth';

const LoginManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <div className="flex-1">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Login Manual</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
            </div>
            <div className="mt-5 border-t border-gray-200">
              <dl className="divide-y divide-gray-200">

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Using your Email and Password</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. Go to the landing page of the Client Portal.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Login/login-1.png"/>
                    <span className="flex-grow col-span-4">2. Fill in your Email address and Password.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Login/login-2.png"/>
                    <span className="flex-grow col-span-4">3. Click the Sign In button.</span>
                    <img src="/assets/images/UserManual/Login/login-3.png"/>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Using Google</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. Click the Continue with Google button.</span>
                    <img className="h-4/5 w-auto col-span-4" src="/assets/images/UserManual/Login/login-4.png"/>
                    <span className="flex-grow col-span-4">2. If your Google account is not in the choices, click Use another account button and Login with your Gmail and password. Otherwise, continue to the next step.</span>
                    <img className="h-4/5 w-auto col-span-4" src="/assets/images/UserManual/Login/login-6.png"/>
                    <span className="flex-grow col-span-4">3. Choose your Google account.</span>
                    <img className="h-4/5 w-auto col-span-4" src="/assets/images/UserManual/Login/login-5.png"/>
                  </dd>
                </div>

              </dl>
            </div>
            <div className="py-4 sm:py-5 sm:gap-4 sm:text-right">
              <Link to="/help" className="text-m font-medium text-blue-800">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>)
  });
}

export default LoginManual
