import React from 'react';
import { Link } from 'react-router-dom';

const BankSection = () => {
    return (<section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Bank Information
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">The following information is confidential. Please report immediately to an admin should any discrepancies occur.</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Bank Name</dt>
                    <dd className="mt-1 text-sm text-gray-900">Banco de Oro</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Country</dt>
                    <dd className="mt-1 text-sm text-gray-900">Philippines</dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Frequency</dt>
                    <dd className="mt-1 text-sm text-gray-900">Monthly</dd>
                </div>
                <div className="sm:col-span-1">
                <Link
                    to="/info/bank-info/form/us"
                    type="button"
                    className="inline-flex px-3 py-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Request Update
                  </Link>
                </div>
            </dl>
        </div>
        </div>
    </section>);
}

export default BankSection;
