import React from "react"
import { Link } from "react-router-dom"
import ProfileImage from "components/ProfileImage"
import { ChangePasswordButton } from "components/Buttons/ChangePasswordButton"
import { useTranslation } from 'react-i18next';

const Header = ({ user, isProfile }) => {
  const {t, i18n} = useTranslation();
  let displayName = user.name || user.email

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <ProfileImage className="h-16 w-16 rounded-full" user={user} />
            <span
              className="absolute inset-0 shadow-inner rounded-full"
              aria-hidden="true"
            />
          </div>
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{displayName}</h1>
          <p className="text-sm font-medium text-gray-500">
            {t('Last login on')}{" "}
            <time className="text-gray-900" dateTime="2020-08-25">
              April 7, 2022
            </time>
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <ChangePasswordButton user={user} />
        <Link
          type="button"
          to={isProfile ? `/profile/edit` : `/users/edit/${user.id}`}
          className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
        >
          {t('Edit')}
        </Link>
        {user.role === 'admin' &&
          <button
            type="button"
            className="cursor-not-allowed inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          >
            {t('Delete')}
          </button>
        }
      </div>
    </div>
  )
}

export default Header
