import BankUSForm from "./components/BankUSForm"

const Information = ({ client }) => {
  return (<>
    {/* Main container */}
    <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
      {/* Left */}
      <div className="grid grid-cols-2 gap-6 lg:col-span-2">
        <div className='col-span-2 bg-white rounded-lg shadow'>
          <BankUSForm client={client} />
        </div>
        <div className="col-span-2 bg-white rounded-lg shadow divide-y divide-gray-200">
          {/* put something here */}
        </div>
      </div>
      {/* Right */}
      <div className="grid grid-cols-1 gap-6 lg:col-span-1">

      </div>
    </div>
  </>)
}

export default Information