import React, { useContext} from 'react';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'stores/auth';
import TableofContents from './components/TableofContents';

const UserManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <TableofContents />
        </div>
      </div>
    </div>)
  });
}

export default UserManual
