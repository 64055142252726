import { getClientTeam, getUsers } from "api/expauth"
import { assignUserToClient } from "api/users"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import MemberInline from "./components/MemberInline"
import Spinner from "components/Icons/Spinner"
import { useTranslation } from 'react-i18next';

const Team = ({ uid, selectedAccount }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [members, setMembers] = useState([])
  const [users, setUsers] = useState([])
  const [assignedUser, setAssignedUser] = useState('');

  useEffect(()=>{
    setLoading(true)
    getClientTeam(uid)
      .then(res=>{
        setMembers(res.data.data)
        setLoading(false)
      })
      .catch(err=>{
        console.log(err.response)
      })

    if (!selectedAccount) {
      setLoading(true)
      getUsers().then(res=>{
        setUsers(res.data.results)
        setLoading(false)
      }).catch(err=>{
        console.log(err.response)
      })
    }
  }, [uid, selectedAccount]);

  const assignUser = () => {
    assignUserToClient(assignedUser, uid).then(_res => {
      setLoading(true)
      getClientTeam(uid).then(res=>{
        setMembers(res.data.data)
        setLoading(false)
      }).catch(err=>{
        console.log(err.response)
      })
    })
  }

  return (
    <section aria-labelledby="client-overview-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div className="ml-4 mt-2 w-full flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{t('Team')}</h3>
                <Link type="button" to={`/users/add/`} className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  {t('Add User')}
                </Link>
              </div>
            </div>
          </div>
          <div className="py-2 align-middle inline-block min-w-full px-6 lg:px-8">
            {loading && (<div className="bg-white py-3 flex items-center justify-center"><Spinner /></div>)}
            {members.map((member) => (
              <MemberInline key={member.id} member={member} setMembers={setMembers} />
            ))}
            {users && !selectedAccount && (<div key={'added-user'} className="px-0 py-4">
              <div className="">
                <select
                  id="user"
                  name="user"
                  defaultValue=''
                  onChange={(e) => setAssignedUser(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value=''>---</option>
                  {users.map(user => {
                    return (<option key={user.email} value={user.email}>{user.email}</option>);
                  })}
                </select>
              </div>
              <button
                onClick={() => assignUser()}
                type="button"
                className="inline-flex min-w-full items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mt-2"
              >
                Assign to Client
              </button>
            </div>)}
          </div>
        </div>
    </section>
  )
}

export default Team
