import React from 'react';
import { useObserver } from 'mobx-react';
import ContractInfoForms from './components/ContractInfoForms';

const ContractForms = () => {

  return useObserver(() => {
    return (<div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <ContractInfoForms />
        </div>
      </div>
    </div>)
  });
}

export default ContractForms
