import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';

import { AuthContext } from 'stores/auth';

const ClientsManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <div className="flex-1">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Clients Manual</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
            </div>
            <div className="mt-5 border-t border-gray-200">
              <dl className="divide-y divide-gray-200">

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View Clients List</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the sidebar of the Client Portal, click the Clients button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-1.png" />
                    <span className="flex-grow col-span-4">2. The Clients List will be displayed on the page containing the Client Name, Active Status, Payee ID, and Number of Users.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-3.png" />
                    <span className="flex-grow col-span-4">3. At the bottom of the Client List, click the Previous or Next button to navigate through pages.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-4.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Add Client</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. Click on the Add Client button located on the top right of the Client Page. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-5.png" />
                    <span className="flex-grow col-span-4">2. Fill out the required client data. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-6.png" />
                    <span className="flex-grow col-span-4">3. Click the save button at the bottom right of the screen. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-7.png" />
                    <span className="flex-grow col-span-4">4. Added Client will be displayed in the Client List. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-2.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Set Client as Inactive</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. In the Clients List, locate the client you want to set as inactive.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-2.png" />
                    <span className="flex-grow col-span-4">2. At the right most column of the client, click on the Set as Inactive button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-8.png" />
                    <span className="flex-grow col-span-4">3. To see if client is set as inactive, check the client status located on the second column of the client. Status should be set as Terminated.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-9.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Set Client as Active</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. In the Clients List, locate the client you want to set as active.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-2.png" />
                    <span className="flex-grow col-span-4">2. At the right most column of the client, click on the Set as Active button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-11.png" />
                    <span className="flex-grow col-span-4">3. To see if client is set as active, check the client status located on the second column of the client. Status should be set as Active.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-10.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View Client Page</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the Clients List, click on the name of the client you want to view.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-2.png" />
                    <span className="flex-grow col-span-4">2. The client's data will be displayed on the client page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-12.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Add a User</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the top right side of the client page, locate the Team section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-13.png" />
                    <span className="flex-grow col-span-4">2. Click the Add User button located on the top right of the Team section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-14.png" />
                    <span className="flex-grow col-span-4">3. Fill out required user data.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-15.png" />
                    <span className="flex-grow col-span-4">4. Click the Save button located on the bottom right of the page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-7.png" />
                    <span className="flex-grow col-span-4">5. User has now been added.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-2.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Assign User to Client</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the top right side of the client page, locate the Team section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-13.png" />
                    <span className="flex-grow col-span-4">2. Click on the dropdown button in the Team section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-17.png" />
                    <span className="flex-grow col-span-4">3. Select user you want to assign to client.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-16.png" />
                    <span className="flex-grow col-span-4">4. Click assign to client button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-17.png" />
                    <span className="flex-grow col-span-4">5. User has been assigned to client.</span>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Add File</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the right side of the client page, locate the Files section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-18.png" />
                    <span className="flex-grow col-span-4">2. Click on the Add File button located on the upper right of the Files section.</span>
                    <span className="flex-grow col-span-4">3. Fill out necessary information and upload file.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-19.png" />
                    <span className="flex-grow col-span-4">4. Click the save button located on the lower right of the Add File page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-7.png" />
                    <span className="flex-grow col-span-4">5. File has been added.</span>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View Client Overview</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the left side of the client page, locate the Overview section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-20.png" />
                    <span className="flex-grow col-span-4">2. Click the dropdown sorter located on the upper right of the charts to sort accordingly by date or amount. Click the up arrow to sort data in ascending order, and the down arrow to sort data in descending order.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-21.png" />
                    <span className="flex-grow col-span-4">3. Click the dropdown sorters located on the bottom of the charts to select overview of specific periods. Choose the starting and ending dates.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-22.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View Client Net Revenue</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the left side of the client page, locate the Net Revenue section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-23.png" />
                    <span className="flex-grow col-span-4">2. Click the dropdown sorter located on the upper right of the charts to sort accordingly by date or amount. Click the up arrow to sort data in ascending order, and the down arrow to sort data in descending order.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-24.png" />
                    <span className="flex-grow col-span-4">3. The monthly Net Revenue data is displayed accordingly.</span>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Client Transactions</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. To view client transactions list, go to the Clients page and locate Transactions section below the Overview and Revenue charts.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-25.png" />
                    <span className="flex-grow col-span-4">2. To add client transaction, click the Add transaction button located on the upper right of the Transactions section. Fill out necessary information and click the save button located on the lower right of the Add Transaction page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-26.png" />
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-27.png" />
                    <span className="flex-grow col-span-4">3. To view a client transaction, click the date of the transaction in the transaction table.</span>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Client Contracts</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. To view contracts list, go to the Clients page and locate Contracts section below the Transactions section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-28.png" />
                    <span className="flex-grow col-span-4">2. To view a client contract, click the Payee ID of the contract in the contract list. Or click the Details button in the last column.</span>
                  </dd>
                </div>
              </dl>
            </div>
            <div className="py-4 sm:py-5 sm:gap-4 sm:text-right">
              <Link to="/help" className="text-m font-medium text-blue-800">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>)
  });
}

export default ClientsManual
