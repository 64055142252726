import React, {
  // useState
} from 'react';
// import { ExclamationCircleIcon, CheckBadgeIcon } from '@heroicons/react/20/solid';

// import Spinner from 'components/Icons/Spinner';

const PayeeIdField = ({
  register
}) => {
  // const [loading, setLoading] = useState(false);
  // const [status, setStatus] = useState();

  return (<div className="relative">
    <input
      type="text"
      name="external_payee_id"
      id="external_payee_id"
      className="block w-full pr-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
      {...register('external_payee_id')}
    />
    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      {/* {loading && <Spinner />}
      {!loading && status === 'invalid' && <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />}
      {!loading && status === 'invalid' && <CheckBadgeIcon className="h-5 w-5 text-green-500" aria-hidden="true" />} */}
    </div>
  </div>);
}

export default PayeeIdField;
