import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { classNames, cleanObject } from 'components/utils';
import { getAssetLabels, assignAssetLabel } from 'api/core';
import Paginate from 'components/Pagination';
import InfoBar from 'components/InfoBar';
import ClientSelect from 'components/ClientSelect';
import { useQuery } from 'utils';

import Filters from './Filters';
import AssetLabelSearchBar from './AssetLabelSearchBar';

const AssetLabelsTable = () => {
  const componentIsMounted = useRef(true);
  let query = useQuery();
  const [assetLabels, setAssetLabels] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchQ, setSearchQ] = useState(query.get('q'))
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    'hasNext': false,
    'hasPrev': false,
    'total': 1,
    'pages': 1
  });
  const [openClientSelect, setOpenClientSelect] = useState(false);
  const [selectedAssetLabel, setSelectedAssetLabel] = useState();

  const fetchData = useCallback(() => {
    setLoading(true)
    const filters = {
      q: searchQ,
      show: query.get('show') || 'all'
    };
    getAssetLabels(page, cleanObject(filters))
      .then(res=>{
        setAssetLabels(res.data.data)
        setPageInfo({
          'hasNext': res.data.has_next,
          'hasPrev': res.data.has_previous,
          'total': res.data.total,
          'pages': res.data.pages
        })
        setPage(parseInt(res.data.page))
        setLoading(false)
      })
      .catch(err=>{
        // TODO: repalce error logging
        console.log(err.response)
        setLoading(false)
      })
  }, [page])

  useEffect(() => {
    if (!componentIsMounted.current) return;
    fetchData();
    return () => {
      componentIsMounted.current = false;
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleAssignClientModal = (assetLabel) => {
    setSelectedAssetLabel(assetLabel);
    setOpenClientSelect(true);
  };

  const handleAssignClient = (clientId) => {
    if (selectedAssetLabel) {
      assignAssetLabel(clientId, selectedAssetLabel?.id).then((res) => {
        fetchData();
      });
    }
  };

  const resetSearch = () => {
    setLoading(true);
    setPage(1);
    setSearchQ("");
  }

  return (<>
    <div className="md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <Filters />
      </div>
      <div className="mt-4 flex-1 flex justify-end md:mt-0 md:ml-4">
        <AssetLabelSearchBar setQuery={setSearchQ} query={searchQ}  />
        <Link type="button" to="/clients/add" className="button ml-3 inline-flex items-center bg-blue-800 hover:bg-blue-900 text-sm text-white py-2 px-4 rounded">
          Add Asset Label
        </Link>
      </div>
    </div>
    <div className="max-w-full px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            {searchQ && <InfoBar text={`Showing search result for ${searchQ}`} onClose={resetSearch} />}

            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="table-auto overflow-scroll w-full border-separate" style={{ borderSpacing: 0 }}>
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 truncate backdrop-blur backdrop-filter sm:pl-6 lg:pl-8 max-w-2/6"
                      >
                        Name
                      </th>
                      {/* <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 truncate backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Slug
                      </th> */}
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Connected Client
                      </th>
                      {/* <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        &nbsp;
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {loading &&
                      (<tr>
                        <td
                          className={classNames(
                            'border-b border-gray-200',
                            'whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                          )}
                          colSpan={2}
                        >
                          Loading asset labels...
                        </td>
                      </tr>
                    )}
                    {assetLabels.map((assetLabel, idx) => (
                      <tr key={assetLabel.id}>
                        <td
                          className={classNames(
                            idx !== assetLabels.length - 1 ? 'border-b border-gray-200' : '',
                            'overflow-hidden whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 text-ellipsis sm:pl-6 lg:pl-8 max-w-2/6'
                          )}
                        >
                          {assetLabel.name}
                        </td>
                        {/* <td
                          className={classNames(
                            idx !== assetLabels.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate hidden sm:table-cell'
                          )}
                        >
                          {assetLabel.slug}
                        </td> */}
                        <td
                          className={classNames(
                            idx !== assetLabels.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell'
                          )}
                        >
                          {assetLabel.client
                            ? (<Link
                              to={`/clients/${assetLabel.client?.uid}/`}
                              className="hover:text-blue-600"
                            >
                              {assetLabel.client?.name}
                            </Link>)
                            : (<button
                              type="button"
                              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => handleAssignClientModal(assetLabel)}
                            >
                              Assign To Client
                            </button>)
                          }
                        </td>
                        {/* <td
                          className={classNames(
                            idx !== assetLabels.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell'
                          )}
                        >
                          <button
                            type="button"
                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                          >
                            Ignore
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <Paginate
                      page={page}
                      setPage={setPage}
                      pageInfo={pageInfo}
                      setPageInfo={setPageInfo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ClientSelect
          dialogTitle={'Assign client for ' + selectedAssetLabel?.name}
          isOpen={openClientSelect}
          setIsOpen={setOpenClientSelect}
          ctaAction={handleAssignClient}
        />
      </div>
    </div>
  </>);
}

export default AssetLabelsTable;
