import React from 'react';
import { useTranslation } from 'react-i18next';

const UserInfo = ({ user }) => {
  const { t } = useTranslation();
  return (<section aria-labelledby="applicant-information-title">
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
        {t('User Information')}
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('Personal details of the user.')}</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('Email')}</dt>
            <dd className="mt-1 text-sm text-gray-900">{user.email}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('Phone Number')}</dt>
            <dd className="mt-1 text-sm text-gray-900">{user.phone_number}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('Role')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <span className="px-2 text-sm font-semibold rounded-full bg-green-100 text-green-800">{user.role}</span>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{t('Preferred Language')}</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {!user.preferred_language ? 'English' : ''}
              {user.preferred_language === 'en' && 'English'}
              {user.preferred_language === 'es' && 'Spanish'}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </section>);
}

export default UserInfo;
