import React from 'react';

import Header from './components/Header';
import BankSection from './components/BankSection';
import Transactions from './components/Transactions';

const ClientInformation = () => {
  return (
    <>
      <div className="min-h-full">
        <div className="py-10">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <Header />
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <Transactions />
            </div>
            <BankSection />
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientInformation
