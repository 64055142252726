import React, { useState, useEffect, useCallback } from 'react';
import LoadingNotification from 'components/LoadingNotification';

import { getReportCategories } from 'api/core';

const CategoriesTable = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([])
  const [pageInfo, setPageInfo] = useState({
    'hasNext': false,
    'hasPrev': false,
    'total': 1,
    'pages': 1
  });

  const fetchData = useCallback(() => {
    setLoading(true);
    getReportCategories(page).then(res => {
      setCategories(res.data?.data);
      setPageInfo({
        'hasNext': res.data?.has_next,
        'hasPrev': res.data?.has_previous,
        'total': res.data?.total,
        'pages': res.data?.pages
      });
      setLoading(false);
    }).catch(err => {
      console.log('err');
      setLoading(false);
    });
  }, [page])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNext = () => {
    if (pageInfo?.hasNext) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (pageInfo?.hasPrev) {
      setPage(page - 1);
    }
  };

  return (<div className="mt-5 flex flex-col">
    <LoadingNotification loadingText='Fetching report categories...' loading={loading} />
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading && categories?.map((category, idx) => {
                return (<tr key={category.id} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <td className="cursor-pointer text-md font-medium text-blue-800">
                        {category.name}
                    </td>
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Page <span className="font-medium">{page}</span>&nbsp;of&nbsp;
                <span className="font-medium">{pageInfo?.pages}</span>
              </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
              <button type="button"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => handlePrevious()}
              >
                Previous
              </button>
              <button type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => handleNext()}
              >
                Next
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>);
}

export default CategoriesTable;
