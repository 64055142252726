import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const UnderConstruction = () => {
  var location = useLocation()
  const [imageUrl, setImageUrl] = useState('https://source.unsplash.com/random/?puppy')

  useEffect(() => {
    setImageUrl(`https://source.unsplash.com/random/?puppy&sig=${Math.floor(Math.random() * 100) + 1}`)
  }, [location.pathname])

  return (<div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
    <main
      className="min-h-full bg-cover bg-center sm:bg-center"
      style={{
        backgroundImage:
          `url("${imageUrl}")`,
      }}
    >
      <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
        <Link to="/" className="inline-flex">
          <span className="sr-only">Exploration</span>
          <img
            className="h-12 w-auto"
            src="/assets/images/Logo.png"
            alt="Exploration"
          />
        </Link>
        <p className="text-sm font-semibold text-black text-opacity-70 uppercase tracking-wide">Under Construction</p>
        <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
          We're working on it.
        </h1>
        <p className="mt-2 text-lg font-medium text-black text-opacity-70">
          Sorry, this page is not yet available.
        </p>
        <div className="mt-6">
          <Link
            to="/"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
          >
            Go back home
          </Link>
        </div>
      </div>
    </main>
  </div>);
}

export default UnderConstruction;
