import React from 'react';
import { useParams } from 'react-router-dom';
import ReportsTable from './components/ReportsTable';

const ClientReports = ({ client }) => {
  let { uid } = useParams();

  return (<>
    <ReportsTable uid={uid} client={client} />
  </>);
}

export default ClientReports;
