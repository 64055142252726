import { ChevronLeftIcon } from "@heroicons/react/24/solid"
import { getMember } from "api/expauth"
import ProfileImage from "components/ProfileImage"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"

import MemberPermissions from "./Permissions"
import GeneralInfo from "./GeneralInfo"

const MemberDetail = () => {

  const {id} = useParams()
  
  const [member, setMember] = useState()
  const [permissions, setPermissions] = useState([])

  useEffect(()=>{
    id && getMember(id)
    .then(res=>{
      setMember(res.data)
      setPermissions(res.data.permissions)
    })
  }, [id])
  
  return (
    <div className="space-y-4 p-4">
      <div className="flex items-start justify-between space-x-4">
        <div className="flex items-center space-x-4">
          <ProfileImage user={member?.user_detail} className="w-16"/>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{member?.user_detail?.name}</h1>
            <p className="text-sm font-medium text-gray-500">{member?.user_detail?.email}</p>
          </div>
        </div>
        <div>
          <Link to="/team" className="flex space-x-2 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><ChevronLeftIcon className="w-5"/> <span>Back</span></Link>
        </div>
      </div>

      <GeneralInfo
        member={member}
      />

      {/* <MemberPermissions
        permissions={permissions}
        member={member}
      /> */}
      
    </div>
  )
}

export default MemberDetail