import React, { useState, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { IdentificationIcon, MapPinIcon, FolderPlusIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { classNames } from 'components/utils';
import ClientLogo from 'components/ClientLogo';
import LoadingNotification from 'components/LoadingNotification';
import { CurrencyDollarIcon, ReceiptPercentIcon, LinkIcon, DocumentDuplicateIcon, CogIcon } from '@heroicons/react/20/solid'
import { generateClientInviteLink } from 'api/core';
import { AuthContext } from 'stores/auth';

const Profile = ({ loading, client, contract, uploadClientLogo, clientSource, toggleClientSource, showServices = true, overflow = true }) => {
  const [generating, setGenerating] = useState(false)
  const [copied, setCopied] = useState(false)
  const [clientLink, setClientLink] = useState()
  const authStore = useContext(AuthContext);
  const user = authStore.user;

  const contents = [
    { key: 'publishing', name: 'Publishing' },
    { key: 'sound-recordings', name: 'Sound Recordings' },
    { key: 'music-videos', name: 'Music Videos' },
    { key: 'film', name: 'Film' },
    { key: 'tv', name: 'TV' },
    { key: 'channel-management', name: 'Channel Management' },
    { key: 'broker', name: 'Broker' },
  ];

  const tabs = [
    { name: 'Invoices', href: '#', current: true },
    { name: 'Royalties', href: '#', current: false },
  ];

  const activeClassname = "bg-white text-gray-600 hover:bg-white focus-visible:outline-gray-300";
  const inactiveClassname = "bg-gray-300 text-gray-600 hover:bg-white focus-visible:outline-gray-300";

  const getClientLink = () => {
    setGenerating(true)
    generateClientInviteLink(client?.uid).then((res => {
      if (res.status === 200) {
        setGenerating(false)
        setCopied(false)
        setClientLink(res.data.invite_link)
      }
    })).catch(err => {
      setGenerating(false)
      console.log(err)
    })
  };

  const copyLink = () => {
    navigator.clipboard.writeText(clientLink)
    setCopied(true)
  }

  return (<section aria-labelledby="client-overview-title">
    <LoadingNotification loadingText='Generating client invite link...' loading={generating} />
    <div className={classNames(
        "overflow-hidden px-6 bg-gradient-to-tr from-blue-600 via-blue-800 to-blue-900",
        overflow ? "pb-12" : "pb-0"
      )}>
      <div className="bg-transparent py-10 px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <div className="flex items-center space-x-5">
                <div className="flex-shrink-0 cursor-pointer">
                  <div className="group relative h-16">
                    <ClientLogo className={client?.logo_url ? "h-full" : "h-16 w-16 rounded-full"} client={client} />
                    <label
                      className={classNames(
                        "group-hover:opacity-80 transition-opacity bg-gray-800 flex items-center justify-center text-sm text-gray-100 rounded absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer",
                        client?.logo_url ? "rounded" : "rounded-full"
                      )}>
                      <FolderPlusIcon className="flex-shrink-0 object-center h-8 w-8 text-gray-100" aria-hidden="true" />
                      <input
                        type="file"
                        name="logo_url"
                        accept="image/jpeg,image/png,image/gif"
                        className="hidden"
                        aria-hidden="true"
                        onChange={(e) => uploadClientLogo(e)}
                      />
                    </label>
                  </div>
                </div>
                <div>
                  <Link to={`/clients/${client?.uid}`}>
                    <h2 className="text-4xl font-bold text-white hover:text-blue-200 sm:tracking-tight sm:truncate">
                      {client?.name || 'Loading...'}
                    </h2>
                  </Link>
                  {contract ? (<>
                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                      <div className="mt-2 flex items-center text-sm font-medium text-gray-200">
                        <IdentificationIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-100" aria-hidden="true" />
                        {contract.entity}
                      </div>
                      <div className="mt-2 flex items-center text-sm font-medium text-gray-200">
                        <MapPinIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-100" aria-hidden="true" />
                        {contract.address_string}
                      </div>
                    </div>
                  </>) : (<div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                      <div className="mt-2 flex items-center text-sm font-medium text-gray-500">
                        <IdentificationIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Loading...
                      </div>
                      <div className="mt-2 flex items-center text-sm font-medium text-gray-500">
                        <MapPinIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Loading...
                      </div>
                    </div>)}
                  </div>
              </div>
            </div>
          </div>

          <div className="sm:flex sm:items-right sm:justify-between">
            {client?.client_status === 'invoice_royalty' ? (
              <div className="sm:flex sm:space-x-5">
                <div className="grid grid-cols-2 gap-1 bg-gray-300 px-1 py-1 rounded-md">
                  <button
                    type="button"
                    className={classNames(
                      "inline-flex items-center gap-x-1 rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                      clientSource === 'royalty' ? activeClassname : inactiveClassname
                    )}
                    onClick={() => toggleClientSource('royalty')}
                  >
                    <CurrencyDollarIcon className={classNames(
                      "h-5 w-5",
                      clientSource === 'royalty' ? "text-green-500" : ""
                    )}  aria-hidden="true" />
                    Royalties
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      "inline-flex items-center gap-x-1 rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                      clientSource !== 'royalty' ? activeClassname : inactiveClassname
                    )}
                    onClick={() => toggleClientSource('invoice')}
                  >
                    <ReceiptPercentIcon className={classNames(
                      "h-5 w-5",
                      clientSource === 'invoice' ? "text-green-500" : ""
                    )} aria-hidden="true" />
                    Invoices
                  </button>
                </div>
              </div>
            ) : null }

            {user.role === 'admin' &&
              <>
                {clientLink
                  ? <button
                      type="button"
                      onClick={() => copyLink()}
                      className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-2"
                    >
                      <DocumentDuplicateIcon className="h-4 w-4 mr-1" aria-hidden="true" />{copied ? 'Copied!' : 'Copy Invite Link'}
                    </button>
                  : <button
                    type="button"
                    onClick={() => getClientLink()}
                    className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-2"
                  >
                    <LinkIcon className="h-4 w-4 mr-1" aria-hidden="true" /> Generate Invite Link
                  </button>
                }
                <a
                  href="settings"
                  className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-2"
                >
                  <CogIcon className="h-4 w-4 mr-1" aria-hidden="true" />Settings
                </a>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  </section>);
}

export default Profile;
