import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Link, Switch, Route, useLocation } from "react-router-dom";
import UnderConstruction from "routes/static/UnderConstruction";
import classNames from "styles/classNames";

import BankUSDetails from "./BankUSDetails/List"
import BankDetailView from "./BankUSDetails/Detail"

const ClientRequests = () => {

  const location = useLocation()

  const navigation = [
    { name: 'Bank US', href: "/client-requests" },
    { name: 'Bank INT', href: "/client-requests/bank-int" }
  ];

  return (
    <div>
      <div className="w-full bg-white border-gray-300 border-b px-4 py-4">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
          {/* Left nav */}
          <div className="hidden lg:block lg:col-span-2">
            <nav className="flex space-x-4">
              {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={{
                      pathname: item.href
                    }}
                    className={classNames(
                      item.href === location.pathname ? 'text-blue-600' : 'text-cyan-100',
                      'text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10'
                    )}
                    aria-current={ item.href === location.pathname ? 'page' : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
            </nav>
          </div>
          <div className="px-12 lg:px-4">
            {/* Search */}
            <div className="hidden w-full">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative text-gray-500 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search"
                  className="block w-full text-gray-500 bg-gray-500 bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder-gray-600 focus:outline-none focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
                  placeholder="Search"
                  type="search"
                  name="search"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="mt-4 pb-8">
        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
          <Switch>
            <Route exact path="/client-requests">
              <BankUSDetails />
            </Route>

            <Route exact path="/client-requests/bank-us/:id">
              <BankDetailView />
            </Route>
            <Route exact path="/client-requests/*" render={() => <UnderConstruction />} />
          </Switch>
        </div>
      </main>
    </div>
  )
}

export default ClientRequests
