import React from 'react';
import W9Form from './components/W9Form'

const W9 = () => {
  return (<>
    <W9Form/>
  </>);
}

export default W9;
