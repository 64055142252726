import React, { useEffect, useState, useContext } from "react"
import Spinner from "components/Icons/Spinner"

import { getAllClients } from "api/core"
import { AuthContext } from "stores/auth"
import {
  assignUserToClient,
  getUserAssignedClients,
  unassignUserToClient,
} from "api/users"
import { Modal } from "components/Modal"
import { useTranslation } from 'react-i18next';

const AssignedClients = ({ user }) => {
  const { t, i18n } = useTranslation();
  const authStore = useContext(AuthContext)
  const [loading, setLoading] = useState()
  const [clients, setClients] = useState()
  const [assignedClients, setAssignedClients] = useState([])
  const [assignedClient, setAssignedClient] = useState("")

  const [showModal, setShowModal] = useState(false)
  const [clientToRemove, setClientToRemove] = useState(null)

  useEffect(() => {
    setLoading(true)
    if (!authStore.selectedAccount) {
      getAllClients().then((res) => {
        setClients(res.data?.data)
      })
    }

    getUserAssignedClients(user.id).then((res) => {
      setAssignedClients(res.data)
      setLoading(false)
    })
  }, [authStore.selectedAccount, user?.id])

  const assignClient = () => {
    assignUserToClient(user.email, assignedClient).then((res) => {
      setLoading(true)
      getUserAssignedClients(user.id).then((res) => {
        setAssignedClients(res.data)
      })
      setLoading(false)
    })
  }

  const onUnassignClient = async () => {
    unassignUserToClient(clientToRemove.id)
      .then(() => {
        setAssignedClients((prevState) =>
          prevState.filter((obj) => obj.id !== clientToRemove.id)
        )
        setShowModal(false)
      })
      .catch((res) => {
        console.log(res.response)
      })
  }

  return (
    <section
      aria-labelledby="timeline-title"
      className="lg:col-start-3 lg:col-span-1"
    >
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {t('Clients')}
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {t('List of clients this user is assigned to.')}
          </p>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {loading && (
              <div className="bg-white py-3 flex items-center justify-center">
                <Spinner />
              </div>
            )}
            {!loading && assignedClients.length === 0 && (
              <div className="bg-white py-3 flex items-center justify-center text-sm text-gray-500">
                {t('No assigned clients.')}
              </div>
            )}
            {assignedClients.map((client) => {
              return (
                <li key={client.uid} className="px-6 py-4 flex">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    alt=""
                  />
                  <div className="ml-3 flex justify-between items-center flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      {client?.client_detail?.name}
                    </p>
                    <button
                      className="text-sm text-gray-500 hover:text-red-500"
                      onClick={() => {
                        setShowModal(true)
                        setClientToRemove(client)
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </li>
              )
            })}
            {clients && !authStore.selectedAccount && (
              <li key={"added-client"} className="px-6 py-4">
                <div className="">
                  <select
                    id="client"
                    name="client"
                    defaultValue=""
                    onChange={(e) => setAssignedClient(e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">---</option>
                    {clients.map((client) => {
                      return (
                        <option key={client.uid} uid={client.uid} value={client.uid}>
                          {client.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <button
                  onClick={() => assignClient()}
                  type="button"
                  className="inline-flex min-w-full items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mt-2"
                >
                 {t('Assign to Client')}
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        dialogTitle="Unassign client?"
      >
        <div className="text-center space-y-4">
          <p>
            {t('You are trying to remove')} <br />{" "}
            <span className="text-blue-600">{user?.email}</span> from{" "}
            <span className="text-blue-500">
              {clientToRemove?.client_detail.name}
            </span>
          </p>
          <button
            className="rounded-md bg-red-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => onUnassignClient()}
          >
            {t('Unassign')}
          </button>
        </div>
      </Modal>
    </section>
  )
}

export default AssignedClients
