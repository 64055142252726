import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon, Bars2Icon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import NumberFormat from 'react-number-format';
import urlJoin from 'url-join';

import { clientYearOverYear, exportYearOverYear } from 'api/core';
import { classNames } from 'components/utils';
import { useQuery } from 'utils';
import Profile from "../Dashboard/components/Profile";
import YearDropdown from './components/YearDropdown';

const IGNORE_KEYS = ['Exploration Share', 'Total Gross', 'Total To Client']


const YearOverYear = ({ client, contract }) => {
  let query = useQuery();
  const [year, setYear] = useState(query.get('year') || new Date().getFullYear());
  const [prevYear, setPrevYear] = useState(query.get('prevYear') || year - 1);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [reports, setReports] = useState({
    'current': [],
    'prev': [],
  });

  useEffect(() => {
    if (client?.uid) {
      setLoading(true);
      clientYearOverYear(client.uid, prevYear, year).then(res => {
        setReports({
          'current': res.data.current_year,
          'prev': res.data.prev_year,
        })
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    }
  }, [client, prevYear, year]);

  const getExport = (uid, prevYear, year) => {
    setDownloading(true);
    exportYearOverYear(uid, prevYear, year).then((res => {
      if (res.status === 200) {
        setDownloading(false);
        if (res.data?.link) {
          window.location.replace(res.data?.link);
        }
      }
    })).catch(err => {
      console.log(err);
      setDownloading(false);
    })
  };

  return (<>
    <Profile
      loading={loading}
      client={client}
      contract={contract}
      showServices={false}
    />
    <div className="grid grid-cols-1 gap-4 items-start sm:px-6 lg:px-10 mt-8 pb-16">
      <div className="col-span-1 w-full -mt-24">
        <div className="w-full bg-white rounded-lg shadow divide-y divide-gray-200 py-12 px-16">
          <div className="lg:flex lg:items-center lg:justify-between">
            <main className="min-w-0 flex-1">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-4xl font-bold text-gray-900">Year Over Year</h1>
                  <div className="mt-2 flex items-center">
                    <YearDropdown
                      defaultYear={prevYear}
                      actionHandler={setPrevYear}
                    />

                    <span className="inline-flex mx-2">vs</span>

                    <YearDropdown
                      defaultYear={year}
                      actionHandler={setYear}
                    />
                  </div>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    type="button"
                    onClick={() => getExport(client?.uid, prevYear, year)}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-2"
                  >
                    {downloading ? 'Downloading...' : 'Download'}
                  </button>
                  {/* <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Print
                  </button> */}
                </div>
              </div>

              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                          >
                            Description
                          </th>
                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            {prevYear}
                          </th>
                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            {year}
                          </th>
                          {/* <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            Percentage
                          </th>
                          <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            &nbsp;
                          </th> */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {loading && (<tr>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">

                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">

                          </td>
                          {/* <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">

                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">

                          </td> */}
                        </tr>)}
                        {!loading && reports?.current?.map((report) => {
                          const prev = reports?.prev?.find(({ report__name }) => report__name === report.report__name);
                          const increase = (prev?.gross_revenue || 0) < report.gross_revenue;
                          const equal = prev?.gross_revenue === report.gross_revenue;

                          const diff = prev && (increase ? ((report.gross_revenue - prev.gross_revenue) / prev.gross_revenue) * 100 : ((prev.gross_revenue - report.gross_revenue) / prev.gross_revenue) * 100);

                          return IGNORE_KEYS.indexOf(report.report__name) < 0 && (<tr key={report.report__name}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                              {report.report__name}
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                              <NumberFormat
                                value={prev?.gross_revenue?.toFixed(2) || 0.00}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                              <NumberFormat
                                value={report.gross_revenue?.toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </td>
                            {/* <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                              <NumberFormat
                                value={parseFloat(diff).toFixed(2) || 0.00}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix={'%'}
                              />
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                              <p className={classNames("inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                                increase
                                  ? "bg-green-100 text-green-800"
                                  : equal
                                    ? "bg-gray-100 text-gray-800"
                                    : "bg-red-100 text-red-800")
                              }>
                                {increase
                                  ? <ArrowTrendingUpIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                  : equal
                                    ? <Bars2Icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                    : <ArrowTrendingDownIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                }
                              </p>
                            </td> */}
                          </tr>)
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </main>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default YearOverYear;
