import React, { useRef, useEffect, useCallback, useReducer, useContext } from 'react';
import { Link } from 'react-router-dom';

import { getClients } from 'api/core';
import { cleanObject } from 'components/utils';
import LoadingNotification from 'components/LoadingNotification';
import InfoBar from 'components/InfoBar';
import ClientSearchBar from 'components/ClientSearchBar';
import { useQuery } from 'utils';
import { AuthContext } from 'stores/auth';

import listReducer from './listReducer';
import Table from './components/Table';
import Filters from './components/Filters';
import FilterPopover from './components/FilterPopover';

const initialState = {
  'page': 1,
  'hasNext': false,
  'hasPrev': false,
  'total': 1,
  'pages': 1,
  'loading': false,
  'query': null,
  'clients': [],
  'error': false
}

const List = () => {
  const componentIsMounted = useRef(true);
  let query = useQuery();
  const authStore = useContext(AuthContext);
  const user = authStore.user;
  const [state, dispatch] = useReducer(listReducer, initialState);

  const fetchData = useCallback(() => {
    dispatch({ type: 'LOADING' })
    const filters = {
      q: state.query,
      show: query.get('show') || 'all'
    };

    getClients(state.page, cleanObject(filters)).then(res => {
      dispatch({ type: 'RECEIVE_DATA', payload: res.data })
    }).catch(err => {
      dispatch({ type: 'ERROR', error: err })
    });
  }, [state.page, query, state.query])

  const resetSearch = () => {
    dispatch({ type: 'RESET_SEARCH' })
  }

  const handleSearch = (keyword) => {
    dispatch({ type: 'SET_SEARCH', payload: keyword })
  }

  useEffect(() => {
    if (!componentIsMounted.current) return;
    fetchData();
    return () => {
      componentIsMounted.current = false;
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [state.page, state.query]);

  useEffect(() => {
    if (query.get('q') === null || query.get('q') === undefined) {
      dispatch({ type: 'SET_SEARCH', payload: null })
    }
    if (query.get('show')) {
      fetchData();
    }
  }, [query]);

  const ADMIN_EMAILS = [
    'stanley.semilla@exploration.io',
    'tiny.ramirez@exploration.io',
    'jem.apolinario@exploration.io',
    'kevin.semilla@exploration.io',
    'alex.alt@exploration.io',
    'rene.merideth@exploration.io',
    'jenellee.sencil@exploration.io',
    'lionel.delapaz@exploration.io',
    'ana.berberana@exploration.io'
  ]

  return (<div className="relative px-4 sm:px-6 lg:px-8 py-5">
    <LoadingNotification loadingText='Fetching clients...' loading={state.loading} />
    <div className="md:flex md:items-center md:justify-between my-5">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Clients</h2>
      </div>
    </div>
    <div className="md:flex md:items-center md:justify-between my-5">
      <div className="flex-1 min-w-0">
        <Filters />
      </div>
      <div className="mt-4 flex-1 flex md:mt-0 md:ml-4">
        <FilterPopover />
        <ClientSearchBar
          loading={state.loading}
          setQuery={handleSearch}
          query={state.query}
        />
        {ADMIN_EMAILS.includes(user?.email) &&
          <Link type="button" to="/clients/add" className="button ml-3 inline-flex items-center bg-blue-800 hover:bg-blue-900 text-sm text-white py-2 px-4 rounded">
            Add Client
          </Link>
        }
      </div>
    </div>
    {state.query && <InfoBar text={`Showing search result for ${state.query}`} onClose={resetSearch} />}
    <Table
      state={state}
      dispatch={dispatch}
      fetchData={fetchData}
    />
  </div>);
}

export default List;
