import PrivateAPI from './private';

export const getClientYTMonthlyReport = (uid, startYear, startMonth, endYear, endMonth) => {
  return PrivateAPI.get(`/api/reports/clients/${uid}/yt-monthly-report/${startYear}/${startMonth}/${endYear}/${endMonth}/`)
};

export const getClientYTAdRates = (uid, startYear, startMonth, endYear, endMonth) => {
  return PrivateAPI.get(`/api/reports/clients/${uid}/yt-ad-rates/${startYear}/${startMonth}/${endYear}/${endMonth}/`)
};

export const getTopAssets = (uid, startYear, startMonth, endYear, endMonth) => {
  return PrivateAPI.get(`/api/reports/clients/${uid}/yt-top-assets/${startYear}/${startMonth}/${endYear}/${endMonth}/`)
};

export const getTopVideos = (uid, startYear, startMonth, endYear, endMonth) => {
  return PrivateAPI.get(`/api/reports/clients/${uid}/yt-top-videos/${startYear}/${startMonth}/${endYear}/${endMonth}/`)
};

export const downloadExport = (uid, startYear, startMonth, endYear, endMonth) => {
  return PrivateAPI.get(`/api/reports/clients/${uid}/export/${startYear}/${startMonth}/${endYear}/${endMonth}/`)
};
