import React from 'react';

import { getMonthName } from 'utils';
import { useTranslation } from 'react-i18next';

const AnalyticalCharts = ({ month, year }) => {
  const { t } = useTranslation();
  const monthName = getMonthName(month);
  return (<>
    <div className="mb-6">
      <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">{t('Analytical Charts')}</h1>
      <p className="mt-1 max-w-3xl text-lg text-gray-500">{t(monthName)} {year}</p>
    </div>
  </>);
}

export default AnalyticalCharts;
