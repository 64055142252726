import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

import { createReport, updateReport, getReportCategoriesOptions } from 'api/core';
import Switch from 'components/Form/Switch';
import { useTranslation } from 'react-i18next';


const ReportsForm = ({ defaultValues }) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [youtubeReport, setYoutubeReport] = useState(defaultValues?.is_youtube || false);

  const { control, register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: defaultValues
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "aliases",
  });

  useEffect(() => {
    getReportCategoriesOptions().then(res => {
        setCategoryOptions(res.data)
        setLoading(false);
    }).catch(err => {
      console.log('err');
      setLoading(false);
    });
  }, []);

  const onSubmit = (data) => {
    setLoading(true)

    if (data?.id) {
      updateReport(data.id, data).then(res => {
        setLoading(false);
        history.push('/admin/report-types/');
      }).catch(err => {
        console.log(err.response);
        setLoading(false);
      })
    } else {
      createReport(data).then(res => {
        setLoading(false);
        history.push('/admin/report-types/');
      }).catch(err=>{
        console.log(err.response);
        setLoading(false);
      });
    }
  };

  const handleCategories = (e) => {
    let value = Array.from(e, option => option.value);
    setValue('categories', value);
  }

  const handleYoutube = (val) => {
    setYoutubeReport(val);
    setValue('is_youtube', val);
  }

  const handleRemove = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0){
      append({'name': ''});
    }
  }, [fields]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6 w-1/2 mx-auto mt-10">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Report Information</h3>
                <p className="mt-1 text-sm text-gray-500">This information will only be displayed to admins.</p>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
              <div className="sm:col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Report Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    {...register('name', { required: true })}
                  />
                  {errors?.name?.type === "required" ? (
                    <p className="text-red-500 text-sm">Please enter a Report Name</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="mt-6 flex gap-6">
              <div className="flex-grow-0">
                <label htmlFor="is_youtube" className="block text-sm font-medium text-gray-700">
                  YouTube Report
                </label>
                <div className="mt-3 flex">
                  <input
                    type="hidden"
                    name="is_youtube"
                    id="is_youtube"
                    value={youtubeReport}
                    {...register('is_youtube', { required: false })}
                  />
                  <Switch
                    handleChange={handleYoutube}
                    value={youtubeReport}
                    color='green'
                  />
                </div>
              </div>

              <div className="flex-1">
                <label htmlFor="rate_key" className="block text-sm font-medium text-gray-700">
                  Rate Key
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="rate_key"
                    id="rate_key"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    {...register('rate_key', { required: false })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div className="md:col-span-1">
                <h3 className="mt-4 text-lg font-medium leading-6 text-gray-900">Categories</h3>
              </div>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4">
              <div className="sm:col-span-1">
                <div className="mt-1">
                  <input
                    type="hidden"
                    name="categories"
                    {...register('categories', { required: false })}
                  />
                  <CreatableSelect
                    isMulti
                    onChange={(e => handleCategories(e))}
                    options={categoryOptions}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-2">
              <div className="col-span-1">
                <h3 className="mt-4 text-lg font-medium leading-6 text-gray-900">Aliases</h3>
              </div>
              <div className="col-span-1 text-right">
                <h3
                  className="mt-4 px-2 text-sm text-indigo-700 cursor-pointer font-medium hover:text-indigo-900"
                  onClick={() => append({})}
                >Add Alias</h3>
              </div>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4">
              <div className="sm:col-span-1">
                {fields.map((field, index) => {
                  return (<div className='mt-1' key={field.id}>
                    <div className="">
                      <input
                        type="text"
                        name="aliases"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...register(`aliases.${index}.name`)}
                      />
                    </div>
                    <div className="text-right">
                      <span
                        className="mt-2 px-2 py-2 text-xs text-red-500 cursor-pointer font-medium hover:text-red-900"
                        onClick={() => handleRemove(index)}
                      >Remove</span>
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Link
            to={`/admin/report-types`}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </Link>
          <button
            type="button"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {t('Set as Alias')}
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
    </div>
  </form>)
}

export default ReportsForm
