import React from 'react'

import NavigationBar from './components/NavigationBar'
// import Footer from './components/Footer'

function MainWrapper ({ children }) {
  return (
    <div className="h-full">
      <div className="h-full">
        <div className="h-full flex">
          <NavigationBar />
          <div className="flex-1 md:pl-64">
            {children}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default MainWrapper
