import React from 'react';
import { Link } from 'react-router-dom';

import Switch from 'components/Form/Switch';
import { useTranslation } from 'react-i18next';


const LoadingReportsForm = () => {
  const { t } = useTranslation();
  return (
    <form>
      <div className="space-y-6 w-1/2 mx-auto mt-10">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Report Information</h3>
                <p className="mt-1 text-sm text-gray-500">This information will only be displayed to admins.</p>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
              <div className="sm:col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  {t('Report Name')}
                </label>
                <div className="mt-1">
                  <div className="animate-pulse rounded-md bg-gray-200 w-full h-10" />
                </div>
              </div>
            </div>

            <div className="mt-6 flex gap-6">
              <div className="flex-grow-0">
                <label htmlFor="is_youtube" className="block text-sm font-medium text-gray-700">
                  YouTube Report
                </label>
                <div className="mt-3 flex">
                  <input
                    type="hidden"
                    name="is_youtube"
                    id="is_youtube"
                  />
                  <Switch
                    handleChange={null}
                    value={false}
                    color='green'
                  />
                </div>
              </div>

              <div className="flex-1">
                <label htmlFor="rate_key" className="block text-sm font-medium text-gray-700">
                  Rate Key
                </label>
                <div className="mt-1">
                  <div className="animate-pulse rounded-md bg-gray-200 w-full h-10" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div className="md:col-span-1">
                <h3 className="mt-4 text-lg font-medium leading-6 text-gray-900">Categories</h3>
              </div>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4">
              <div className="sm:col-span-1">
                <div className="mt-1">
                  <div className="animate-pulse rounded-md bg-gray-200 w-full h-10" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div className="md:col-span-1">
                <h3 className="mt-4 text-lg font-medium leading-6 text-gray-900">Aliases</h3>
              </div>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4">
              <div className="sm:col-span-1">
                <div className="mt-1">
                  <div className="animate-pulse rounded-md bg-gray-200 w-full h-10" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Link
            to={`/admin/report-types`}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </Link>
          <button
            type="button"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {t('Set as Alias')}
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
    </div>
  </form>)
}

export default LoadingReportsForm
