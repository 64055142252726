import React, { useRef, useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

import { useQuery } from 'utils';
import ReportsTable from './components/ReportsTable';
import Filters from './components/Filters';
import ReportsSearchBar from './components/ReportsSearchBar';
import { useTranslation } from 'react-i18next';

const ReportTypes = () => {
  const { t } = useTranslation();
  const componentIsMounted = useRef(true);
  let query = useQuery();
  const [loading, setLoading] = useState(false);
  const [searchQ, setSearchQ] = useState(query.get('q') || '');

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      <div className="max-w-full">
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">Report Types</h2>
          </div>
        </div>
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <Filters />
          </div>
          <div className="mt-4 flex-1 flex md:mt-0 md:ml-4">
            <ReportsSearchBar
              loading={loading}
              setQuery={setSearchQ}
              query={searchQ}
            />
            <Link
              to={`/admin/report-types/add`}
              className="button ml-3 inline-flex items-center bg-blue-800 hover:bg-blue-900 text-sm text-white py-2 px-4 rounded"
            >
              <PlusCircleIcon className='h-5 w-5' />
              <span className="ml-1">{t('Add')}</span>
            </Link>
          </div>
        </div>
        <ReportsTable
          query={searchQ}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  )
}

export default ReportTypes
