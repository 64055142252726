import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { Disclosure } from '@headlessui/react'
import {
  HomeIcon,
  UsersIcon,
  UserGroupIcon,
  UserCircleIcon,
  InboxIcon,
  ChartBarIcon,
  InformationCircleIcon,
  DocumentChartBarIcon,
  FolderArrowDownIcon,
  Cog8ToothIcon,
  RectangleStackIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline';

import { useTranslation } from 'react-i18next';

import { AuthContext } from 'stores/auth';
import ProfileImage from '../../components/ProfileImage';
import classNames from 'styles/classNames';

import { getUserAssignedClients } from 'api/users';
import { testEmail } from 'api/core';
import ClientDropdown from './ClientDropdown';
// import AdminClientDropdown from './AdminClientDropdown';

const NavigationBar = () => {
  const { t, i18n} = useTranslation();
  let location = useLocation();
  const authStore = useContext(AuthContext);
  const user = authStore.user;
  const selectedAccount = authStore.selectedAccount;

  const [assignedClients, setAssignedClients] = useState([])

  const handleLogout = () => {
    authStore.logout();
  }

  const checkCurrent = (path) => {
    var matchObj = matchPath(location.pathname, { path: path, exact: true });
    return matchObj !== null
  }

  const handleTestEmail = async () => {
    await testEmail().then(res => {
      console.log(res.data)
    })
  }

  useEffect(() => {
    getUserAssignedClients(user?.id).then(res => {
      setAssignedClients(res.data);
    }).catch(err => {
      console.log('err');
    });
  }, [user]);

  const navigation = [
    { name: 'Dashboard', icon: HomeIcon, href: '/', current: checkCurrent('/dashboard') || checkCurrent('/')},
    // { name: 'Team', icon: UserGroupIcon, href: '/team/', current: checkCurrent('/team')},
    // { name: 'Assets', icon: InboxIcon, href: '/assets/', current: checkCurrent('/assets')},
    { name: 'Reports', icon: DocumentChartBarIcon, href: '/reports/', current: checkCurrent('/reports')},
    { name: 'Profile', icon: UserCircleIcon, href: '/profile/', current: checkCurrent('/profile')},
    // { name: 'Help', icon: InformationCircleIcon, href: '/help', current: checkCurrent('/help') }
  ]

  const adminNavigation = [
    { name: t('Profile'), icon: UserCircleIcon, href: '/profile/', current: checkCurrent('/profile')},
    { name: t('Clients'), icon: UsersIcon, href: '/clients/', current: checkCurrent('/clients') },
    { name: t('Reports'), icon: DocumentChartBarIcon, href: '/reports/', current: checkCurrent('/reports')},
    { name: t('Asset Labels'), icon: InboxIcon, href: '/admin/asset-labels/', current: checkCurrent('/admin/asset-labels')},
    { name: t('Users'), icon: UserGroupIcon, href: '/users/', current: checkCurrent('/users')},
    { name: t('Report Types'), icon: ChartBarIcon, href: '/admin/report-types/', current: checkCurrent('/admin/report-types')},
    { name: t('Downloads'), icon: FolderArrowDownIcon, href: '/my-downloads/', current: checkCurrent('/my-downloads')},
    { name: t('Asset Search'), icon: MagnifyingGlassIcon, href: '/search/assets', current: checkCurrent('/search/assets')},
    { name: t('Help'), icon: InformationCircleIcon, href: '/help', current: checkCurrent('/help') }
    // { name: 'Client Requests', icon: TicketIcon, href: '/client-requests', current: checkCurrent('/client-requests')},
  ]

  return useObserver(() => {
    if (!user) {
      return null
    } else {
      return (<>
        <Disclosure as="nav">
          {({ open }) => (
            <>
              <div>
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                  <div className="flex-1 flex flex-col min-h-0 bg-blue-800">
                    <div className="flex items-center justify-center flex-shrink-0 px-4 pt-5 pb-4">
                      <Link to="/">
                        <img
                          className="h-6 w-auto"
                          src="/assets/images/Exploration-White.png"
                          alt="Exploration"
                        />
                      </Link>
                    </div>

                    <div className="mt-3 flex items-center justify-center ">
                      {user.role === 'admin'
                        ? null
                        : <ClientDropdown
                          accounts={assignedClients}
                          selectedAccount={selectedAccount}
                        />}
                    </div>

                    {/* <div className="text-white py-2 px-3">
                      {assignedClients && assignedClients.length > 0 && (<Menu>
                          <Menu.Button>
                            <div className='text-sm px-1 font-medium group flex group hover:text-blue-200 flex items-center py-1 text-sm font-medium rounded-md'>
                              <UserGroupIcon className='mr-3 w-6 h-6 text-blue-300' />Switch Account
                            </div>
                          </Menu.Button>
                          <Menu.Items>
                            <div className='text-sm px-2 text-sm font-medium'>
                              {assignedClients.map((client) => (
                                <Menu.Item key={client.uid}>
                                  {({ active }) => (
                                    <div
                                      onClick={() => authStore.setSelectedAccount(client)}
                                      className={`group flex items-center rounded-md cursor-pointer py-2 ${active && 'bg-blue-600 bg-opacity-75'}`}
                                    >
                                      <UserIcon className='mr-3 w-5 h-5 text-blue-300' />{client.name}
                                    </div>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>

                          </Menu.Items>
                        </Menu>)
                      }
                    </div> */}

                    <div className="mt-8 flex-grow flex flex-col">
                      <nav className="flex-1 px-2 space-y-8" aria-label="Sidebar">


                        {user.role === 'admin'
                          ? (<div className="space-y-1">
                            <h3 className="px-3 mb-3 text-sm font-medium text-gray-100" id="projects-headline">
                              Admin
                            </h3>
                            <div className="space-y-1" role="group" aria-labelledby="projects-headline">
                              {adminNavigation.map((item) => (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className={classNames(
                                    item.current ? 'bg-blue-600 text-white' : 'text-blue-100 hover:bg-blue-600 hover:bg-opacity-75',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                  )}
                                >
                                  <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-blue-300" aria-hidden="true" />
                                  <span className="flex-1">{item.name}</span>
                                </Link>
                              ))}
                            </div>
                          </div>)
                          : (<div className="space-y-1">
                            {navigation.map((item) => (
                              <Link
                                key={item.name}
                                to={item.href}
                                className={classNames(
                                  item.current ? 'bg-blue-600 text-white' : 'text-blue-100 hover:bg-blue-600 hover:bg-opacity-75',
                                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                )}
                              >
                                <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-blue-300" aria-hidden="true" />
                                <span className="flex-1">{item.name}</span>
                              </Link>
                            ))}
                          </div>)
                        }
                      </nav>
                    </div>

                    {/* <div className="relative bg-blue-900 p-10 relative z-0 rounded-lg shadow-md mx-5 my-3 flex flex-col justify-center items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-white h-8 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                      </svg>
                      <h1 className="text-xl font-bold text-white">Help Center</h1>
                      <h3 className="text-xs text-blue-300">Having trouble navigating the portal? </h3>
                      <button className="bg-white p-2 mt-4 rounded text-sm font-semibold hover:bg-gray-100 text-gray-600">Go to Help Center</button>
                    </div> */}

                    <div className="flex-shrink-0 flex border-t border-b border-blue-700 p-4">
                      <Link to="#" className="flex-shrink-0 w-full group block">
                        <div className="flex items-center">
                          <div>
                            <ProfileImage className="inline-block h-9 w-9 rounded-full" user={user} />
                          </div>
                          <div className="ml-3">
                            <span className="text-xs font-medium text-white">{user?.email} </span>
                            <span onClick={() => handleLogout()} className="block text-sm font-medium text-blue-300 group-hover:text-white">{t('Log Out')}</span>
                          </div>

                        </div>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </>);
    }
  })
}

export default NavigationBar
