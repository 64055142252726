import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { createReport, getReport, getReportCategoriesOptions } from 'api/core';
import ReportsForm from './components/ReportsForm';
import LoadingReportsForm from './components/LoadingReportsForm';

const ReportTypesForm = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [report, setReport] = useState();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getReport(id).then(res => {
        setReport(res.data?.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
      })
    }
  }, [id]);

  console.log(report);

  return (<div className="px-4 sm:px-6 lg:px-8 py-5">
    <div className="max-w-full">
      {!loading && <ReportsForm defaultValues={report} />}
      {loading && <LoadingReportsForm />}
    </div>
  </div>);
}

export default ReportTypesForm;
