import { getMemberPermissionList, setMemberPermission } from "api/expauth"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { AuthContext } from "stores/auth"
import hasClientMemberPerm from "utils/hasClientMemberPerm"

const MemberPermissions = ({ member, permissions: permissionProp }) => {

  const authStore = useContext(AuthContext)
  const selectedAccount = authStore.selectedAccount;
  let { uid } = useParams();
  let clientUid = selectedAccount ? selectedAccount.uid : uid

  const [isLoading, setIsLoading] = useState(false)
  const [permissionChoices, setPermissionChoices] = useState([])
  const [permissions, setPermissions] = useState(permissionProp)

  const canUpdateMemberPermissions = hasClientMemberPerm(authStore.user, clientUid, 'can_update_member_permissions')
  
  useEffect(()=>{
    getMemberPermissionList()
    .then(res=>{
      setPermissionChoices(res.data)
    })
  }, [])

  useEffect(()=>{
    setPermissions(permissionProp)
  }, [permissionProp])

  const onSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    e.preventDefault()
    member && setMemberPermission(member.id, permissions).then(res=>{
      console.log(res)
    }).catch(res=>{
      console.log('err', res)
    }).finally(()=>{
      setIsLoading(false) 
    })
  }

  const onChange = e => {
    const name = e.target.name
    const checked = e.target.checked
    if (checked) {
      addPermission(name)
    } else {
      removePermission(name)
    }
  }

  const removePermission = codeName => {
    setPermissions(prevState => prevState.filter(permission => permission.codename !== codeName))
  }

  const addPermission = codeName => {
    const perm = permissions.find((tempPerm) => tempPerm.codename === codeName)
    if (!perm) {
      const permToAdd = permissionChoices.find(tempPerm => tempPerm.codename === codeName)
      setPermissions(prevState => [...prevState, permToAdd])
    }
  }

  return (
    <section aria-labelledby="member-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Permissions
            </h2>
            {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Details</p> */}
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div>
            <form onSubmit={onSubmit}>
              <fieldset disabled={!canUpdateMemberPermissions}>
              {permissionChoices.map((permission) => (
                <div key={permission.codename} className="flex items-center space-x-2">
                  <input
                    name={`${permission.codename}`}
                    onChange={onChange}
                    checked={permissions.filter(perm => perm.codename === permission.codename).length > 0}
                    type='checkbox'
                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    /> <span>{permission.name}</span>
                </div>
              ))}
              <button
                disabled={isLoading || !canUpdateMemberPermissions}
                type='submit'
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-75 disabled:cursor-default disabled:bg-gray-600"
                >Update</button>
                </fieldset>
            </form>
            </div>
          </div>
        </div>
      </section>
  )
}

export default MemberPermissions