import { CAN_VIEW_MEMBER, CLIENT_OWNER } from "const/permissions"

const hasClientMemberPerm = (user, client, permission) => {

  if (user.role === 'superuser' || user.role === 'admin') return true

  const member = user.teams.find(team => team.client_detail.uid === client)
  if (!member) return false
  if (member?.role === CLIENT_OWNER) return true
  if (permission === CAN_VIEW_MEMBER && member?.user === user?.id) return true
  else return member?.permissions.filter(perm => perm.codename === permission).length > 0
}

export default hasClientMemberPerm