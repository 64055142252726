import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import app from 'firebase';

const ProfileImage = ({ user, ...props }) => {
  const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_NAME)
  const [imageUrl, setImageUrl] = useState("https://cdn-icons-png.flaticon.com/512/149/149071.png")

  useEffect(() => {
    if (user?.profile_image_uid) {
      getDownloadURL(ref(storage, `images/${user.profile_image_uid}`)).then((url) => {
        setImageUrl(url)
      }).catch((error) => {
        console.log(error)
      });
    }
  }, [user, storage])

  return (<img
    {...props}
    src={imageUrl}
    alt={user?.email}
    />
  );
};

export default ProfileImage;
