import React, { useState, useEffect } from 'react';

import CMSMonthlyReport from './CMS'
import NonCMSMonthlyReport from './NonCMS'

const MonthlyReport = ({ loading, client, stats, contract }) => {
  if (client) {
    return (<>
      {client.own_cms
        ? <CMSMonthlyReport
            loading={loading}
            client={client}
            stats={stats}
            contract={contract}
          />
        : <NonCMSMonthlyReport
            loading={loading}
            client={client}
            stats={stats}
            contract={contract}
          />}
    </>)
  } else {
    return null;
  }
}

export default MonthlyReport;
