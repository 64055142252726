import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'stores/auth';
import ReportsTable from './components/ReportsTable';
import ClientSelect from './components/ClientSelect';
import { getAllClients } from 'api/core';
import { getAssetReports, getAssetReport } from 'api/expdata';
import LoadingNotification from 'components/LoadingNotification';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const AssetReports = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const componentIsMounted = useRef(true);
  const authStore = useContext(AuthContext)
  let selectedAccount = authStore.selectedAccount
  let email = authStore.user?.email

  const [client, setClient] = useState(null)
  const [clients, setClients] = useState([])
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const [exporting, setExporting] = useState(false)
  const [page, setPage] = useState(1);
  const [rownum, setRownum] = useState(20)
  const [pageInfo, setPageInfo] = useState({
    'hasNext': false,
    'hasPrev': false,
    'total': 1,
    'pages': 1
  });

  const fetchData = useCallback(() => {
    setLoading(true)
    let uid = null
    if (selectedAccount) {
      uid = selectedAccount.uid
      setClient(selectedAccount)
    }
    if (client) {
      uid = client.uid
    }
    getAssetReports(uid)
      .then(res=>{
        setReports(res.data.data)
        // setPageInfo({
        //   'hasNext': res.data.has_next,
        //   'hasPrev': res.data.has_previous,
        //   'total': res.data.total,
        //   'pages': res.data.pages
        // })
        // setPage(parseInt(res.data.page))
        setLoading(false)
      })
      .catch(err=>{
        // TODO: repalce error logging
        console.log(err.response)
        setLoading(false)
      })
  }, [client])

  const getExport = (uid, name) => {
    setExporting(true)
    getAssetReport(uid, email, name).then((res => {
      if (res.status === 200) {
        setExporting(false)
        history.push('/downloads')
      }
    })).catch(err => {
      setExporting(false)
      console.log(err.response)
    })
  };

  useEffect(() => {
    getAllClients()
      .then(res=> {
        setClients(res.data.data)
      })
      .catch(err=>{
        console.log(err.response)
    })
  }, [])

  useEffect(() => {
    if (!componentIsMounted.current) return;
    fetchData();
    return () => {
      componentIsMounted.current = false;
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return useObserver(() => {
    return (
      <div className="px-4 sm:px-6 lg:px-8 py-5">
        <LoadingNotification loadingText='Requesting export...' loading={exporting} />
        <LoadingNotification loadingText='Fetching reports...' loading={loading} />
        <div className="w-full mx-auto">
          <div className="md:flex md:items-center md:justify-between my-5">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{t('Asset Reports')}</h2>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              {authStore.selectedAccount
                ? <>&nbsp;</>
                : <ClientSelect clients={clients} client={client} setClient={setClient} />
              }
            </div>
          </div>
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">&nbsp;</div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <span
                  type="button"
                  onClick={() => getExport(client?.uid, client?.name)}
                  className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-4 py-2 text-xs font-medium text-indigo-700 cursor-pointer hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {t('Request Asset Report')}
                  <ArrowDownTrayIcon className="h-4 w-4 ml-1" aria-hidden="true" />
                </span>
            </div>
          </div>
          <ReportsTable
            loading={loading}
            reports={reports}
            page={page}
            setPage={setPage}
            pageInfo={pageInfo} />
        </div>
      </div>
    )
  });
}

export default AssetReports
