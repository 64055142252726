import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getMonthName } from 'utils';

const AssetTable = ({ loading, assets }) => {
  const { t } = useTranslation();

  return (<div className="mt-5 flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Asset ID
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left w-44">
                  Asset Title
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left w-44">
                  Custom ID
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left w-44">
                  Asset Label
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left w-44">
                  Year
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left w-44">
                  Month
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left w-44">
                  Client
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-left w-44">
                  Report
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr className="bg-white">
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="rounded-full bg-gray-200 w-44 h-5" />
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-full bg-gray-200 w-5 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-sm bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-full bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-sm bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-full bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-sm bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-full bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                </tr>
              )}
              {!loading && assets?.map((asset, idx) => {
                return (<tr key={asset.pk} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {asset.asset_id}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {asset.asset_title}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {asset.custom_id}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {asset.asset_label}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {asset.year}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {getMonthName(asset.month)}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {asset?.client?.name}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-800">
                      {asset?.report?.name}
                    </span>
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>);
}

export default AssetTable;
