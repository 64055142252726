import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';

import { AuthContext } from 'stores/auth';

const DashboardManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
    <div className="mr-8 my-8">
      <div className="mx-24">
        <div className="flex-1">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Dashboard Manual</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="divide-y divide-gray-200">

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                <dt className="text-m font-medium text-gray-700">View Dashboard</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                  <span className="flex-grow col-span-4">1. On the sidebar of the Client Portal, click the Dashboard button.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-1.png"/>
                  <span className="flex-grow col-span-4">2. The Dashboard contains an overview of your data.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-2.png"/>
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                <dt className="text-m font-medium text-gray-700">View Overview of Shares</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                  <span className="flex-grow col-span-4">1. Go to the Client Portal Dashboard.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-2.png"/>
                  <span className="flex-grow col-span-4">2. The Overview Charts is located on the left side of the dashboard.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-3.png"/>
                  <span className="flex-grow col-span-4">3. Click the dropdown sorter located on the upper right of the charts to sort accordingly by date or amount. Click the up arrow to sort data in ascending order, and the down arrow to sort data in descending order.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-4.png"/>
                  <span className="flex-grow col-span-4">4. Click the dropdown sorters located on the bottom of the charts to select overview of specific periods. Choose the starting and ending dates.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-5.png"/>
                  <span className="flex-grow col-span-4">5. To remove Exploration Share or Gross Revenue Share display, click on the labels located on top of the charts.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-6.png"/>
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                <dt className="text-m font-medium text-gray-700">View Top Clients</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                  <span className="flex-grow col-span-4">1. Go to the Client Portal Dashboard.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-2.png"/>
                  <span className="flex-grow col-span-4">2. The Top Clients Charts is located on the right side of the dashboard.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-7.png"/>
                  <span className="flex-grow col-span-4">3. Click the dropdown sorter located on the upper right of the charts to sort accordingly by name or amount. Click the up arrow to sort gross revenue in ascending order, and the down arrow to sort gross revenue in descending order.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Dashboard/dashboard-4.png"/>
                </dd>
              </div>

            </dl>
          </div>
          <div className="py-4 sm:py-5 sm:gap-4 sm:text-right">
            <Link to="/help" className="text-m font-medium text-blue-800">Back</Link>
          </div>
        </div>
      </div>
    </div>
  </div>)
  });
}

export default DashboardManual
