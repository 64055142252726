import React from 'react';
import { useObserver } from 'mobx-react';

import AddClientForm from './components/AddClientForm'

const AddClient = () => {

  return useObserver(() => {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className='px-4 sm:px-6 lg:px-8 py-5'>
          <div className="md:flex md:items-center md:justify-between my-5">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Add Client</h2>
            </div>
            {/* <div className="mt-4 flex md:mt-0 md:ml-4">
              <Link type="button" to="/clients" className="button ml-3 inline-flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div className="ml-2">Cancel</div>
              </Link>
            </div> */}
          </div>
          <AddClientForm />
        </div>
      </div>)
    });
}

export default AddClient
