import axios from 'axios'

const buildUrl = (url, query) => {
  let finalUrl = process.env.REACT_APP_API_ROOT + url;
  while (finalUrl.includes('//')) {
    finalUrl = finalUrl.replace('//', '/');
  };
  let tmpUrl = new URL(finalUrl);
  if (query) {
    var searchParams = tmpUrl.searchParams;
    Object.keys(query).map(key => searchParams.set(key, query[key]));
    tmpUrl.search = searchParams.toString();
  }
  return tmpUrl.toString();
};

const _getAccessToken = () => {
  return localStorage.getItem('accessToken')
}

export const _getRefreshToken = () => {
  return localStorage.getItem('refreshToken')
}

const getFormData = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

export const PrivateAPI = {
  get: (url, query) => {
    return axios({
      method: 'get',
      url: buildUrl(url, query),
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  },

  getfile: (url) => {
    return axios({
      method: 'get',
      responseType: 'blob',
      url: buildUrl(url),
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  },

  post: (url, payload) => {
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  },

  put: (url, payload) => {
    return axios({
      method: 'put',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  },

  postfile: (url, payload) => {
    const formData = getFormData(payload)
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: formData,
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  }
}

export const PublicAPI = {
  get: (url, query) => {
    return axios({
      method: 'get',
      url: buildUrl(url),
    })
  },

  post: (url, payload) => {
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: payload
    })
  }
}
