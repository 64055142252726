import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';

import { AuthContext } from 'stores/auth';
import { PaperClipIcon } from '@heroicons/react/solid'

const TableofContents = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">User Manual</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Navigate the application through the help section</p>
      </div>
      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
            <Link to="/support/login" className="text-sm font-medium text-blue-700">Login</Link>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-y-4 text-left">
              <span className="flex-grow col-span-2">with Chosen Email</span>
              <span className="flex-grow">with Google Account</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
            <Link to="/support/dashboard" className="text-sm font-medium text-blue-700">Dashboard</Link>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-y-4">
              <span className="flex-grow col-span-2">Overview</span>
              <span className="flex-grow">Top Clients</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
            <Link to="/support/clients" className="text-sm font-medium text-blue-700">Clients</Link>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-y-4">
              <span className="flex-grow col-span-2">View Clients List</span>
              <span className="flex-grow col-span-2">Add Client</span>
              <span className="flex-grow col-span-2">Set Client as Inactive</span>
              <span className="flex-grow col-span-2">Set Client as Active</span>
              <span className="flex-grow col-span-2">Client Page</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Add User</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Assign User to Client</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Add File</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Overview</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Net Revenue</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Transactions</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Contracts</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
            <Link to="/support/users" className="text-sm font-medium text-blue-700">Users</Link>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-y-4">
              <span className="flex-grow col-span-2">View User List</span>
              <span className="flex-grow col-span-2">Add User through the User Page</span>
              <span className="flex-grow col-span-2">Add User through the Client Page</span>
              <span className="flex-grow col-span-2">Edit User</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
            <Link to="/support/profile" className="text-sm font-medium text-blue-700">Profile</Link>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-y-4">
              <span className="flex-grow col-span-2">View Profile Information</span>
              <span className="flex-grow col-span-2">Edit</span>
              <span className="flex-grow col-span-2">Delete</span>
              <span className="flex-grow col-span-2">Add to Client</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
            <Link to="/support/clientinfo" className="text-sm font-medium text-blue-700">Client Information</Link>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:gap-y-4">
              <span className="flex-grow col-span-2">Transaction History</span>
              <span className="flex-grow col-span-2">Bank Information</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">Edit Bank Info</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">US Form</span>
              <span className="flex-grow col-span-2 pl-8 text-gray-400">W-9 Form</span>
              <span className="flex-grow col-span-2 pl-5 text-gray-500">International Form</span>
              <span className="flex-grow col-span-2 pl-8 text-gray-400">W-8BEN Form</span>
              <span className="flex-grow col-span-2 pl-8 text-gray-400">W-8BEN-E Form</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
            <Link to="/support/logout" className="text-sm font-medium text-blue-700">How to Log out</Link>
          </div>

        </dl>
      </div>
    </div>)
  });
}

export default TableofContents
