import React from 'react';
import { useParams } from 'react-router-dom';
import AssetsTable from './components/AssetsTable';

const ClientAssets = ({ client }) => {
  let { uid } = useParams();

  return (<>
    <AssetsTable uid={uid} client={client} />
  </>);
}

export default ClientAssets;
