import React, { useState, useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'stores/auth';

import GoogleButton from '../../../components/Buttons/GoogleButton';
import { useForm } from 'react-hook-form';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { login } from 'api/expauth';

function LogIn() {
  const authStore = useContext(AuthContext);
  const auth = getAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    data['username'] = data['email']
    await signInWithEmailAndPassword(auth, data['email'], data['password']).then((userCredential) => {
      let firebaseUser = userCredential.user;
      data['accessToken'] = firebaseUser.accessToken;
      authStore.setLoading(true);
      login(data).then(res=>{
        localStorage.setItem("accessToken", res.data.access);
        authStore.setUser(res.data.user);
        authStore.setLoggedIn(true);
        authStore.setLoading(false);
      })
      .catch(err=>{
        console.log(err.response)
      })
    }).catch((error) => {
      const errorCode = error.code;
      if (errorCode === 'auth/wrong-password') {
        setError("password", {
          type: "manual",
          message: "Wrong password",
        })
      } else {
        setError("password", {
          type: "manual",
          message: "Invalid login",
        })
      }
    });
  };

  return useObserver(() => {
    if (authStore.loggedIn) {
      return (<Redirect to='/' />)
    } else {
      return (<div id="login_page" className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="flex items-center">
                <img
                  className="h-10 w-auto inline-block"
                  src="/assets/images/Logo.png"
                  alt="Exploration"
                />
                <h2 className="text-3xl font-extrabold text-gray-900 inline-block align-middle mx-5">Client Portal</h2>
              </div>

              <div className="mt-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700"> Email address </label>
                    <div className="mt-1">
                      <input
                      {...register('email')}
                      id="email" name="email" type="email" autoComplete="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700"> Password </label>
                    <div className="mt-1">
                      <input
                      {...register('password')}
                      id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  {errors?.password?.type === "manual" ? (
                    <p className="text-red-500 text-sm">{errors?.password?.message}</p>
                  ) : null}

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500"> Forgot your password? </Link>
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-800 hover:from-blue-800 hover:to-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sign in</button>
                  </div>
                </form>
              </div>
              <div className="mt-6 relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-100 text-gray-500"> OR </span>
                </div>
              </div>

              <div className="mt-2">
                <GoogleButton />
              </div>

              {/* <Link to='/dashboard'>
                <div>
                  <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    Continue with Email
                  </button>
                </div>
              </Link> */}
              {/* <p className="mt-2 text-sm">
                <span className="font-medium text-gray-400 hover:text-gray-500">
                  By signing in, you agree to Exploration's Terms & Conditions and Privacy Policy.
                </span>
              </p> */}
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/assets/images/Landing.jpg"
            alt=""
          />
        </div>
      </div>)
    }
  })
}

export default LogIn;
