import React from 'react';
import { Link } from 'react-router-dom';

const W8BENEForm = () => {
  return (<>
    <div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <div className="w-full mx-auto">
            <div className="md:flex md:items-center md:justify-between my-5">
              <div className="flex-1 min-w-0 grid grid-cols-2">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate col-span-2">W-8BEN-E Form</h2>
                <label> Certificate of Status of Beneficial Owner for
                  United States Tax Withholding and Reporting <span className="font-semibold">(Entities)</span>
                </label>
                <div className="flex justify-end mt-2">
                  <Link to="/info/bank-info/form/int/w8ben" type="button" className="button bg-white py-2 px-2 border border-gray-300 rounded-md shadow-sm text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Switch to W-8BEN Form (Individuals)</Link>
                </div>
              </div>
            </div>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <form action="#" method="POST">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">I. Identification of Beneficial Owner </h3>
                    <p className="mt-1 text-sm text-gray-500">If you already have an accomplished W8 Form please go back to the
                      <Link to="/info/bank-info/form/int" className="text-blue-700 font-medium"> Bank Information section </Link>
                      and choose file upload.</p>
                  </div>

                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Name of organization that is the beneficial owner </label>
                        <input type="text" name="first-name" id="first-name" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700"> Country of incorporation or organization</label>
                        <input type="text" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Name of entity receiving the payment </label>
                        <input type="text" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Chapter 3 Status (entity type)</label>
                        <div className="grid grid-cols-2">
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Corporation</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Partnership</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Simple Trust</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Tax-exempt organization</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Complex Trust</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Central Bank of Issue</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Private foundation</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Estate</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Grantor trust</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Disregarded entity</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">International organization</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Foreign Government - Controlled Entity</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Foreign Government - Integral Part</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Chapter 4 Status (FATCA status)</label>
                        <div className="grid grid-cols-1">
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs"> Nonparticipating FFI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Participating FFI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Reporting Model 1 FFI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Reporting Model 2 FFI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Registered deemed-compliant FFI </label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Sponsored FFI. Complete Part IV</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Certified deemed-compliant nonregistering local bank. Complete
                              Part V</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Certified deemed-compliant FFI with only low-value accounts.
                              Complete Part VI
                            </label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Certified deemed-compliant sponsored, closely held investment
                              vehicle. Complete Part VII
                            </label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Certified deemed-compliant limited life debt investment entity.
                              Complete Part VIII
                            </label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Certain investment entities that do not maintain financial accounts.
                              Complete Part IX
                            </label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Owner-documented FFI. Complete Part X</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Restricted distributor. Complete Part XI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Nonreporting IGA FFI. Complete Part XII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Foreign gov, gov of a U.S. possession, or foreign
                              central bank of issue. Complete Part XIII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">International organization. Complete Part XIV</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Exempt retirement plans. Complete Part XV</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Entity wholly owned by exempt beneficial owners. Complete Part XVI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Territory financial institution. Complete Part XVII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Excepted nonfinancial group entity. Complete Part XVIII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Excepted nonfinancial start-up company. Complete Part XIX</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Excepted nonfinancial entity in liquidation or bankruptcy.
                              Complete Part XX</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">501(c) organization. Complete Part XXI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Nonprofit organization. Complete Part XXII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">
                              Publicly traded NFFE or NFFE affiliate of a publicly traded
                              corporation. Complete Part XXIII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Excepted territory NFFE. Complete Part XXIV</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Active NFFE. Complete Part XXV</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Passive NFFE. Complete Part XXVI</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Excepted inter-affiliate FFI. Complete Part XXVII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Direct reporting NFFE</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Sponsored direct reporting NFFE. Complete Part XXVIII</label>
                          </div>
                          <div class="form-check">
                            <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                            <label className="form-check-label inline-block text-gray-800 text-xs">Account that is not a financial account</label>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">
                          Permanent residence address (street, apt. or suite no., or rural route).
                          <div className="font-semibold"> Do not use a P.O. box or in-care-of address. </div>
                        </label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label className="block text-sm font-medium text-gray-700">City or town, state or province. Include postal code. </label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Country</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Mailing Address </label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label className="block text-sm font-medium text-gray-700">Mailing city or town, state or province. Include postal code. </label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Country</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label for="social-security" className="block text-sm font-medium text-gray-700">U.S. taxpayer identification (TIN)</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <label for="employer-identification" className="block text-sm font-medium text-gray-700"> GIIN </label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <label for="employer-identification" className="block text-sm font-medium text-gray-700"> FTIN </label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-2 mt-5">
                        <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                        <label for="social-security" className="block text-sm font-medium text-gray-700">FTIN not required</label>
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label for="employer-identification" className="block text-sm font-medium text-gray-700"> Reference number(s) </label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>
                  </div>

                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">II. Claim of Tax Treaty Benefits</h3>
                    <p className="mt-1 text-sm text-gray-500">Certify that the beneficial owner is a resident of the country within the meaning of the income tax
                      treaty between the United States and that country.</p>
                  </div>

                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label for="social-security" className="block text-sm font-medium text-gray-700">
                          Country
                        </label>
                        <input type="text" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>

                    <div className="grid grid-cols-6 gap-6 mt-5">
                      <div className="col-span-6 sm:col-span-3">
                        <label for="social-security" className="block text-sm font-medium text-gray-700 font-semibold">
                          Special Rates and Conditions
                        </label>
                      </div>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-4">
                        <label for="social-security" className="block text-sm font-medium text-gray-700">
                          Article and paragraph Provision
                        </label>
                        <input type="text" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label for="social-security" className="block text-sm font-medium text-gray-700">
                          Rate of Withholding
                        </label>
                        <input type="text" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-5 mt-5">
                      <label className="form-check-label inline-block text-gray-800 text-xs">Notes</label>
                      <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>
                  </div>

                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">III. Certification </h3>
                    <p className="mt-1 text-sm text-gray-500">Certify that the details shown on this form your correct taxpayer identification and details.</p>
                  </div>

                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label for="formFile" class="text-sm font-medium text-gray-700">Upload E-Signature</label>
                        <input class="form-control block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile" />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label for="date" className="block text-sm font-medium text-gray-700">Date</label>
                        <input type="text" name="date" id="date" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>
                    <div className="flex justify-end mt-5">
                      <Link to="/info/bank-info/form/int" type="button" className="ml-3 button bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Cancel</Link>
                      <Link to="/info/bank-info/form/int" type="button" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Request Update</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default W8BENEForm;
