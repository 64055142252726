import { useState, useRef, useEffect, useCallback } from 'react';
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { UsersIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { parseISO, format } from 'date-fns';

import { classNames } from 'components/utils';
import ProfileImage from 'components/ProfileImage';
import Paginate from 'components/Pagination';
import { searchClients } from 'api/core';

const SelectPalette = ({
  ctaText,
  ctaAction,
  selectedClient
}) => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState();
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    'hasNext': false,
    'hasPrev': false,
    'total': 1,
    'pages': 1
  });
  const [query, setQuery] = useState('');

  const fetchData = () => {
    setLoading(true);
    searchClients(query, page).then(res => {
      setClients(res.data?.data);
      setPageInfo({
        'hasNext': res.data?.has_next,
        'hasPrev': res.data?.has_previous,
        'total': res.data?.total,
        'pages': res.data?.pages
      });
      setLoading(false);
    }).catch(err => {
      console.log('err');
      setLoading(false);
    });
  }


  useEffect(() => {
    fetchData();
  }, [])

  return (<>
    <Combobox>
      {({ activeOption }) => (
        <>
          <div className="relative">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <Combobox.Input
              className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <button 
                type="button"
                onClick={() => fetchData()}
                className="ml-4 inline-flex items-center px-4 py-2 shadow-sm text-xs font-medium rounded text-gray-500 bg-gray-100 hover:text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:cursor-pointer">
                Search
              </button>
            </div>
          </div>

          {(query === '' || clients?.length > 0) && (
            <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
              <div
                className={classNames(
                  'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                  activeOption && 'sm:h-96'
                )}
              >
                {loading && (
                  <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500">Loading clients...</h2>
                )}
                <div className="-mx-2 text-sm text-gray-700">
                  {clients && clients.map((client) => {
                    if (selectedClient?.id !== client?.id) {
                      return (<Combobox.Option
                        as="div"
                        key={client.id}
                        value={client}
                        className={({ active }) =>
                          classNames(
                            'flex cursor-default select-none items-center rounded-md p-2',
                            active && 'bg-gray-100 text-gray-900'
                          )
                        }
                      >
                        {({ active }) => (
                          <>
                            <ProfileImage
                              user={client.slug}
                              alt={client.name}
                              className="h-6 w-6 flex-none rounded-full"
                            />
                            <span className="ml-3 flex-auto truncate">{client.name} {client.external_payee_id && ('(' + client.external_payee_id + ')')}</span>
                            {active && (
                              <ChevronRightIcon
                                className="ml-3 h-5 w-5 flex-none text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </>
                        )}
                      </Combobox.Option>)
                    } else {
                      return null
                    }

                  })}
                </div>
                {!loading && clients?.length > 20 &&
                  (<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <Paginate
                        page={page}
                        setPage={setPage}
                        pageInfo={pageInfo}
                        setPageInfo={setPageInfo}
                        hidePages={true}
                      />
                    </div>
                  </div>)
                }
              </div>

              {activeOption && (
                <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                  <div className="flex-none p-6 text-center">
                    <ProfileImage
                      src={activeOption.imageUrl}
                      alt={activeOption.name}
                      className="mx-auto h-16 w-16 rounded-full"
                    />
                    <h2 className="mt-3 font-semibold text-gray-900">{activeOption.name}</h2>
                    <p className="text-sm leading-6 text-gray-500">{activeOption.role}</p>
                  </div>
                  <div className="flex flex-auto flex-col justify-between p-6">
                    <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      <dt className="col-end-1 font-semibold text-gray-900">Payee ID</dt>
                      <dd>{activeOption.external_payee_id}</dd>

                      <dt className="col-end-1 font-semibold text-gray-900">Added</dt>
                      <dd className="truncate">
                        {format(parseISO(activeOption.created), 'MMM dd, yyyy')}
                      </dd>
                    </dl>
                    <button
                      type="button"
                      className="mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => ctaAction(activeOption.uid)}
                    >
                      {ctaText}
                    </button>
                  </div>
                </div>
              )}
            </Combobox.Options>
          )}

          {query !== '' && clients?.length === 0 && (
            <div className="py-14 px-6 text-center text-sm sm:px-14">
              <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
              <p className="mt-4 font-semibold text-gray-900">No client found</p>
              <p className="mt-2 text-gray-500">
                We couldn’t find any match. Please try again.
              </p>
            </div>
          )}
        </>
      )}
    </Combobox>
  </>);
}

export default SelectPalette;
