import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useObserver } from 'mobx-react';

// import { AuthContext } from 'stores/auth';
import { getAssets } from 'api/assets';
// import LoadingNotification from 'components/LoadingNotification';

import Header from './components/Header';
import AssetLabelsTable from './components/AssetLabelsTable';
import ReportsTab from './components/ReportsTab';

const AdminHome = () => {
  const componentIsMounted = useRef(true);
  // const authStore = useContext(AuthContext)
  // let selectedAccount = authStore.selectedAccount

  // const [assets, setAssets] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  // const [pageInfo, setPageInfo] = useState({
  //   'hasNext': false,
  //   'hasPrev': false,
  //   'total': 1,
  //   'pages': 1
  // });
  const [currentTab, setCurrentTab] = useState('Asset Labels')

  const fetchData = useCallback(() => {
    // setLoading(true)
    // let uid = selectedAccount ? selectedAccount.uid : null
    getAssets(page)
      .then(res=>{
        // setAssets(res.data.data)
        // setPageInfo({
        //   'hasNext': res.data.has_next,
        //   'hasPrev': res.data.has_previous,
        //   'total': res.data.total,
        //   'pages': res.data.pages
        // })
        setPage(parseInt(res.data.page))
      })
      .catch(err=>{
        // TODO: repalce error logging
        console.log(err.response)
      }).finally(()=>{
        // setLoading(false)
      })
  }, [page])

  useEffect(() => {
    if (!componentIsMounted.current) return;
    fetchData();
    return () => {
      componentIsMounted.current = false;
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return useObserver(() => {
    return (
      <div className="px-4 sm:px-6 lg:px-8 py-5">
        <Header currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <div className="max-w-full px-4 sm:px-6 lg:px-8">
          {currentTab === 'Reports' && <ReportsTab />}
          {currentTab === 'Clients' && <></>}
          {currentTab === 'Asset Labels' && (
            <div className="max-w-5xl"><AssetLabelsTable /></div>)
          }
          {currentTab === 'Users' && <></>}
        </div>
      </div>
    )
  });
}

export default AdminHome
