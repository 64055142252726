import React from 'react';
import W8BENEForm from './components/W8BENEForm'

const W8BENE = () => {
  return (<>
    <W8BENEForm/>
  </>);
}

export default W8BENE;
