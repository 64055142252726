import axios from 'axios'

const buildUrl = (url) => process.env.REACT_APP_AUTH_ROOT + url

const _getAccessToken = () => {
  return localStorage.getItem('accessToken')
}

export const _getRefreshToken = () => {
  return localStorage.getItem('refreshToken')
}

export const PrivateAPI = {
  get: (url, query) => {
    return axios({
      method: 'get',
      url: buildUrl(url),
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      },
      params: query
    })
  },

  post: (url, payload) => {
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  },

  put: (url, payload) => {
    return axios({
      method: 'put',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  },

  delete: (url, payload) => {
    return axios({
      method: 'delete',
      url: buildUrl(url),
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  }
}

export const PublicAPI = {
  get: (url, query) => {
    return axios({
      method: 'get',
      url: buildUrl(url),
    })
  },

  post: (url, payload) => {
    return axios({
      method: 'post',
      url: buildUrl(url),
      data: payload
    })
  }
}
