import React from 'react';
import { ArrowDownTrayIcon, PrinterIcon } from '@heroicons/react/24/outline';

import { useQuery, getMonthName } from 'utils';
import Profile from "../Dashboard/components/Profile";
import Table from './components/Table';

const RoyaltySummary = ({ loading, client, contract }) => {
  let query = useQuery();
  return (<>
    <Profile
      loading={loading}
      client={client}
      contract={contract}
      showServices={false}
    />
    <div className="grid grid-cols-1 gap-4 items-start sm:px-6 lg:px-10 mt-8">
      <div className="col-span-1 w-full -mt-24">
        <div className="w-full bg-white rounded-lg shadow divide-y divide-gray-200 py-12 px-16">
          <div className="lg:flex lg:items-center lg:justify-between">
            <main className="min-w-0 flex-1">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-4xl font-bold text-gray-900">Royalty Summary</h1>
                  <p className="mt-2 text-lg text-gray-700">
                    {getMonthName(query.get('month'))} {query.get('year')}
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-2"
                  >
                    <ArrowDownTrayIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                    Download
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <PrinterIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                    Print
                  </button>
                </div>
              </div>

              <Table
                client={client}
                month={query.get('month')}
                year={query.get('year')}
              />
            </main>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default RoyaltySummary;
