import React, { useState, useRef } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  ArrowPathIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline"
import { useHistory } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { getStorage, ref, uploadBytes } from "firebase/storage"
import app from "firebase"
import uuid from "react-uuid"
import { useTranslation } from "react-i18next"

import { createUser, editUser } from "api/expauth"
import LoadingNotification from "components/LoadingNotification"

const ClientUserForm = ({ id, user, client, isProfile }) => {
  let history = useHistory()
  const [profileImage, setProfileImage] = useState("")
  const [copied, setCopied] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const hiddenFileInput = useRef(null)
  const { t, i18n } = useTranslation();

  const storage = getStorage(
    app,
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_NAME
  )

  const { register, handleSubmit, setValue, watch, control, formState: { errors }} = useForm({
    mode: "onChange",
    defaultValues: user ? user : null,
  })
  const passwordValue = watch("password")
  const phoneNumber = watch("phone_number")

  function clean(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName]
      }
    }
    return obj
  }

  const onSubmit = async (data) => {
    if (profileImage) {
      let values = data
      var imageUid = uuid()
      var storageRef = ref(storage, `images/${imageUid}`)
      values["profile_image_uid"] = imageUid
      uploadBytes(storageRef, profileImage).then((res) => {
        console.log(res)
        saveData(values)
      })
    } else {
      saveData(data)
    }
  }

  const saveData = async (data) => {
    setSubmitting(true)
    let values = clean(data)
    if (user) {
      editUser(id, values)
        .then((res) => {
          setSubmitting(false)
          if (isProfile) {
            i18n.changeLanguage(res.data.preferred_language)
            history.push(`/profile/`)
          } else {
            history.push(`/users/${res.data.id}/`)
          }
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      createUser(values)
        .then((res) => {
          setSubmitting(false)
          history.push(`/users/${res.data.id}/`)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const generatePassword = () => {
    const randomPassword =
      Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
    setValue("password", randomPassword)
    setCopied(false)
  }

  const copyPassword = () => {
    navigator.clipboard.writeText(passwordValue)
    setCopied(true)
  }

  const handleFileClick = (event) => {
    hiddenFileInput.current.click()
  }

  return (
    <>
      <LoadingNotification
        loadingText="Saving user information..."
        loading={submitting}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div>
              <div className="md:col-span-1 mb-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  User Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Use a permanent email address where you can recieve emails.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Photo
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                  <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={(e) => {
                      setProfileImage(e.target.files[0])
                    }}
                  />
                  {profileImage && (
                    <span className="text-sm ml-3 text-gray-700">
                      {profileImage.name}
                    </span>
                  )}
                  <button
                    type="button"
                    onClick={handleFileClick}
                    className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="name"
                  className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                  {...register("name", { required: true })}
                />
                {errors?.name?.type === "required" ? (
                  <p className="text-red-500 text-sm">Please enter a name</p>
                ) : null}
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                  {...register("email", { required: true })}
                />
                {errors?.email?.type === "required" ? (
                  <p className="text-red-500 text-sm">Please enter an email</p>
                ) : null}
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Phone Number
                </label>
                <Controller
                  control={control}
                  name="phone_number"
                  render={(props) => (
                    <PhoneInput
                      {...props}
                      country="us"
                      preferredCountries={["us", "ph"]}
                      containerClass="max-w-lg flex bg-gray-50 border-gray-300"
                      inputClass="block min-w-full shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                      value={phoneNumber}
                      onChange={(e) => {
                        setValue(`phone_number`, e)
                      }}
                    />
                  )}
                />
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                <label
                  htmlFor="preferred_language"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t('Preferred Language')}
                </label>
                <div className="mt-1 sm:mt-0">
                  <select
                    id="preferred_language"
                    name="preferred_language"
                    autoComplete="preferred_language"
                    {...register("preferred_language")}
                    className="max-w-lg block min-w-full focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option key="en" value="en">
                      {t('English')}
                    </option>
                    <option key="es" value="es">
                      {t('Spanish')}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default ClientUserForm
