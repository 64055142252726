import React, { useContext } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'styles/classNames';
import { AuthContext } from 'stores/auth';

// import BMI from 'components/Logos/BMI';
// import CMRRA from 'components/Logos/CMRRA';
// import SoundExchange from 'components/Logos/SoundExchange';

const Rates = ({ client, contract }) => {
  const { t } = useTranslation();
  const clientSource = localStorage.getItem('clientSource') || 'royalty';
  const authStore = useContext(AuthContext);
  const user = authStore.user;

  const service_rate_mapping = [
    { value: 'youtube', label: 'YouTube' },
    { value: 'hfa', label: 'HFA' },
    { value: 'mri', label: 'MRI' },
    { value: 'cmrra', label: 'CMRRA' },
    { value: 'mlc', label: 'The MLC' },
    { value: 'subpublishing', label: t('SubPublishing_1') },
    { value: 'subpublishingsync', label: 'Sub-Publishing Sync' },
    { value: 'ascap', label: 'ASCAP' },
    { value: 'bmi', label: 'BMI' },
    { value: 'sesac', label: 'SESAC' },
    { value: 'soundexchange', label: 'SoundExchange' },
    { value: 'record-distribution', label: t('Record Distribution') },
    { value: 'lyricfind', label: 'LyricFind' },
  ];

  const service_mapping = {
    'YouTube - Publishing': 'youtube',
    'YouTube - Sound Recording': 'youtube',
    'YouTube - Film/TV & Web side': 'youtube',
    'HFA': 'hfa',
    'The MLC': 'mlc',
    'MRI': 'mri',
    'CMMRA': 'cmrra',
    'ASCAP ': 'ascap',
    'BMI': 'bmi',
    'SESAC': 'sesac',
    'Distribution': 'record-distribution',
    'Sound Exchange': 'soundexchange',
    'Sub - Publishing': 'subpublishing',
    'LyricFind': 'lyricfind',
    'Channel Management': 'youtube',
    'Direct Licensing': 'direct-licensing'
  };

  const findRate = (contract, service) => {
    const sMap = service_mapping[service];
    const contractRate = contract?.rates?.find((e) => e.rate_type === sMap);
    return contractRate?.rate || -1;
  };

  return (<>
    <section aria-labelledby="client-overview-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2 w-full flex justify-between">
              <div>
                <h3 className="text-xl leading-6 font-medium text-gray-800">{t('Services and Rates')}</h3>
                {/* <span className="text-sm leading-6 font-medium text-gray-500">as of</span> */}
              </div>
              <div>
                {/* <span
                  type="button"
                  // onClick={() => getExport(client?.uid, year)}
                  className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 cursor-pointer hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <PencilSquareIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                  Request Change
                </span> */}
                {user?.role === 'admin' && <Link
                  to={`/clients/rates/add?uid=${client?.uid}`}
                  className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 cursor-pointer hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Add
                </Link>}
                {user?.role === 'admin' && <Link
                  to={`/clients/rates/edit?uid=${client?.uid}`}
                  className="ml-2 inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 cursor-pointer hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Edit
                </Link>}
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-0 align-middle inline-block min-w-full">
          <ul className="divide-y divide-gray-200">
            {!client?.services && (<li className="py-2 px-6 flex flex-wrap items-center animate-pulse">
              <div className="flex-1">
                <div className="rounded-full bg-gray-200 w-44 h-5" />
              </div>
              <div className="flex-none flex justify-end text-lg font-medium text-green-700">
                <div className="rounded-full bg-gray-200 w-12 h-6" />
              </div>
            </li>
            )}

            {client?.services.map((service, idx) => {
              const show = service.is_active;

              return ((show && parseFloat(service.rate) !== 0.0 ? (<li key={service.id} className="py-2 px-6 flex flex-wrap items-center">
                <div className="flex-1">
                  <span className="text-sm font-medium text-gray-500 uppercase truncate">{t(service.service)}</span>
                </div>
                <div className="flex-none flex justify-end text-lg font-medium text-green-700">
                  {service.rate !== -1 ? (
                    <span className="mt-1 text-lg tracking-tight font-semibold text-gray-900">{service.rate % 1 === 0 ? parseInt(service.rate) : service.rate}%</span>
                  ) : (
                    <span className="mt-1 text-sm tracking-tight font-semibold text-gray-400">N/A</span>
                  )}
                </div>
              </li>) : null)
            )})}
          </ul>
        </div>
      </div>
    </section>
  </>)
}

export default Rates;
