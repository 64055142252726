import React, { useRef, useEffect, useContext } from 'react';
import { useObserver } from 'mobx-react-lite';
import { EnvelopeIcon } from '@heroicons/react/20/solid'
// import { UsersIcon, DocumentChartBarIcon, CurrencyDollarIcon, BanknotesIcon } from '@heroicons/react/24/outline'

import { AuthContext } from 'stores/auth';
// import { getSummary } from 'api/core';

import ClientSearchBar from 'components/ClientSearchBar';
import { useTranslation } from 'react-i18next';

// const stats = [
//   { id: 1, name: 'Total Gross Revenue', stat: '0', icon: CurrencyDollarIcon, change: '0', changeType: 'increase' },
//   { id: 2, name: 'Total Exploration Share', stat: '0', icon: BanknotesIcon, change: '0', changeType: 'increase' },
//   { id: 3, name: 'Clients', stat: '0', icon: UsersIcon, change: '0', changeType: 'increase' },
//   { id: 4, name: 'Reports', stat: '0', icon: DocumentChartBarIcon, change: '0%', changeType: 'increase' },
// ]

const Header = () => {
  const { t } = useTranslation();
  const componentIsMounted = useRef(true);
  const authStore = useContext(AuthContext);
  const user = authStore.user;
  // const [loading, setLoading] = useState(false);
  // const [summary, setSummary] = useState(stats);
  // const [clients, setClients] = useState(0);
  // const [reports, setReports] = useState(0);

  useEffect(() => {
    if (!componentIsMounted.current) return;
    // setLoading(true);
    // getSummary().then(res => {
    //   setSummary(res.data?.summary);
    //   setClients(res.data?.active_clients);
    //   setReports(res.data?.active_reports);
    //   setLoading(false);
    // }).catch(err => {
    //   setLoading(false);
    // });

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return useObserver(() => {
    return (<div>
      <div className="w-full mx-auto">
        <div className="grid grid-cols-1 gap-2 px-2 py-5">
          <div className="py-3 px-5">
            <div className="grid grid-cols-3 justify-between items-center">
              <div className="col-span-2">
                <h2 className="text-3xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{t('Welcome to Exploration')}</h2>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div className="mt-2 flex items-center text-lg text-gray-600">
                    <EnvelopeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {user?.email}

                    {user?.role === 'admin' && <span className="inline-flex items-center ml-2 px-3 py-0.5 rounded-full text-sm font-medium bg-green-300 text-green-900">
                      {t('ADMIN')}
                    </span>}
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <ClientSearchBar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  });
}

export default Header;
