import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { format, parseISO} from "date-fns";

import { classNames } from 'components/utils';
import { getMonthName } from 'utils';
import { getTopAssets } from 'api/reports';
import { useTranslation } from 'react-i18next';

const TopAssets = ({ uid, startMonth, startYear, endMonth, endYear }) => {
  const { t } = useTranslation();
  const monthName = getMonthName(startMonth);
  const [loading, setLoading] = useState(false);
  const [topAssets, setTopAssets] = useState();

  const header = [
    {'key': 'song_title', 'label': t('Asset')},
    {'key': 'asset_id', 'label': t('Asset ID')},
    {'key': 'estimated_revenue', 'label': t('Total Estimated Earnings')},
    // {'key': 'videos_added', 'label': t('Videos added')},
    {'key': 'views', 'label': t('Views')},
  ]

  useEffect(() => {
    if(uid) {
      setLoading(true);
      getTopAssets(uid, startYear, startMonth, startYear, startMonth).then((res) => {
        setTopAssets(res?.data?.data);
        setLoading(false);
      });
    }
  }, [uid]);

  const sortedData = topAssets?.sort((a,b) => b.estimated_revenue - a.estimated_revenue);

  const stats = [
    { name: 'Total Estimated Earnings', stat: sortedData?.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.estimated_revenue);
    }, 0)},
    // { name: 'Total Videos Added', stat: sortedData?.reduce((accumulator, object) => {
    //   return accumulator + parseInt(object.videos_added);
    // }, 0)},
    { name: 'Total Views', stat: sortedData?.reduce((accumulator, object) => {
      return accumulator + parseInt(object.views);
    }, 0)},
  ]

  return (<>
    <div className="mb-6">
      <div className="text-3xl font-bold tracking-tight text-blue-gray-900">
        {t('Top 100 Earning Assets for')} <span className="text-blue-800">{t(monthName)} {startYear}</span>
      </div>
      {/* {topAssets && <p className="mt-1 max-w-3xl text-md text-gray-500">
        Data as of {format(parseISO(topAssets[0].created), "MMMM d, yyyy pppp")}
      </p>} */}
    </div>

    <div className="mb-6">
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              <NumberFormat
                value={item.name === "Total Estimated Earnings" ? parseFloat(item.stat).toFixed(2) : item.stat}
                displayType={'text'}
                thousandSeparator={true}
                prefix={item.name === "Total Estimated Earnings" && '$'}
              />
            </dd>
          </div>
        ))}
      </dl>
    </div>

    <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-6">
      <div className="border-t border-gray-200">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              {header.map((k, idx) => {
                return (
                  <th key={k.key} scope="col"
                    className={
                      classNames(
                        idx === 0 ? 'pl-4 sm:pl-6' : '',
                        "py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                      )
                    }
                  >
                    {k.label}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {sortedData?.map((rowData, idx) => (
              <tr key={'rowData__' + idx}>
                {header.map((k, kIdx) => {
                  return (
                    <td key={'claim_' + idx + '_' + kIdx}
                      className={
                        classNames(
                          k.key === 'asset' ? 'font-medium pl-4 sm:pl-6' : '',
                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500 break-all"
                        )
                      }
                    >
                      {k.key === 'song_title' && rowData.asset_title}
                      {k.key === 'asset_id' && rowData.asset_id}
                      {k.key === 'estimated_revenue' && <NumberFormat
                        value={parseFloat(rowData.estimated_revenue).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />}
                      {/* {k.key === 'videos_added' && <NumberFormat
                        value={rowData.videos_added}
                        displayType={'text'}
                        thousandSeparator={true}
                      />} */}
                      {k.key === 'views' && <NumberFormat
                        value={rowData.views}
                        displayType={'text'}
                        thousandSeparator={true}
                      />}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </>);
}

export default TopAssets;
