import PrivateAPI from "./utils"

export const getAssets = (id, page, rownum) => {
  return PrivateAPI.get(`/api/publishing/publishers/${id}/assets/${page}/${rownum}/`)
}

export const getMonthlyClaims = (month, year, endMonth, endYear, client) => {
  var params = {
    month: month,
    year: year,
    endMonth: endMonth,
    endYear: endYear,
    client: client
  }
  return PrivateAPI.get(`/api/claims/portal/report/`, params)
}

export const getMonthlyConflicts = (month, year, endMonth, endYear, client) => {
  var params = {
    month: month,
    year: year,
    endMonth: endMonth,
    endYear: endYear,
    client: client
  }
  return PrivateAPI.get(`/api/conflicts/portal/`, params)
}
export const getAssetReports = (uid) => {
  return PrivateAPI.get(`/api/importexport/export/portal/assets/${uid}/`);
};

export const getAssetReport = (uid, email, name) => {
  var params = {
    name: name,
    email: email
  }
  return PrivateAPI.get(`/api/importexport/export/portal/download/${uid}/`, params);
};

