import React, { useState, useEffect } from 'react';
import { getClientServices } from 'api/core';
import { useQuery } from 'utils';
import RateForm from './components/RateForm';
import LoadingNotification from 'components/LoadingNotification';

const EditRates = () => {
  const query = useQuery()
  let uid = query.get('uid')

  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getClientServices(uid).then(res => {
        setDefaultValues({ services: res.data?.data });
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    }
  }, [uid]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className='px-4 sm:px-6 lg:px-8 py-5'>
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Edit Service and Rate</h2>
          </div>
        </div>
        <LoadingNotification loadingText='Fetching client services...' loading={loading} />
        {!loading && <RateForm defaultValues={defaultValues} />}
      </div>
    </div>
  )
}

export default EditRates
