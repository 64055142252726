import React, { useEffect, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useHistory, useLocation, useParams } from "react-router-dom";


import { getClientAssetTitles } from "api/core"
import FilterBox from "./FilterBox";
import RelatedAssetTable from "./RelatedAssetTable";

const RelatedAssets = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page = searchParams.get('page') ?? 1
  const perPage = searchParams.get('perPage') ?? 20
  const { uid, title } =  useParams()
  const [assets, setAssets] = useState([])
  const [pageInfo, setPageInfo] = useState({
    hasNext: false,
    hasPrev: false,
    total: 0,
    pages: 1,
  })

  const { register, watch, formState: { isValid }, control, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      sort_by: "created",
      trend: "desc",
      date_from: "",
      date_to: ""
    }
  });

  const data = useWatch({ control })


  const dateFrom = new Date(watch('date_from')).getTime()
  const dateTo =  new Date(watch('date_to')).getTime()

  const [filterOptions] = useState({
    sort_by: "partner_revenue",
    trend: "desc",
    date_from: "",
    date_to: ""
  });

  useEffect(()=>{
    if (((dateFrom && dateTo) && (dateFrom <= dateTo)) || (!dateFrom && !dateTo)) {
      setLoading(true)
      getClientAssetTitles(uid, { page, perPage, title, ...data }).then(res=>{
        setAssets(res.data.data)
        setPageInfo({
          hasNext: res.data.has_next,
          hasPrev: res.data.has_previous,
          total: res.data.total,
          pages: res.data.pages
        })
      }).catch(err=>{
        console.log(err.response)
      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [uid, title, page, perPage, filterOptions, isValid, data, dateFrom, dateTo])

  const handleNext = () => {
    if (pageInfo?.hasNext) {
      history.push({
        search: "?" + new URLSearchParams({page: parseInt(page) + 1}).toString()
      })
    }
  };

  const handlePrevious = () => {
    if (pageInfo?.hasPrev) {
      if (page === "2") {
        history.push({
          search: ""
        })
      } else {
        history.push({
          search: "?" + new URLSearchParams({page: parseInt(page) - 1}).toString()
        })
      }
    }
  };

  const onReset = e => {
    e.preventDefault()
    reset()
  }

  return (
    <div className="grid grid-cols-12 gap-4">

      <div className="col-span-3">
        <FilterBox
          register={register}
          dateFrom={dateFrom}
          dateTo={dateTo}
          onReset={onReset}
        />
      </div>

      <div className="col-span-9">
        <RelatedAssetTable
          assets={assets}
          loading={loading}
          page={page}
          pageInfo={pageInfo}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        />
      </div>

    </div>
  )
}

export default RelatedAssets
