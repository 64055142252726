import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone'

import { createFile } from 'api/expclient';

const AddFileForm = ({ client, uid }) => {
  const { register, handleSubmit } = useForm()
  let history = useHistory()
  const [file, setFile] = useState(null)

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
  
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  
    const i = Math.floor(Math.log(bytes) / Math.log(k))
  
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: ['.csv', '.pdf', '.xlsx']})

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <span key={file.path}>
      {file.path} - {file.size} bytes&nbsp;
      <>
        {errors.map(e => (
          <span key={e.code}>({e.message})</span>
        ))}
      </>
    </span>
  ))

  const onSubmit = (data) => {
    let values = data
    values['file'] = file
    values['mimetype'] = file['type']
    values['filename'] = file['name']
    createFile(values, uid)
      .then(res => {
        history.push(`/clients/${uid}/`)
      })
      .catch(err=>{
        console.log(err.response);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">File Information</h3>
              <p className="mt-1 text-sm text-gray-500">You are adding a new file for {client?.name}.</p>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="file" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                File
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center" {...getRootProps()}>
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {file === null
                      ? (<>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                          >
                            <span>Upload a file</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" {...getInputProps()} />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500 text-center">CSV, PDF, XLSX up to 20MB</p>
                      </>)
                      : file === undefined
                      ? (<>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                          >
                            <span>File Rejected: </span>
                          </label>
                          <p className="pl-1">{fileRejectionItems}</p>
                        </div>
                      </>)
                      : (<div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                        >
                          <span>{file.name}</span>
                        </label>
                        <p className="pl-1">({formatBytes(file.size)})</p>
                      </div>)
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="tag" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Tag
              </label>
              <div className="mt-1 sm:mt-0">
                <select
                  id="tag"
                  name="tag"
                  autoComplete="tag"
                  {...register('tag')}
                  className="max-w-lg block min-w-full focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option key="" value="">--</option>
                  <option key="royalties" value="royalties">Royalties</option>
                  <option key="pending" value="pending">Pending</option>
                </select>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="notes" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Notes
              </label>
              <textarea
                id="notes"
                name="notes"
                rows={3}
                className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                {...register('notes')}
              />
            </div>
          </div>
        </div>

      <div className="flex justify-end">
        <Link
          to={`/clients/${client?.uid}/`}
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </Link>
        <button
          type="submit"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  </form>)
}

export default AddFileForm
