import React from 'react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from 'components/utils';

export default function AccountClientSearch ({ loading, setQuery, query }) {

  return (
    <div className="mb-5 relative flex items-stretch flex-grow min-w-24 focus-within:z-10">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="search"
        id="search"
        className={classNames(
          "focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300",
          loading ? "" : ""
        )}
        placeholder="Search Client"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        disabled={loading}
      />
      {query !== "" && (
        <div className="absolute inset-y-0 right-0 flex py-2 pr-1.5">
            <XMarkIcon
                className="cursor-pointer h-5 w-5 text-blue-400"
                aria-hidden="true"
                onClick={() => setQuery('')}
            />
        </div>)
      }
    </div>
  )
}
