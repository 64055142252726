import React, { useState, useEffect } from 'react'
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import NumberFormat from 'react-number-format';

import { getClientAssetStat } from "api/assets";

const AssetStats = ({ client, title }) => {
  // const [loading, setLoading] = useState()
  const [boxStats, setBoxStats] = useState([
    { id: 1, name: 'Total Revenue', stat: '$0', icon: CurrencyDollarIcon, change: '0', changeType: 'increase' }
  ]);

  useEffect(() => {
    // setLoading(true);
    getClientAssetStat(client.uid, title).then((res) => {
      setBoxStats([
        { id: 1, name: 'Total Revenue', stat: res.data.total_revenue, icon: CurrencyDollarIcon, change: '0', changeType: 'increase' }
      ]);
    }).finally(()=>{
      // setLoading(false);
    })
  }, [client, title]);

  return (<div>
    <dl className="grid grid-cols-1 gap-5">
      {boxStats.map((item) => {
        return (<div
          key={item.id}
          className="relative bg-white pt-5 px-4 pb-6 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
        >
          <dt>
            <div className="absolute bg-indigo-500 rounded-md p-3">
              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
          </dt>
          <dd className="ml-16 pb-6 flex items-baseline sm:pb-6">
            <p className="text-2xl font-semibold text-gray-900">
              <NumberFormat
                value={item?.stat ? item.stat : 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
            {/* <p
              className={classNames(
                item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                'ml-2 flex items-baseline text-sm font-semibold'
              )}
            >
              {item.changeType === 'increase' ? (
                <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
              ) : (
                <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
              )}

              <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
              {item.change}
            </p> */}
          </dd>
        </div>
      )})}
    </dl>
  </div>);
}

export default AssetStats;
