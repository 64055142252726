import React from 'react';
import { Link } from 'react-router-dom';

const W9Form = () => {
  return (<>
    <div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <div className="w-full mx-auto">
            <div className="md:flex md:items-center md:justify-between my-5">
              <div className="flex-1 min-w-0 grid grid-cols-2">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate col-span-2">W-9 Form</h2>
                Request for Taxpayer
                Identification Number and Certification
              </div>
            </div>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <form action="#" method="POST">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">I. Tax Payer Information</h3>
                    <p className="mt-1 text-sm text-gray-500">If you already have an accomplished W9 Form please go back to the
                      <Link to="/info/bank-info/form/us" className="text-blue-700 font-medium"> Bank Information section </Link>
                      and choose file upload.</p>
                  </div>

                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label for="first-name" className="block text-sm font-medium text-gray-700">First Name</label>
                        <input type="text" name="first-name" id="first-name" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label for="last-name" className="block text-sm font-medium text-gray-700">Last Name</label>
                        <input type="text" name="last-name" id="last-name" autocomplete="family-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Business Name</label>
                        <input type="text" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Federal Tax Classification</label>
                        <div className="grid grid-cols-5">
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio"/>
                            <label className="form-check-label inline-block text-gray-800 text-xs">Individual</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio"/>
                            <label className="form-check-label inline-block text-gray-800 text-xs">C Corporation</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio"/>
                            <label className="form-check-label inline-block text-gray-800 text-xs">S Corporation</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio"/>
                            <label className="form-check-label inline-block text-gray-800 text-xs">Partnership</label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio"/>
                            <label className="form-check-label inline-block text-gray-800 text-xs">Trust/Estate</label>
                          </div>
                          <div class="form-check col-span-5">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio"/>
                            <label className="form-check-label inline-block text-gray-800 text-xs">Limited liability company. Enter classification in notes (C=C corporation, S=S corporation, P=Partnership)</label>
                          </div>
                          <div class="form-check col-span-1">
                            <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio"/>
                            <label className="form-check-label inline-block text-gray-800 text-xs">Other</label>
                          </div>
                          <div className="col-span-6 sm:col-span-5">
                            <label className="form-check-label inline-block text-gray-800 text-xs">Notes</label>
                            <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Exempt Payee Code</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Exemption from FATCA reporting code</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Address (number, street, and apt. or suite no.)</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">City, state, and ZIP code</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">List of account number(s)</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">Branch Contact Number</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-6">
                        <label className="block text-sm font-medium text-gray-700">Requester's Name and Address</label>
                        <input className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>
                  </div>

                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">II. Tax Payer Identification Number (TIN)</h3>
                    <p className="mt-1 text-sm text-gray-500">The TIN provided must match the name given on Section I </p>
                  </div>

                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label for="social-security" className="block text-sm font-medium text-gray-700">Social Security Number</label>
                        <input type="text" name="social-security" id="social-security" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label for="employer-identification" className="block text-sm font-medium text-gray-700">Employer Identification Number</label>
                        <input type="text" name="employer-identification" id="employer-identification" autocomplete="family-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>
                  </div>

                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">III. Certification</h3>
                    <p className="mt-1 text-sm text-gray-500">Certify that the details shown on this form your correct taxpayer identification and details.</p>
                  </div>

                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label for="formFile" class="text-sm font-medium text-gray-700">Upload E-Signature</label>
                        <input class="form-control block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile" />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label for="date" className="block text-sm font-medium text-gray-700">Date</label>
                        <input type="text" name="date" id="date" autocomplete="given-name" className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>
                    <div className="flex justify-end mt-5">
                      <Link to="/info/bank-info/form/us" type="button" className="ml-3 button bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Cancel</Link>
                      <Link to="/info/bank-info/form/us" type="button" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Request Update</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default W9Form;
