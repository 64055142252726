import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthContext } from 'stores/auth';

const UsersManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <div className="flex-1">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">User Manual</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
            </div>
            <div className="mt-5 border-t border-gray-200">
              <dl className="divide-y divide-gray-200">

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View User List</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the sidebar of the Client Portal, click the Users button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-1.png"/>
                    <span className="flex-grow col-span-4">2. The list of users registered is displayed on the screen with their email, name, and role.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-2.png"/>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Add User through the User Page</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the user page, click the Add User button located on the upper right side of the page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-3.png"/>
                    <span className="flex-grow col-span-4">2. Fill out required user data.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-4.png"/>
                    <span className="flex-grow col-span-4">3. Click the Save button located on the bottom right of the page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-5.png"/>
                    <span className="flex-grow col-span-4">4. User has now been added.</span>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Add User through the Client Page</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the sidebar of the Client Portal, click the Clients button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-1.png"/>
                    <span className="flex-grow col-span-4">2. On the top right side of the client page, locate the Team section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-13.png"/>
                    <span className="flex-grow col-span-4">3. Click the Add User button located on the top right of the Team section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-14.png"/>
                    <span className="flex-grow col-span-4">4. Fill out required user data.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-15.png"/>
                    <span className="flex-grow col-span-4">5. Click the Save button located on the bottom right of the page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-7.png"/>
                    <span className="flex-grow col-span-4">6. User has now been added.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Clients/clients-2.png"/>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Edit User</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the user page, the list of users registered is displayed on the screen with their email, name, and role. Locate the user you want to edit.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-2.png"/>
                    <span className="flex-grow col-span-4">2. Click the edit button on the last column of the user you want to edit.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-6.png"/>
                    <span className="flex-grow col-span-4">3. Fill out the details you want to update.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-7.png"/>
                    <span className="flex-grow col-span-4">4. Click the save button located on the bottom right of the screen.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Users/users-5.png"/>
                    <span className="flex-grow col-span-4">5. The user has been updated.</span>
                  </dd>
                </div>

              </dl>
            </div>
            <div className="py-4 sm:py-5 sm:gap-4 sm:text-right">
              <Link to="/help" className="text-m font-medium text-blue-800">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>)
  });
}

export default UsersManual
