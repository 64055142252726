import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getServices, addServiceRate } from 'api/core';
import { useQuery } from 'utils';

const AddRates = () => {
  let history = useHistory()
  const query = useQuery()
  let uid = query.get('uid')
  const [serviceOptions, setServiceOptions] = useState([{ slug: '', name: '---'}]);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm()

  useEffect(() => {
    setLoading(true);
    getServices().then(res => {
      setServiceOptions(res.data.data);
      setLoading(false);
    }).catch(err=>{
      console.log(err.response);
    });
  }, []);

  const onSubmit = (data) => {
    addServiceRate(uid, data)
      .then(res => {
        history.push(`/clients/${uid}`)
      })
      .catch(err=>{
        console.log(err.response);
      });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className='px-4 sm:px-6 lg:px-8 py-5'>
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Add Services and Rates</h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-6">
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div>
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Client Services and Rates</h3>
                  <p className="mt-1 text-sm text-gray-500">You may add or choose an existing service.</p>
                </div>
              </div>

              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Service Name
                  </label>
                  {loading ? <span>Loading...</span>
                    : (<select
                      type="text"
                      name="slug"
                      id="slug"
                      className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      {...register('slug', { required: true })}
                    >
                      {serviceOptions.map((service) => (
                        <option value={service.slug}>{service.name}</option>
                      ))}
                    </select>)
                  }
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Rate
                  </label>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    name="rate"
                    id="rate"
                    className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    {...register('rate', { required: true })}
                  />
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Is Invoice?
                    <span className="pl-2">
                      <input
                        id="is_invoice"
                        name="is_invoice"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        {...register('is_invoice')}
                      />
                    </span>
                  </label>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Is Active?
                    <span className="pl-5">
                      <input
                        id="is_active"
                        name="is_active"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        checked="false"
                        {...register('is_active')}
                      />
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <Link
                to="/clients"
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddRates
