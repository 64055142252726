export const BankAccountTypes = {
  PERSONAL: 'personal',
  CHECKING: 'checking',
  BUSINESS: 'business',
  SAVINGS: 'savings',

  choices: function() {
    return [
      { value: this.PERSONAL, label: this.PERSONAL },
      { value: this.CHECKING, label: this.CHECKING },
      { value: this.BUSINESS, label: this.BUSINESS },
      { value: this.SAVINGS, label: this.SAVINGS },
    ]
  },

}