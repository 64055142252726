import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { createClient, checkSlug } from 'api/expclient';
import PayeeIdField from './PayeeIdField';

const AddClientForm = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm()
  let history = useHistory()
  const [isSlugUnique, setIsSlugUnique] = useState(true);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    createClient(data)
      .then(res => {
        history.push(`/clients/${res.data?.uid}`)
      })
      .catch(err=>{
        console.log(err.response);
      });
  };

  function slugifyString (text) {
    if (text) {
      return text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
    } else {
      return ''
    }
  };

  const checkSlugUnique = async (slug) => {
    setLoading(true);
    setIsSlugUnique(true);
    try {
      const response = await checkSlug(slug);
      setIsSlugUnique(response.data.is_unique);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  // Handle client name input and check slug uniqueness
  const handleClientName = async (e) => {
    const clientName = e.target.value;
    if (clientName) {
      const slug = slugifyString(clientName);
      setValue('slug', slug);
      await checkSlugUnique(slug);  // Check uniqueness whenever the name changes
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Client Information</h3>
              <p className="mt-1 text-sm text-gray-500">This information will only be displayed to admins.</p>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Client Name
              </label>
              <div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('name', { required: true })}
                  onChange={handleClientName}
                />
                {errors?.name?.type === "required" ? (
                    <div className="text-red-500 text-sm">Client Name is required</div>
                ) : null}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Slug
              </label>
              <div>
                <input
                  type="text"
                  name="slug"
                  id="slug"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('slug', { required: true })}
                />
                {loading && <p className="text-sm">Checking slug uniqueness...</p>}
                {!isSlugUnique && <p className="text-red-500 text-sm">Slug is already taken</p>}
                {errors?.slug?.type === "required" && (
                  <p className="text-red-500 text-sm">Slug is required</p>
                )}
              </div>
            </div>

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                CMS
              </label>
              <div>
                <input
                  type="text"
                  name="cms"
                  id="cms"
                  className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  {...register('cms', { required: true })}
                />
                {errors?.cms?.type === "required" ? (
                  <p className="text-red-500 text-sm">CMS is required</p>
                ) : null}
              </div>
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Own CMS?
                <span className="pl-2">
                  <input
                    id="own_cms"
                    name="own_cms"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </span>
              </label>
            </div> */}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Client Status
              </label>
              <div className="mt-1 sm:mt-0">
                <select
                  id="client_type"
                  name="client_type"
                  autoComplete="client_type"
                  {...register('client_type', { required: true })}
                  className="max-w-lg block min-w-full focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option key="royalty" value="royalty">Royalty</option>
                  <option key="invoice" value="invoice">Invoice</option>
                  <option key="invoice_royalty" value="invoice_royalty">Invoice and Royalty</option>
                </select>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Royalties - Payee ID
              </label>
              <PayeeIdField
                register={register}
              />
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Is Active?
                <span className="pl-5">
                  <input
                    id="is_active"
                    name="is_active"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register('is_active')}
                  />
                </span>
              </label>
            </div>

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
              <label htmlFor="start-date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Start Date
              </label>
              <input
                type="date"
                name="start-date"
                id="start-date"
                autoComplete="start-date"
                className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                {...register('start_date', { required: true })}
              />
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
              <label htmlFor="termination-date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Termination Date <span className="text-gray-500">(Optional)</span>
              </label>
              <input
                type="date"
                name="termination-date"
                id="termination-date"
                autoComplete="termination-date"
                className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                {...register('termination_date')}
              />
            </div> */}
          </div>
        </div>

      {/* <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Team</h3>
            <p className="mt-1 text-sm text-gray-500">A dedicated team of users allowed to access this client.</p>
            <div className="pt-3">
              <div className="flex">
                <button
                  type="button"
                  onClick={() => append()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add User
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={onSubmit}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  {fields.map((user, index) => {
                    return (<div className="mb-3" key={user}>
                      <label htmlFor={`users[${index}]`} className="block text-sm font-medium text-gray-700">
                        User {index + 1}
                      </label>
                      <Controller
                        className="inline-flex"
                        key={user}
                        control={control}
                        name={`users[${index}]`}
                        render={(props) => (
                        <UserSelect
                          {...props}
                          options={options}
                          onChange={(e) => {
                            setValue(`users[${index}]`, e)
                          }}
                        />
                        )}
                      />
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="mt-1 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Remove
                      </button>
                    </div>)
                    })
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}

      <div className="flex justify-end">
        <Link
          to="/clients"
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </Link>
        <button
          type="submit"
          disabled={loading || !isSlugUnique}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  </form>)
}

export default AddClientForm
