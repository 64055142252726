import React, { useEffect, useState } from 'react';

import { classNames } from '../utils';
import { useTranslation } from 'react-i18next';

const MonthYearSelect = ({ defaultMonth, defaultYear, actionHandler }) => {
  const { t } = useTranslation();

  const Months = [
    { label: t('January'), value: 1 },
    { label: t('February'), value: 2 },
    { label: t('March'), value: 3 },
    { label: t('April'), value: 4 },
    { label: t('May'), value: 5 },
    { label: t('June'), value: 6 },
    { label: t('July'), value: 7 },
    { label: t('August'), value: 8 },
    { label: t('September'), value: 9 },
    { label: t('October'), value: 10 },
    { label: t('November'), value: 11 },
    { label: t('December'), value: 12 }
  ];

  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  
  const currentYear = (new Date()).getFullYear();
  const currentMonth = (new Date()).getMonth();

  const [month, setMonth] = useState(defaultMonth || currentMonth);
  const [year, setYear] = useState(defaultYear || currentYear);

  const handleMonthChange = (evt) => {
    setMonth(evt.target.value);
  }

  const handleYearChange = (evt) => {
    setYear(evt.target.value);
  }

  useEffect(() => {
    actionHandler(month, year);
  }, [month, year, actionHandler])

  return (<div
    className={classNames(
      'mt-1'
    )}
  >
    <div className="w-full flex text-lg">
      <select
        id={'month'}
        name={'month'}
        className="col-span-5 block w-full pl-3 pr-10 py-2 text-lg border-0 focus:outline-none focus:ring-0 focus:border-gray-300 sm:text-sm rounded-lg mr-2"
        onChange={handleMonthChange}
        value={month}
      >
        {Months.map(opt => (
          <option
            key={opt.value}
            value={opt.value}
          >
            {opt.label}
          </option>
        ))}
      </select>
      <select
        id={'year'}
        name={'year'}
        className="col-span-5 block w-full pl-3 pr-10 py-2 text-lg border-0 focus:outline-none focus:ring-0 focus:border-gray-300 sm:text-sm rounded-lg"
        onChange={handleYearChange}
        value={year}
      >
        {range(currentYear, currentYear - 5, -1).map(opt => (
          <option
            key={opt}
            value={opt}
          >
            {opt}
          </option>
        ))}
      </select>
    </div>
  </div>);
}

export default MonthYearSelect;
