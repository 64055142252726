import React, { useState, useContext } from 'react';
import {
  CogIcon,
  KeyIcon,
  SquaresPlusIcon,
  ArrowUturnLeftIcon
} from '@heroicons/react/24/outline'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'

import { classNames } from 'components/utils';
import { useQuery, getMonthName } from 'utils';
import { AuthContext } from 'stores/auth';

import PerformanceSummary from './components/PerformanceSummary';
import YTManualClaims from './components/YTManualClaims';
import MechManualClaims from './components/MechManualClaims';
import Banner from './components/Banner';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DownloadExport from './components/DownloadExport';

const NonCMSMonthlyReport = ({ loading, client, stats, contract }) => {
  const { t } = useTranslation();
  const subNavigation = [
    {
      name: t('Performance Summary'),
      description: '',
      icon: CogIcon,
    },
    {
      name: t('YouTube Manual Claims'),
      description: '',
      icon: KeyIcon,
    },
    // {
    //   name: 'Top Earning Assets',
    //   description: '',
    //   icon: PhotoIcon,
    // },
    // {
    //   name: 'Top Earning Videos',
    //   description: '',
    //   icon: BanknotesIcon,
    // },
    // {
    //   name: 'MRI - Claiming',
    //   description: 'Nisi, elit volutpat odio urna quis arcu faucibus dui. Mauris adipiscing pellentesque.',
    //   href: '#',
    //   icon: SquaresPlusIcon,
    //   current: false,
    // },
    // {
    //   name: 'MLC - Claiming',
    //   description: 'Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.',
    //   href: '#',
    //   icon: MagnifyingGlassCircleIcon,
    //   current: false,
    // },
    // {
    //   name: 'HFAMLC - Duplicates sent to be',
    //   description: 'Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.',
    //   href: '#',
    //   icon: MagnifyingGlassCircleIcon,
    //   current: false,
    // },
    // {
    //   name: 'HFAMLC - Resolved Disputes',
    //   description: 'Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.',
    //   href: '#',
    //   icon: MagnifyingGlassCircleIcon,
    //   current: false,
    // }
  ]

  const mechSubNavigation = [
    {
      name: t('Performance Summary'),
      description: '',
      icon: CogIcon,
    },
    {
      name: t('YouTube Manual Claims'),
      description: '',
      icon: KeyIcon,
    },
    {
      name: t('Mechanicals Manual Claims'),
      description: '',
      icon: SquaresPlusIcon,
    }
  ]

  let query = useQuery();
  const authStore = useContext(AuthContext);
  const user = authStore.user;
  const startMonth = query.get('startMonth');
  const startYear = query.get('startYear');
  const endMonth = query.get('endMonth');
  const endYear = query.get('endYear');
  const startMonthName = getMonthName(startMonth);
  const endMonthName = getMonthName(endMonth);
  const [activeTab, setActiveTab] = useState(query.get('reportTab') || 'Performance Summary');
  let clientServices = client.services.map(service => service.service.toLowerCase());
  let mechServices = ['mri', 'cmrra', 'the mlc']
  let isMech = clientServices.some(s => mechServices.includes(s));
  let navigationMenu = isMech ? mechSubNavigation : subNavigation;

  return (<>
    <div className="flex h-full">
      <div className="flex flex-1 overflow-hidden">
        <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
          <Banner client={client} />
          <div className="flex flex-1 xl:overflow-hidden">
            {/* Secondary sidebar */}
            <nav
              aria-label="Sections"
              className="hidden w-96 flex-shrink-0 border-r border-blue-gray-200 bg-white xl:flex xl:flex-col"
            >
              <div className="flex min-h-16 flex-shrink-0 items-center border-b border-blue-gray-200 px-6 py-4">
                <Link
                  type="button"
                  to={`/clients/${client?.uid}/`}
                  className="inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-3 mr-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <ArrowUturnLeftIcon className="h-4 w-4" aria-hidden="true" />
                </Link>
                <p className="text-lg font-medium text-blue-gray-900">{t('Monthly Report for')}<br /> {startMonthName} {startYear}</p>
                {/* {(startMonth === endMonth && startYear === endYear)
                  ? (<p className="text-lg font-medium text-blue-gray-900">{t('Monthly Report for')}<br /> {startMonthName} {startYear}</p>)
                  : (<p className="text-lg font-medium text-blue-gray-900">Report from<br /> {startMonthName} {startYear} to {endMonthName} {endYear}</p>)
                } */}
              </div>
              <div className="min-h-0 flex-1 overflow-y-auto">
                {navigationMenu.map((item) => (
                  <span
                    key={item.name}
                    className={classNames(
                      activeTab === item.name ? 'bg-blue-100 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50',
                      'flex p-6 border-b border-blue-gray-200 cursor-pointer'
                    )}
                    aria-current={activeTab === item.name ? 'page' : undefined}
                    onClick={() => {setActiveTab(item.name)}}
                  >
                    {/* <item.icon className="-mt-0.5 h-6 w-6 flex-shrink-0 text-blue-gray-400" aria-hidden="true" /> */}
                    <div className="ml-3 text-sm">
                      <p className="font-medium text-gray-900">{item.name}</p>
                      <p className="mt-1 text-gray-500">{item.description}</p>
                    </div>
                  </span>
                ))}
                {user?.role === 'admin' && <span className='flex p-6'>
                  <div className="ml-3 text-sm">
                    <DownloadExport
                      uid={client?.uid}
                      name={client?.name}
                      isMech={isMech}
                      startYear={startYear}
                      startMonth={startMonth}
                      startMonthName={startMonthName}
                    />
                  </div>
                </span>}
              </div>
            </nav>

            {/* Main content */}
            <div className="flex-1 xl:overflow-y-auto">
              <div className="w-full py-10 px-4 sm:px-6 lg:py-12 lg:px-12">
                {activeTab === 'Performance Summary' &&
                  <PerformanceSummary
                    uid={client?.uid}
                    name={client?.name}
                    startMonth={startMonth}
                    startYear={startYear}
                    endMonth={endMonth}
                    endYear={endYear}
                  />
                }

                {activeTab === 'YouTube Manual Claims' &&
                  <YTManualClaims
                    client={client}
                    startMonth={startMonth}
                    startYear={startYear}
                    endMonth={endMonth}
                    endYear={endYear}
                  />
                }

                {activeTab === 'Mechanicals Manual Claims' &&
                  <MechManualClaims
                    client={client}
                    startMonth={startMonth}
                    startYear={startYear}
                    endMonth={endMonth}
                    endYear={endYear}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default NonCMSMonthlyReport;
