import { TextInput } from "components/Form/TextInput"
import { Modal } from "components/Modal"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { editUser } from "api/expauth"
import Spinner from "components/Icons/Spinner"
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/24/outline"
import { useTranslation } from 'react-i18next'

export function ChangePasswordButton({ user }) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm()

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true)
    editUser(user.id, { ...data, email: user.email })
      .then(() => {
        setMessage(
          <div className="flex justify-center space-x-2">
            <CheckCircleIcon className="w-6 text-green-500" />{" "}
            <p>Successfully changed password!</p>
          </div>
        )
      })
      .catch((res) => console.log(res.response))
      .finally(() => setIsLoading(false))
  })

  const onClose = () => {
    setIsOpen(false)
    setMessage("")
    reset({ password: "" })
  }

  const generatePassword = () => {
    const randomPassword =
      Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
    setValue("password", randomPassword, { shouldDirty: true })
  }

  return (
    <div>
      <button
        className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
        onClick={() => setIsOpen(true)}
      >
        {t('Change Password')}
      </button>
      <Modal
        isOpen={isOpen}
        closeModal={() => onClose()}
        dialogTitle="Change Password"
      >
        {!!message ? (
          <div className="text-center">{message}</div>
        ) : (
          <form onSubmit={onSubmit}>
            <TextInput
              label="New password"
              {...register("password", { required: true, minLength: 6 })}
            />
            <p
              className="mt-1 text-xs hover:text-blue-400 cursor-pointer"
              onClick={() => generatePassword()}
            >
              {t('Click here to generate random password')}
            </p>
            {errors?.password?.type === "required" ? (
              <p className="text-red-500 text-sm">{t('Please enter a password')}</p>
            ) : null}
            {errors?.password?.type === "minLength" ? (
              <p className="text-red-500 text-sm">{t('Minimum of 6 characters')}</p>
            ) : null}
            <div className="flex justify-center space-x-4">
              <button
                disabled={isLoading}
                type="submit"
                className="w-48 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                {isLoading ? <Spinner /> : "Save"}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  )
}
