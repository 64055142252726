import React from 'react';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'

const Banner = () => {
  return (<>
    <div className="bg-yellow-400">
      <div className="w-full py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center">
          <div className="flex w-0 flex-1 items-center justify-center">
            <p className="ml-4 truncate text-gray-900">
              <span className="inline">This is version 1 of this Portal, more data may be added that isn't currently displayed.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default Banner;
