import React, { useEffect, useState, useContext } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { AuthContext } from 'stores/auth';
import { getClient, getClientStats, getClientContract, clientLogoUpload } from 'api/core';
import LoadingNotification from 'components/LoadingNotification';

import Dashboard from './pages/Dashboard';
import UnderConstruction from 'routes/static/UnderConstruction';
import ClientAssets from './pages/ClientAssets';
import ClientReports from './pages/ClientReports';
import ClientInformation from './pages/ClientInformation';
import BankFormUS from '../Clients/BankInformation/BankFormUS';
import ClientAssetDetail from './pages/ClientAssetDetail';
import RoyaltySummary from './pages/RoyaltySummary';
import MonthlyReport from './pages/MonthlyReport';
import YearOverYear from './pages/YearOverYear';

import Settings from './pages/Settings';

const ClientDetailRoutes = ({ clientUid }) => {
  const { path } = useRouteMatch()
  const basePath = path.replace(/\/+$/, '')

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Fetching client information...');
  const [client, setClient] = useState();
  const [stats, setStats] = useState();
  const [contract, setContract] = useState();
  const [clientSource, setClientSource] = useState(localStorage.getItem("clientSource"));

  useEffect(() => {
    fetchClient();
    fetchStats(null);
    fetchContract();
  }, [clientUid]);

  useEffect(() => {
    if (client?.client_status === 'invoice_royalty') {
      const stat = localStorage.getItem("clientSource");
      if (stat !== 'royalty' && stat !== 'invoice') {
        localStorage.setItem("clientSource", "royalty");
      }
    } else {
      localStorage.setItem("clientSource", client?.client_status);
    }
  }, [client]);

  useEffect(() => {
    fetchStats();
  }, [clientSource]);

  const fetchClient = () => {
    setLoading(true);
    setClient(null);
    getClient(clientUid).then(res => {
      setClient(res.data?.data);
      localStorage.setItem("clientSource", res.data?.data?.client_status);
      setClientSource(res.data?.data?.client_status);
      setLoading(false);
    }).catch(_err => {
      setLoading(false);
    });
  };

  const fetchStats = () => {
    // setStats(null);
    getClientStats(clientUid, clientSource).then(res => {
      setStats(res.data);
    }).catch(err => {
      console.log(err);
    });
  };

  const fetchContract = () => {
    setContract(null);
    getClientContract(clientUid).then(res => {
      setContract(res.data?.data);
    }).catch(err => {
      console.log(err);
    });
  };

  const uploadClientLogo = (e) => {
    let payload = { logo: e.target.files[0] }
    setLoading(true);
    setLoadingText('Uploading logo...');
    clientLogoUpload(client.uid, payload).then(res => {
      if (res.status === 200) {
        fetchClient();
        setLoadingText('Successfully uploaded logo!');
        setTimeout(() => {
          setLoading(false)
       }, 3000);
      }
    }).catch(err => {
      setLoadingText('Error uploading logo. Please try again.');
      console.log(err);
      setLoading(false);
    })
  };

  const toggleClientSource = (source) => {
    setClientSource(source);
    localStorage.setItem('clientSource', source);
    fetchStats();
  };

  return (<div className="h-full w-full">
    <main className="h-full w-full">
      <LoadingNotification loadingText={loadingText} loading={loading} />
      <div className="h-full w-full mx-auto">
        <Switch>
          <Route exact path={`${basePath}/`}>
            <Dashboard
              loading={loading}
              client={client}
              stats={stats}
              contract={contract}
              uploadClientLogo={uploadClientLogo}
              clientSource={clientSource}
              toggleClientSource={toggleClientSource}
            />
          </Route>

          <Route exact path={`${basePath}/settings`}>
            <Settings
              loading={loading}
              client={client}
              stats={stats}
              contract={contract}
              uploadClientLogo={uploadClientLogo}
              clientSource={clientSource}
              toggleClientSource={toggleClientSource}
            />
          </Route>

          <Route exact path={`${basePath}/info`}>
            <ClientInformation contract={contract} />
          </Route>

          <Route exact path={`${basePath}/info/bank-form-us`}>
            <BankFormUS />
          </Route>

          <Route exact path={`${basePath}/assets`}>
            <ClientAssets client={client} />
          </Route>

          <Route exact path={`${basePath}/assets/:title`}>
            <ClientAssetDetail client={client} />
          </Route>

          <Route exact path={`${basePath}/reports`}>
            <ClientReports client={client} />
          </Route>

          <Route exact path={`${basePath}/royalty-summary`}>
            <RoyaltySummary
              loading={loading}
              client={client}
              stats={stats}
              contract={contract}
            />
          </Route>

          <Route exact path={`${basePath}/monthly-report`}>
            <MonthlyReport
              loading={loading}
              client={client}
              stats={stats}
              contract={contract}
            />
          </Route>

          <Route exact path={`${basePath}/year-over-year`}>
            <YearOverYear
              loading={loading}
              client={client}
              contract={contract}
            />
          </Route>

          <Route exact path={`${basePath}/*`} render={() => <UnderConstruction />} />
        </Switch>
      </div>
    </main>
  </div>);
}

export default ClientDetailRoutes;
