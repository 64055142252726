import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import BackgroundTransition from './components/BackgroundTransition';
import SelectPalette from './components/SelectPalette';

const ClientSelect = ({ isOpen, setIsOpen, dialogTitle, ctaAction }) => {
  function closeModal() {
    setIsOpen(false)
  }

  const handleCTAAction = (clientId) => {
    ctaAction(clientId);
    closeModal();
  }

  return (<>
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <BackgroundTransition />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="w-full flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-1/3 max-w-1/2 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="">
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {dialogTitle}
                    </Dialog.Title>
                  </div>
                  <SelectPalette
                    ctaText={'Choose Client'}
                    ctaAction={handleCTAAction}
                  />
                  <div className="flex flex-wrap items-right bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
                    <span
                      className="cursor-pointer"
                      onClick={() => closeModal()}>Close</span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  </>)
}

export default ClientSelect;
