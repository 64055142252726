import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon, Bars2Icon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import NumberFormat from 'react-number-format';
import urlJoin from 'url-join';

import { clientYearOverYear, exportYearOverYear } from 'api/core';
import { useTranslation } from 'react-i18next';
import { classNames } from 'components/utils';

const IGNORE_KEYS = ['Exploration Share', 'Total Gross', 'Total To Client']

const Row = ({ reports, report }) => {
  const { t } = useTranslation();
  const prev = reports?.prev?.find(({ report__name }) => report__name === report.report__name);
  const increase = (prev?.gross_revenue || 0) < report.gross_revenue;
  const equal = (prev?.gross_revenue || 0) === report.gross_revenue;

  return (<li key={report.report__name}>
    <div href="#" className="block hover:bg-gray-50">
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="truncate text-sm font-medium text-indigo-600">{t(report.report__name)}</p>
          {/* <div className="ml-2 flex flex-shrink-0">
            <p className={classNames("inline-flex rounded-full px-2 text-xs font-semibold leading-5",
              increase
                ? "bg-green-100 text-green-800"
                : equal
                  ? "bg-gray-100 text-gray-800"
                  : "bg-red-100 text-red-800")
            }>
              {increase
                ? <ArrowTrendingUpIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                : equal
                  ? <Bars2Icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                  : <ArrowTrendingDownIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              }
            </p>
          </div> */}
        </div>
        <div className="mt-1 sm:flex sm:justify-between">
          <div className="mt-1 flex items-center text-sm text-gray-500 sm:mt-0">
            <div>
              From <NumberFormat
                value={prev?.gross_revenue?.toFixed(2) || 0.000}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                className="font-bold"
              />
              &nbsp; to <NumberFormat
                value={report.gross_revenue?.toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                className="font-bold"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>)
}

const YearOverYear = ({ client }) => {
  const { t } = useTranslation();
  let location = useLocation();
  const basePath = location.pathname;
  const tempDate = new Date();
  tempDate.setMonth(tempDate.getMonth() - 3);
  const year = tempDate.getFullYear();
  const [prevYear, setPrevYear] = useState(year - 1);
  const [loading, setLoading] = useState(true);

  const [reports, setReports] = useState({
    'current': [],
    'prev': [],
    'top5': [],
    'bottom5': []
  });

  useEffect(() => {
    if (client?.uid) {
      setLoading(true);
      clientYearOverYear(client.uid, prevYear, year).then(res => {
        const upArray = res.data.current_year?.filter((report) => {
          const prev = res.data.prev_year?.find(({ report__name }) => report__name === report.report__name);
          return (prev?.gross_revenue || 0) < report.gross_revenue;
        });
        const bottomArray = res.data.current_year?.filter((report) => {
          const prev = res.data.prev_year?.find(({ report__name }) => report__name === report.report__name);
          return (prev?.gross_revenue || 0) > report.gross_revenue;
        });
        setReports({
          'current': res.data.current_year,
          'prev': res.data.prev_year,
          'top5': upArray.sort((a,b) => b.gross_revenue - a.gross_revenue),
          'bottom5': bottomArray.sort((a,b) => b.gross_revenue - a.gross_revenue),
        });
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
    }
  }, [client]);

  const getExport = (uid, year) => {
    exportYearOverYear(uid, year - 1, year).then((res => {
    })).catch(err => {
      console.log(err)
    })
  };

  return (<>
    <section aria-labelledby="year-over-year">
      <div className="rounded-lg bg-white overflow-hidden shadow mb-20">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2 w-full flex justify-between">
              <div>
                <h3 className="text-xl leading-6 font-medium text-gray-800">{t('Year Over Year')}</h3>
                <span className="text-sm leading-6 font-medium text-gray-500">{prevYear} vs {year}</span>
              </div>
              <div>
                <span
                  type="button"
                  onClick={() => getExport(client?.uid, year)}
                  className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 cursor-pointer hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <ArrowDownTrayIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                  {t('Download CSV')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="py-0 px-0 align-middle inline-block min-w-full">
          <ul className="divide-y divide-gray-200">
            {loading && (<li>
              <div className="block hover:bg-gray-50 animate-pulse">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className="rounded-full bg-gray-200 w-44 h-5" />
                    <div className="ml-2">
                      <div className="rounded-full bg-gray-200 w-9 h-5" />
                    </div>
                  </div>
                  <div className="mt-1 sm:flex sm:justify-between">
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <div className="rounded-full bg-gray-200 w-24 h-4" />
                    </div>
                  </div>
                </div>
              </div>
            </li>)}
            {!loading && reports?.top5?.slice(0, 3).map((report) => {
              return IGNORE_KEYS.indexOf(report.report__name) < 0 && (
                <Row
                  reports={reports}
                  report={report}
                  key={report.report__name}
                />
              )
            })}
            {!loading && reports?.bottom5?.slice(0, 3).map((report) => {
              return IGNORE_KEYS.indexOf(report.report__name) < 0 && (
                <Row
                  reports={reports}
                  report={report}
                  key={report.report__name}
                />
              )
            })}
          </ul>

          <div className="px-4 py-4 sm:px-6">
            <Link
              to={urlJoin(basePath, `year-over-year?year=${year}`)}
              className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              {t('View all')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  </>)
}

export default YearOverYear;
