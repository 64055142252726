import React from 'react';
import { Link } from 'react-router-dom';

const ContractInfo = () => {
  return (<>
    <div className="w-full mx-auto">
      <div className="md:flex md:items-center md:justify-between my-5">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 font-medium sm:text-3xl sm:truncate inline-block">Contract for</h2>
          <h2 className="text-2xl font-bold leading-7 text-blue-900 sm:text-3xl sm:truncate inline-block px-2">1 World Music Publishing LLC</h2>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 text-gray-900 font-medium">Primary Information</h3>
        </div>
        <div className="px-4 py-3 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Payee ID</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">001</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Payee Entity</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">1 World Music Publishing</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">QBO</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium"></dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Active </span>
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Client Code</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">1 World</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Custom Identifier</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">1world</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Contract Entity</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">1 World Music Publishing LLC</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Alternate Entity</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Jencarlos Canela</dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm text-gray-500">Contract Address</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">7270 NW 35th Terrace, Miami, FL 33122 USA</dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm text-gray-500">Content</dt>
              <div className="space-x-2">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Publishing </span>
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Sound Recordings </span>
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Music Videos </span>
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-300"> Film </span>
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-300"> TV </span>
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-300"> Channel Management </span>
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-300"> Broker </span>
              </div>
            </div>
          </dl>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 text-gray-900 font-medium">Contract Details</h3>
        </div>
        <div className="px-4 py-3 sm:px-6 ">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Fea Delivered</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">2014-08-25</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Start Date</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">2014-08-25</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Renewal Date</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">2019-08-25</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Termination Date</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium"> 2019-09-04 </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Amendment</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">false</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Term</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">1 year, monthly auto renewal, 6 month notice for termination</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm text-gray-500">Royalties Address</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">7270 NW 35th Terrace, Miami, FL 33122 USA</dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-3 sm:px-6 border-t border-gray-200">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">RATE - Youtube</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">20</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Payment Type - Youtube</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Check - Royalties Out</dd>
            </div>
            <div className="sm:col-span-1">
            </div>
            <div className="sm:col-span-1">
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">RATE - HFA</dt>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Payment Type - HFA</dt>
            </div>
            <div className="sm:col-span-1">
            </div>
            <div className="sm:col-span-1">
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">RATE - MRI</dt>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Payment Type - MRI</dt>
            </div>
            <div className="sm:col-span-1">
            </div>
            <div className="sm:col-span-1">
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">RATE - CMRRA</dt>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Payment Type - CMRRA</dt>
            </div>
            <div className="sm:col-span-1">
            </div>
            <div className="sm:col-span-1">
            </div>
          </dl>
        </div>
        <div className="px-4 py-3 sm:px-6 border-t border-gray-200">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">W-9 Received</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Yes</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">W-9 Sent</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Yes</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Bank Info Received</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Yes</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Bank Info Sent</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Yes</dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 text-gray-900 font-medium">Contacts</h3>
        </div>
        <div className="px-4 py-3 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Lissette Canela</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Lcanela@tradersofmiami.com</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium"></dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm text-gray-500">Contact Type</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">Contract</dd>
            </div>
          </dl>
      </div>
    </div>

    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 text-gray-900 font-medium">Entities</h3>
        </div>
        <div className="px-4 py-3 sm:px-6 border-b">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="col-span-1">
              <dt className="text-sm text-gray-500">Entity</dt>
            </div>
            <div className="col-span-1">
              <dt className="text-sm text-gray-500">Entity Type</dt>
            </div>
            <div className="col-span-1">
              <dt className="text-sm text-gray-500">Entity IPI</dt>
            </div>
            <div className="col-span-1">
              <dt className="text-sm text-gray-500">Entity PRO</dt>
            </div>
          </dl>
      </div>
    </div>

    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 text-gray-900 font-medium">Mapping Aliases</h3>
        </div>
    </div>

    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className="border-t border-gray-200" />
      </div>
    </div>

    <form action="#" method="POST"><div className="flex justify-end mt-5">
      <Link to="" type="button" className="button mr-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Delete</Link>
      <Link to="/clients/contracts/form" type="button" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Request Update</Link>
    </div>
    </form>
  </div>


  </>);
}

export default ContractInfo;
