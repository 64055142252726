import React from 'react';
import { useObserver } from 'mobx-react';

import Header from './components/Header';
import Overview from './components/Overview';
import OverviewChart from './components/OverviewChart';
import TopClients from './components/TopClients';
import ReportTypeTable from './components/ReportTypeTable';

const Dashboard = () => {

  return useObserver(() => {
    return (<div className="flex-1">
      <Header />
      <div className="py-5 grid grid-col-3">
        <div className="col-span-3">
          <Overview />
        </div>
      </div>
      {/* <div className="px-7 py-5">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
          <div className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <OverviewChart />
          </div>
          <div className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <TopClients />
          </div>
        </div>
        <ReportTypeTable />
      </div> */}
    </div>)
  });
}

export default Dashboard
