import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { classNames } from 'components/utils';
import { getClientMonthlyRoyalty, getClientContract } from 'api/core';
import Spinner from 'components/Icons/Spinner';
import TableRow from './TableRow';

const headerClass = "py-3.5 pl-4 pr-3 text-sm font-bold text-gray-600 sm:pl-6 md:pl-0 uppercase";

const Table = ({ client, stats, month, year }) => {
  const [loading, setLoading] = useState(true);
  const [currentStats, setCurrentStats] = useState({
    grossRevenue: 0,
    explorationShare: 0,
    reports: 0
  });
  const [reports, setReports] = useState([]);
  const [rates, setRates] = useState([]);

  useEffect(() => {
    if (client?.uid) {
      fetchContract();
    }
  }, [client]);

  useEffect(() => {
    if (client?.uid) {
      fetchMonthlyRoyalty();
    }
  }, [month, year, client]);

  const fetchMonthlyRoyalty = () => {
    setLoading(true);
    getClientMonthlyRoyalty(client?.uid, month, year).then((res) => {
      if (res.data?.status === 'ok') {
        setCurrentStats({
          grossRevenue: res.data.net_revenue,
          explorationShare: res.data.exploration_share,
          reports: res.data.reports
        });
        setReports(res.data.summaries);
      } else if (res.data?.status === 'no-assets') {
        setCurrentStats({
          grossRevenue: 0,
          explorationShare: 0,
          reports: 0
        });
        setReports([]);
      }

      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  }

  const fetchContract = () => {
    setRates([]);
    getClientContract(client?.uid).then(res => {
      setRates(res.data?.data?.original_rates);
    }).catch(err => {
      console.log(err);
    });
  };

  return (<>
    <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className={classNames(headerClass, 'text-left')}>
              Description
            </th>
            <th scope="col" className={classNames(headerClass, 'text-right')}>
              Gross Amount
            </th>
            <th scope="col" className={classNames(headerClass, 'text-right')}>
              Rate
            </th>
            <th scope="col" className={classNames(headerClass, 'text-right')}>
              Exploration Fee
            </th>
            <th scope="col" className={classNames(headerClass, 'text-right')}>
              Net Amount
            </th>
          </tr>
        </thead>

        <tbody>
          {loading && (
            <tr className="border-b border-gray-200">
              <td colSpan={5} className="py-4 pl-4 pr-3 text-md sm:pl-6 md:pl-0">
                <div className="flex justify-center items-center">
                  <Spinner /><span className="ml-2">Loading data...</span>
                </div>
              </td>
            </tr>
          )}
          {!loading && reports?.map((row) => {
            return row.report?.is_youtube && (
              <TableRow data={row} rates={rates} key={row.id} />
            )
          })}
          {!loading && reports?.map((row) => {
            return !row.report?.is_youtube && (
              <TableRow data={row} rates={rates} key={row.id} />
            )
          })}
        </tbody>
      </table>

      <table className="min-w-full divide-y divide-gray-300">
        <tfoot>
          <tr>
            <th
              scope="row"
              colSpan={5}
              className="hidden pl-6 pr-3 pt-6 text-right text-md font-normal text-gray-500 sm:table-cell md:pl-0"
            >
              &nbsp;
            </th>
          </tr>
          <tr>
            <th
              scope="row"
              colSpan={4}
              className="pl-6 pr-3 pt-6 text-right text-md font-normal text-gray-500 sm:table-cell md:pl-0"
            >
              Subtotal
            </th>
            <td className="px-3 pt-6 text-right text-md text-gray-500">
              <NumberFormat
                value={currentStats.grossRevenue.toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              colSpan={4}
              className="pl-6 pr-3 pt-4 text-right text-md font-normal text-gray-500 sm:table-cell md:pl-0"
            >
              Total Exploration Fee
            </th>
            <td className="px-3 pt-6 text-right text-md text-red-500">
              <NumberFormat
                value={currentStats.explorationShare.toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'($'}
                suffix={')'}
              />
            </td>
          </tr>
          <tr>
            <th
              scope="row"
              colSpan={4}
              className="pl-6 pr-3 pt-4 text-right text-xl font-semibold text-gray-900 sm:table-cell md:pl-0"
            >
              Royalty to Client
            </th>
            <td className="px-3 pt-6 text-right text-xl font-semibold text-green-700">
              <NumberFormat
                value={(currentStats.grossRevenue - currentStats.explorationShare).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </>);
}

export default Table;
