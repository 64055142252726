import { PencilIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'

const GeneralInfo = ({ member }) => {

  const { t } = useTranslation();
  const [allowEdit, setAllowEdit] = useState(false)
  const { register, handleSubmit } = useForm()

  const onSubmit = handleSubmit((data)=>{
    console.log(data)
  })

  return (
    <section aria-labelledby="member-information-title" onBlur={()=>setAllowEdit(false)}>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900 flex space-x-2">
            <span>Member Information</span> <PencilIcon className="w-4 cursor-pointer hover:text-blue-500" onClick={()=>setAllowEdit(!allowEdit)}/>
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Details</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <form onSubmit={onSubmit}>
            <fieldset disabled={!allowEdit}>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">{member?.user_detail?.email}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Role</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <select
                      {...register('role', { required: true })}
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="1">Owner</option>
                      <option value="2">Admin</option>
                      <option value="3">{t('Users')}</option>
                    </select>
                  </dd>
                </div>
              </dl>
              {allowEdit && <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-75 disabled:cursor-default disabled:bg-gray-600"
              >Update</button>}
            </fieldset>
          </form>
        </div>
      </div>
    </section>
  )
}

export default GeneralInfo