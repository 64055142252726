import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { classNames } from 'components/utils';

const ReportsTable = ({ loading, reports, page, setPage, pageInfo }) => {
  // const handleNext = () => {
  //   if (pageInfo?.hasNext) {
  //     setPage(page + 1);
  //   }
  // };

  // const handlePrevious = () => {
  //   if (pageInfo?.hasPrev) {
  //     setPage(page - 1);
  //   }
  // };

  return (<>
    <div className="flex flex-col">
      <div className="-my-2 overflow-y-visible sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="-mx-4 mt-10 sm:mx-0 rounded-lg bg-white shadow">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Date
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Asset Count
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Download</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && reports?.length === 0 && (<tr>
                  <td className="bg-white py-3 px-6" colSpan={3}>
                    <div className="flex">
                      No reports to display.
                    </div>
                  </td>
                </tr>)
                }
                {reports.map((report, reportIdx) => (
                  <tr key={report.id}>
                    <td
                      className={classNames(
                        reportIdx === 0 ? '' : 'border-t border-transparent',
                        'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                      )}
                    >
                      <div className="font-medium text-gray-900">
                        {report.date}
                      </div>
                      {reportIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" /> : null}
                    </td>
                    <td
                      className={classNames(
                        reportIdx === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      {report.asset_count}
                    </td>
                    <td
                      className={classNames(
                        reportIdx === 0 ? '' : 'border-t border-transparent',
                        'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
                      )}
                    >
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 cursor-pointer hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Download
                            <ChevronDownIcon className="-mr-1 h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href={report.pub_file}
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    Publishing
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href={report.sr_file}
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                  >
                                    Sound Recording
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      {reportIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" /> : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Page <span className="font-medium">{page}</span>&nbsp;of&nbsp;
                  <span className="font-medium">{pageInfo?.pages}</span>
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <button type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  onClick={() => handlePrevious()}
                >
                  Previous
                </button>
                <button type="button"
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  onClick={() => handleNext()}
                >
                  Next
                </button>
              </div>
            </nav> */}
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default ReportsTable;
