import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthContext } from 'stores/auth';

const ClientInformationManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <div className="flex-1">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Client Information Manual</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
            </div>
            <div className="mt-5 border-t border-gray-200">
              <dl className="divide-y divide-gray-200">

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View Transaction History</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the sidebar, click the Client Information button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-1.png" />
                    <span className="flex-grow col-span-4">2. The Transactions History section is displayed on the top left section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-2.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View Bank Info</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the sidebar, click the Client Information button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-1.png" />
                    <span className="flex-grow col-span-4">2. The Bank Information section is displayed on the top right section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-3.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Request Bank Info Update</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the Bank Information section, click the Request Update button located on the bottom right. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-4.png" />
                    <span className="flex-grow col-span-4">2. Fill out all necessary information you want to update. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-5.png" />
                    <span className="flex-grow col-span-4">3. On the bottom right of the page, click Request Update. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-6.png" />
                    <span className="flex-grow col-span-4">4. You have successfully sent a request to update your Bank Information. </span>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">US Bank Info</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the Bank Information section, click the Request Update button located on the bottom right. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-3.png" />
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-4.png" />
                    <span className="flex-grow col-span-4">2. Click the Switch to US Form button on the upper right side of the page. If the button is labeled as "Switch to International Form", then you are already in the US Bank Form page. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-7.png" />
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-8.png" />
                  </dd>
                </div>


                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">W-9 Info</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the US Bank Information section, locate W-9 section at the bottom of the form. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-8.png" />
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-9.png" />
                    <span className="flex-grow col-span-4">2.1. If you have an accomplished W-9 form file, upload the file by clicking the Choose File button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-10.png" />
                    <span className="flex-grow col-span-4">2.2. Otherwise, click the Update W-9 Details button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-11.png" />
                    <span className="flex-grow col-span-4">3. Fill out all necessary information in the W-9 Forms page. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-12.png" />
                    <span className="flex-grow col-span-4">4. Click the Request Update button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-6.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">International Bank Info</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the Bank Information section, click the Request Update button located on the bottom right. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-3.png" />
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-4.png" />
                    <span className="flex-grow col-span-4">2. Click the Switch to International Form button on the upper right side of the page. If the button is labeled as "Switch to US Form", then you are already in the International Bank Form page. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-13.png" />
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-14.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">W-8BEN Info</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the International Bank Information section, locate W-8 section at the bottom of the form. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-15.png" />
                    <span className="flex-grow col-span-4">2.1. If you have an accomplished W-8BEN form file, upload the file by clicking the Choose File button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-16.png" />
                    <span className="flex-grow col-span-4">2.2. Otherwise, click the Update W-8 Details button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-17.png" />
                    <span className="flex-grow col-span-4">3. Fill out all necessary information in the W-8BEN Forms page. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-18.png" />
                    <span className="flex-grow col-span-4">4. Click the Request Update button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-6.png" />
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">W-8BEN-E Info</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the International Bank Information section, locate W-8 section at the bottom of the form. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-15.png" />
                    <span className="flex-grow col-span-4">2.1. If you have an accomplished W-8BEN-E form file, upload the file by clicking the Choose File button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-16.png" />
                    <span className="flex-grow col-span-4">2.2. Otherwise, click the Update W-8 Details button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-17.png" />
                    <span className="flex-grow col-span-4">3. On the upper right side of the page, click the Switch to W-8BEN-E Form (Entities) button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-20.png" />
                    <span className="flex-grow col-span-4">4. Fill out all necessary information in the W-8BEN-E Forms page. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-19.png" />
                    <span className="flex-grow col-span-4">5. Click the Request Update button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/ClientInformation/clientinfo-6.png" />
                  </dd>
                </div>

              </dl>
            </div>
            <div className="py-4 sm:py-5 sm:gap-4 sm:text-right">
              <Link to="/help" className="text-m font-medium text-blue-800">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>)
  });
}

export default ClientInformationManual
