import React, { useState } from 'react';
import ReportsTable from './ReportsTable';
import CategoriesTable from './CategoriesTable';
import ReportsForm from './ReportsForm';
import CategoriesForm from './CategoriesForm';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const ReportsTab = () => {
  const [showReportForm, setShowReportForm] = useState(false);
  const [showCategoryForm, setShowCategoryForm] = useState(false);

  return (<div className="px-4 sm:px-6 lg:px-8 py-5">
    {!showReportForm && !showCategoryForm && (
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">Report Types</h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
              <button type="button" onClick={() => setShowReportForm(true)} className="button ml-3 inline-flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
              <PlusCircleIcon className='h-6 w-6' />
              <div className="ml-2">Add Report</div>
              </button>
          </div>
        </div>)
    }
    {showReportForm && (<ReportsForm setShowForm={setShowReportForm} />)}
    {!showReportForm && !showCategoryForm && (<ReportsTable />)}

    {!showReportForm && !showCategoryForm && (
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">Report Categories</h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
              <button type="button" onClick={() => setShowCategoryForm(true)} className="button ml-3 inline-flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
              <PlusCircleIcon className='h-6 w-6' />
              <div className="ml-2">Add Report Category</div>
              </button>
          </div>
        </div>)
    }
    {showCategoryForm && (<CategoriesForm setShowForm={setShowCategoryForm} />)}
    {!showReportForm && !showCategoryForm && (<CategoriesTable />)}
  </div>);
}

export default ReportsTab;
