import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import Spinner from 'components/Icons/Spinner';
import { getClientAssets } from 'api/assets';

const AssetsTable = ({ uid, client }) => {
  const pages = [
    { name: 'Assets', href: `/clients/${client.uid}/assets/`, current: true },
  ];

  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  // const [filter, setFilter] = useState('Last year');
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    'hasNext': false,
    'hasPrev': false,
    'total': 1,
    'pages': 1
  });

  useEffect(() => {
    setLoading(true);
    getClientAssets(page, uid).then(res => {
      setAssets(res.data?.data)
      setPageInfo({
        'hasNext': res.data?.has_next,
        'hasPrev': res.data?.has_previous,
        'total': res.data?.total,
        'pages': res.data?.pages
      });
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }, [page, uid]);

  const handleNext = () => {
    if (pageInfo?.hasNext) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (pageInfo?.hasPrev) {
      setPage(page - 1);
    }
  };

  return (<section aria-labelledby="client-overview-title">
    <div
      className="rounded-lg bg-white overflow-hidden shadow"
      // style={{ 'minHeight': '300px' }}
    >
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link to={`/clients/${client.uid}/`} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                      <span>{client.name}</span>
                    </Link>
                  </div>
                </li>
                {pages.map((page) => (
                  <li key={page.name}>
                    <div className="flex items-center">
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <Link
                        to={page.href}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current={page.current ? 'page' : undefined}
                      >
                        {page.name}
                      </Link>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
            <h3 className="mt-3 text-xl leading-6 font-medium text-gray-900">Assets</h3>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Asset IDs
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Custom ID
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Partner Revenue
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (<tr>
                    <td className="bg-white py-3 px-3" colSpan={4}>
                      <div className="flex items-center justify-center">
                        <Spinner />&nbsp;Loading...
                      </div>
                    </td>
                  </tr>)}
                  {!loading && assets?.map((asset, idx) => (
                    <tr key={asset.asset_id + '-' + idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                        <Link to={asset.asset_title} className="flex whitespace-nowrap text-sm font-medium text-gray-900 hover:text-blue-400">{asset.asset_title}</Link>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                        {asset?.asset_id && asset.asset_id.split(',').slice(0, 4).map(asset_id => (
                            <span key={asset_id} className="inline-flex items-center mr-2 px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                              {asset_id}
                            </span>)
                          )}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                        <p className={'inline-flex text-xs leading-5 font-semibold rounded-full'}>
                          {asset.custom_id}
                        </p>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                        {asset.partner_revenue_total.toFixed(5)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Page <span className="font-medium">{page}</span>&nbsp;of&nbsp;
                    <span className="font-medium">{pageInfo?.pages}</span>
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  <button type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => handlePrevious()}
                  >
                    Previous
                  </button>
                  <button type="button"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => handleNext()}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>);
}

export default AssetsTable;
