import { forwardRef } from "react"

export const TextInput = forwardRef(({ label, ...props }, ref) => {
  return (
    <div>
      <label
        htmlFor={props.name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <input
        ref={ref}
        type="text"
        className="block mt-1 w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
        {...props}
      />
    </div>
  )
})
