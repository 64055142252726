const listReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true
      };

    case "END_LOADING":
      return {
        ...state,
        loading: false
      };

    case "SET_PAGE":
      return {
        ...state,
        page: action.payload
      };

    case "SET_SEARCH":
      return {
        ...state,
        query: action.payload,
        page: 1
      };

    case "RESET_SEARCH":
      return {
        ...state,
        query: null,
        page: 1
      };

    case "RECEIVE_DATA":
      return {
        ...state,
        page: parseInt(action?.payload?.page),
        hasNext: action?.payload?.has_next,
        hasPrev: action?.payload?.has_previous,
        total: action?.payload?.total,
        pages: action?.payload?.pages,
        clients: action?.payload?.data || [],
        loading: false
      };


    case "ERROR":
      return {
        loading: false,
        error: true
      };

    default:
      return state;
  }
}

export default listReducer;
