export const CLIENT_OWNER = '1'
export const CLIENT_ADMIN = '2'
export const CLIENT_USER = '3'

export const CAN_VIEW_MEMBER = 'view_member'
export const CAN_VIEW_BANK_US = 'can_view_bank_us'
export const CAN_VIEW_BANK_INT = 'can_view_bank_int'
export const CAN_CREATE_BANK_US_CHANGE_REQUEST = 'can_create_bank_us_change_request'
export const CAN_CREATE_BANK_INT_CHANGE_REQUEST = 'can_create_bank_int_change_request'
export const CAN_ASSIGN_MEMBER_TO_CLIENT = 'can_assign_member_to_client'

export const memberPermissions = [
  CAN_CREATE_BANK_US_CHANGE_REQUEST,
]