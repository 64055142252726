import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

import { getReports, archiveReport } from 'api/core';
import ReportPalette from './ReportPalette';

const ReportsTable = ({ query, loading, setLoading }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [reports, setReports] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedReport, setSelectedReport] = useState()
  const [pageInfo, setPageInfo] = useState({
    'hasNext': false,
    'hasPrev': false,
    'total': 1,
    'pages': 1
  });

  const fetchData = useCallback(() => {
    setLoading(true);
    getReports(page, query).then(res => {
      setReports(res.data?.data);
      setPageInfo({
        'hasNext': res.data?.has_next,
        'hasPrev': res.data?.has_previous,
        'total': res.data?.total,
        'pages': res.data?.pages
      });
      setLoading(false);
    }).catch(err => {
      console.log('err');
      setLoading(false);
    });
  }, [page, query])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNext = () => {
    if (pageInfo?.hasNext) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (pageInfo?.hasPrev) {
      setPage(page - 1);
    }
  };

  const handleAlias = (reportId) => {
    const r = reports?.find(report => report.id === reportId);
    setSelectedReport(r);
    setOpenModal(true);
  };

  const handleArchive = (reportId) => {
    archiveReport(reportId).then(res => {
      console.log(res.data)
    }).catch(err => {

    })
  }

  const handlePageClick = (event) => {
    // const newOffset = (event.selected * 20) % state?.total;
    if (event?.selected) setPage(event.selected + 1);
  }

  return (<div className="mt-5 flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center w-44">
                  YouTube Report
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center w-44">
                  Rate Key
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center w-44">
                  Category
                </th>
                <th scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center w-44">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr className="bg-white">
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="rounded-full bg-gray-200 w-44 h-5" />
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-full bg-gray-200 w-5 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-sm bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="rounded-full bg-gray-200 w-12 h-5" />
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="rounded-full bg-gray-200 w-12 h-5" />
                  </td>
                </tr>
              )}
              {!loading && reports?.map((report, idx) => {
                return (<tr key={report.id} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <span className="cursor-pointer text-sm font-medium text-blue-800">
                      {report.name}
                    </span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      {report.is_youtube && <CheckBadgeIcon className='h-4 w-4 text-red-600' />}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex justify-center">
                      {report.is_youtube && (<span className="inline-flex items-center rounded bg-purple-100 px-2 py-0.5 text-xs font-medium text-purple-800">youtube</span>)}
                      {!report.is_youtube && report.rate_key && (<span className="inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800">{report.rate_key}</span>)}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    {report.categories?.map((category, cidx) => {
                        return (<span key={report.id + cidx} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                            </svg>
                            {category}
                        </span>)
                        })
                    }
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="flex gap-2">
                      <Link
                        to={`/admin/report-types/${report.id}/edit`}
                        className="py-2 px-4 rounded text-sm text-blue-600 font-medium cursor-pointer hover:bg-indigo-100 hover:text-indigo-900"
                      >
                        {t('Edit')}
                      </Link>
                      <span
                        onClick={() => handleAlias(report.id)}
                        className="py-2 px-4 rounded text-sm text-blue-600 font-medium cursor-pointer hover:bg-indigo-100 hover:text-indigo-900"
                      >
                        {t('Set as Alias')}
                      </span>
                      <span
                        onClick={() => handleArchive(report.id)}
                        className="py-2 px-4 rounded text-sm text-blue-600 font-medium cursor-pointer hover:bg-indigo-100 hover:text-indigo-900"
                      >
                        {t('Archive')}
                      </span>
                    </div>
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                {t('Page')} <span className="font-medium">{page}</span>&nbsp;of&nbsp;
                <span className="font-medium">{pageInfo?.pages}</span>
              </p>
            </div>
            {/* <div className="flex-1 flex justify-between sm:justify-end">
              <button type="button"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => handlePrevious()}
              >
                Previous
              </button>
              <button type="button"
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => handleNext()}
              >
                Next
              </button>
            </div> */}
            <div className="flex-1 flex justify-between sm:justify-end">
              {!loading && reports && (<div className="">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={4}
                  pageCount={pageInfo?.pages}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  forcePage={page - 1}

                  containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  pageLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  breakLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  previousLinkClassName="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  nextLinkClassName="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  activeLinkClassName="font-strong text-gray-900 bg-gray-100"
                  disabledLinkClassName=""
                />
              </div>)}
            </div>
          </nav>
        </div>
      </div>
    </div>
    {selectedReport && <ReportPalette
      open={openModal}
      setOpen={setOpenModal}
      alias={selectedReport}
    />}
  </div>);
}

export default ReportsTable;
