import React, { useRef, useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

import { useQuery } from 'utils';
import DownloadsTable from './components/DownloadsTable';
import Filters from './components/Filters';
import SearchBar from './components/SearchBar';
import { useTranslation } from 'react-i18next';

const MyDownloads = () => {
  const { t } = useTranslation();
  const componentIsMounted = useRef(true);
  let query = useQuery();
  const [loading, setLoading] = useState(false);
  const [searchQ, setSearchQ] = useState(query.get('q') || '');

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      <div className="max-w-full">
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">{t('Downloads')}</h2>
          </div>
        </div>
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <Filters />
          </div>
          <div className="mt-4 flex-1 flex md:mt-0 md:ml-4">
            <SearchBar
              loading={loading}
              setQuery={setSearchQ}
              query={searchQ}
            />
          </div>
        </div>
        <DownloadsTable
          query={searchQ}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  )
}

export default MyDownloads
