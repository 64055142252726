import { PaperClipIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { format, parseISO } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from 'stores/auth'
import classNames from 'styles/classNames'

const UpdateRequestInline = ({ updateRequest }) => {
  return (
    <Link to={{
      pathname: "/dashboard/info/bank-form-us",
      state: { bankUSData: updateRequest }
    }}>
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm hover:bg-gray-50">
        <div className="w-0 flex-1 flex items-center">
          <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
          <span className="ml-2 flex-1 w-0 truncate">{format(parseISO(updateRequest.created), "MMM d, yyyy")}</span>
        </div>
        <div className="ml-4 flex-shrink-0">
          <div className={classNames(
            "font-medium text-xs rounded text-white p-1 capitalize",
            updateRequest.status === "approved" ? "bg-green-600" : "",
            updateRequest.status === "pending" ? "bg-gray-600" : "",
            updateRequest.status === "rejected" ? "bg-red-600" : "",
          )}>
            {updateRequest.status}
          </div>
        </div>
      </li>
    </Link>
  )
}

const UpdateRequests = ({ data }) => {

  const authStore = useContext(AuthContext);
  const [updateRequests, setUpdateRequests] = useState([])

  useEffect(()=>{
    setUpdateRequests(data?.data)
  }, [data])

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">Update Requests</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {updateRequests.length > 0 && <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
          {updateRequests.map((request) => <UpdateRequestInline key={request.id} updateRequest={request}/>)}
        </ul>}
        {!['superuser', 'admin'].includes(authStore?.user.role)
        ? <div className='mt-2 flex items-center space-x-2'>
            <Link to="/dashboard/info/bank-form-us" className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Request update</Link>
            <QuestionMarkCircleIcon className='h-4 text-gray-500'/>
          </div>
        : <div className='mt-2 flex items-center space-x-2'>
            <Link to={{
              pathname: `bank-form-us`,
              
            }} className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Update</Link>
          </div>
        }
      </dd>
    </div>
  )
}

export default UpdateRequests
