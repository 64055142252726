import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const Paginate = ({
  page,
  setPage,
  pageInfo,
  // setPageInfo,
  hidePages = false
}) => {
  const handleNext = () => {
    if (pageInfo?.hasNext) {
      setPage(page + 1);
    }
  }

  const handlePrev = () => {
    if (pageInfo?.hasPrev) {
      setPage(page - 1);
    }
  }

  return (<>
    {pageInfo
      ? (<>
        {!hidePages && <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{(page * 20) - 19}</span> to <span className="font-medium">{page * 20}</span> of{' '}
            <span className="font-medium">{pageInfo?.total}</span> results
          </p>
        </div>}
        <div className="flex-1 flex justify-between sm:justify-end">
          <span
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
            onClick={() => handlePrev()}
          >
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            <span>Previous</span>
          </span>
          <span
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
            onClick={() => handleNext()}
          >
            <span>Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        </div>
      </>)
      : (<span>Loading pages...</span>)
    }
  </>);
}

export default Paginate;
