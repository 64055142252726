import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm, useFieldArray } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import Pluralize from 'react-pluralize';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import Spinner from "components/Icons/Spinner"
import { getReports, createReportAliasFromReport } from 'api/core';
import { classNames } from 'components/utils';
import { useTranslation } from 'react-i18next';

export default function ReportPalette ({ open, setOpen, alias }) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({});

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const filteredReports =
    query === ''
      ? reports.filter((report) => {
        return report.id !== alias.id
      })
      : reports.filter((report) => {
          return report.id !== alias.id && report.name.toLowerCase().includes(query.toLowerCase())
        });
  const currentItems = filteredReports.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredReports.length / itemsPerPage);

  useEffect(() => {
    if (alias) {
      setValue('alias', alias.id);
      setLoading(true)
      getReports(1, '', 'all').then(res => {
        setReports(res.data?.data);
        setLoading(false);
      }).catch(err => {
        console.log('err');
        setLoading(false);
      });
    }
  }, [alias]);

  useEffect(() => {
    if (!open) setQuery('')
  }, [open]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredReports.length;
    setItemOffset(newOffset);
  };

  const handleSelect = (report) => {
    setSelectedReport(report);
    setValue('report', report.id);
  }

  const onSubmit = (data) => {
    setLoading(true);
    createReportAliasFromReport(data.report, data.alias).then(res => {
      setLoading(false);
      setOpen(false);
    }).catch(err => {
      setLoading(false);
    })
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type="hidden"
                    name="alias"
                    {...register("alias", { required: true })}
                  />
                  {errors?.alias?.type === "required" ? (
                    <p className="text-red-500 text-sm">Please enter an alias</p>
                  ) : null}
                  <input
                    type="hidden"
                    name="report"
                    {...register("report", { required: true })}
                  />
                  {errors?.report?.type === "required" ? (
                    <p className="text-red-500 text-sm">Please enter a report</p>
                  ) : null}
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Select Report
                      </Dialog.Title>
                      <div className="mt-2 mb-2">
                        Set <span className="font-medium">{alias.name}</span> as alias to:
                      </div>
                      <div className="mt-2">
                        <div>
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <div className="relative mt-1 flex items-center">
                            <input
                              type="text"
                              name="search"
                              id="search"
                              className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              onChange={(event) => setQuery(event.target.value)}
                            />
                            {/* <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                              <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
                                ⌘Enter
                              </kbd>
                            </div> */}
                          </div>
                        </div>
                        <div className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200 text-left">
                          {!loading && currentItems?.map((item, itemIdx) => (
                            <div key={item.id}
                              className={classNames(
                                "relative flex items-start py-3 px-3 cursor-pointer hover:bg-gray-100",
                                selectedReport?.id === item.id && "bg-green-50"
                              )}
                              onClick={() => handleSelect(item)}
                            >
                              <div className="min-w-0 flex-1 text-sm flex gap-2">
                                <label htmlFor={`report-${item.id}`} className="select-none font-medium text-indigo-800">
                                  {item.name}
                                </label>
                                {item.aliases.length > 0 && (<span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                  <Pluralize singular={'alias'} plural={'aliases'} count={item.aliases.length} />
                                </span>)}
                              </div>
                              <div className="ml-3 flex h-5 items-center">
                                {selectedReport?.id === item.id && <CheckCircleIcon className="h-4 w-4 text-green-600" aria-hidden="true" />}
                              </div>
                            </div>
                          ))}

                          {loading && (<div className="relative flex items-start py-4">
                            <div className="min-w-0 flex-1 text-sm">
                              <div className="flex justify-center gap-2 px-6"><Spinner /> Loading reports...</div>
                            </div>
                          </div>)}
                        </div>
                        {!loading && currentItems && (<div className="w-full mt-4">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}

                            containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
                            pageLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            breakLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            previousLinkClassName="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            nextLinkClassName="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            activeLinkClassName=""
                            disabledLinkClassName=""
                          />
                        </div>)}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                      {t('Set as Alias')}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
