import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { kFormatter } from 'components/utils';
import { topClients } from 'api/charts';
import Spinner from 'components/Icons/Spinner';
import SortDropdown from './SortDropdown'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TopClients = () => {
  const sortOptions = [
    { value: 'amount', label: 'Amount' },
    { value: 'name', label: 'Name' }
  ]

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState();
  const [selected, setSelected] = useState(sortOptions[0]);
  const [isAscending, setIsAscending] = useState(true);

  useEffect(() => {
    setLoading(true);
    topClients(selected.value, isAscending).then(res => {
      const labels = res.data?.labels;
      setChartData({
        labels,
        datasets: [
          {
            label: 'Total Gross Revenue',
            data: res.data?.gross_revenue,
            backgroundColor: 'rgb(250,137,107)',
            borderRadius: 5,
          },
        ],
      });
      setLoading(false);
    })
  }, [selected, isAscending]);

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          callback: function(value, index, ticks) {
              return kFormatter(value);
          }
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return 'client ' + value;
          }
        }
      },
    },
  };

  return (<div className="p-6">
      {!loading && chartData &&
        <div className="w-1/3 float-right">
          <SortDropdown
            options={sortOptions}s
            selected={selected}
            setSelected={setSelected}
            isAscending={isAscending}
            setIsAscending={setIsAscending}
          />
        </div>
      }
      Top Clients
      {!loading && chartData && <Bar options={options} data={chartData} />}
      {loading && (<div>
        <Spinner /><span className="">Loading data...</span>
      </div>)}
  </div>);
}

export default TopClients;
