import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'stores/auth';
import CommonProvider from 'stores/common';

import ClientRoutes from './ClientRoutes';
import AdminRoutes from './AdminRoutes'

const ProtectedRoutes = () =>  {
  const authStore = useContext(AuthContext);
  let user = authStore.user
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true;
    setLoading(true)
    if (authStore.hasSelectedAccount()) {
      authStore.fetchSelectedAccount().then(() => {
        if (isMounted) setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [authStore]);


  if (!user) {
    return null
  }

  return (<CommonProvider>
    <div className="h-full container__wrap">
      {user.role === 'client'
        ? (<ClientRoutes loading={loading} />)
        : (<AdminRoutes />)
      }
    </div>
  </CommonProvider>)
}

export default ProtectedRoutes
