import React from 'react';
import { Link } from 'react-router-dom';

const Transactions = () => {
  return (<section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
      <div className="py-5 ">
        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
          Transaction History
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Most recent transactions</p>
      </div>
      <div className="border-t border-gray-200">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Company
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Bank
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Amount
                      </th>
                      <th scope="col" className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">

                    <tr>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">10987</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">Jane Doe Inc.</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">Aug 26, 2020</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">Banco de Oro</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500"></td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500"></td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-green-800">$6000</td>
                      <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <Link href="/" className="text-blue-600 hover:text-blue-900">
                          See Detail
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
  </section>);
}

export default Transactions;
