import { PublicAPI, PrivateAPI } from "./utils"

export const login = (data) => {
  return PublicAPI.post("/api/auth/", data)
}

export const verifyToken = (data) => {
  return PublicAPI.post("/api/auth/verify/", data)
}

export const getUsers = (uid, params = {}) => {
  if (uid) {
    params["client"] = uid
  }
  return PrivateAPI.get("/api/users/", params)
}

export const createUser = (data) => {
  return PrivateAPI.post("/api/users/", data)
}

export const getUser = (id) => {
  return PrivateAPI.get(`/api/users/${id}/`)
}

export const editUser = (id, data) => {
  return PrivateAPI.put(`/api/users/${id}/`, data)
}

export const getClients = () => {
  return PrivateAPI.get("/api/clients/")
}

export const createClient = (data) => {
  return PrivateAPI.post("/api/clients/", data)
}

export const getClientTeam = (uid) => {
  return PrivateAPI.get(`/api/clients/${uid}/members/`)
}

export const deleteMember = (id) => {
  return PrivateAPI.delete(`/api/clients/members/${id}/`)
}

export const getMembers = (uid) => {
  return PrivateAPI.get(`/api/clients/${uid}/members/`)
}

export const getMember = (id) => {
  return PrivateAPI.get(`/api/members/${id}/`)
}

export const getMemberPermissionList = () => {
  return PrivateAPI.get(`/api/members/permissions-list/`)
}

export const setMemberPermission = (id, data) => {
  return PrivateAPI.post(`/api/members/${id}/set-permissions/`, data)
}
