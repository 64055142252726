import React, { useState, useContext } from "react"
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AuthContext } from "stores/auth"
import { login } from "api/expauth"
import Spinner from "components/Icons/Spinner"

const GoogleButton = ({ invitationClientData }) => {
  const authStore = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const auth = getAuth()
  const provider = new GoogleAuthProvider()

  const handleGoogleLogin = async (token) => {
    setLoading(true)
    signInWithPopup(auth, provider)
      .then((result) => {
        authStore.setLoading(true)
        let data = { accessToken: result.user.accessToken }
        if (!!invitationClientData) {
          if (invitationClientData?.uids) {
            data.assigned_clients = invitationClientData.uids.split('|')
          } else {
            data.assigned_clients = [invitationClientData.uid]
          }
        }
        login(data)
          .then((res) => {
            localStorage.setItem("accessToken", res.data.access)
            authStore.setUser(res.data.user)
            authStore.setLoggedIn(true)
            authStore.setLoading(false)
          })
          .catch((err) => {
            console.log(err.response)
          })
        setLoading(false)
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // const email = error.email;
        GoogleAuthProvider.credentialFromError(error)
        // const credential = GoogleAuthProvider.credentialFromError(error);
        setLoading(false)
      })
  }

  return (
    <button
      onClick={() => handleGoogleLogin()}
      type="button"
      className="w-full flex items-center justify-center px-4 py-4 border border-gray-300 shadow-sm text-md font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      disabled={loading}
    >
      {loading ? (
        <>
          <Spinner />
          <span className="ml-2">Logging in...</span>
        </>
      ) : (
        <>
          <FontAwesomeIcon
            icon={["fab", "google"]}
            className="ml-1 mr-2 h-4 w-4"
          />
          <span>Continue with Google</span>
        </>
      )}
    </button>
  )
}

export default GoogleButton
