import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { getUser } from 'api/expauth';
import { AuthContext } from 'stores/auth';
import Header from './components/Header';
import UserInfo from './components/UserInfo';
import AssignedClients from './components/AssignedClients';
import LoadingNotification from 'components/LoadingNotification';
import { classNames } from 'components/utils';

const UserDetail = () => {
  let { id } = useParams();
  const authStore = useContext(AuthContext)

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const isProfile = !id ? true : false

  useEffect(() => {
    setLoading(true)
    let userId = id ? id : authStore.user?.id
    getUser(userId).then(res => {
      setUser(res.data);
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    });
  }, [id, authStore.user]);

  return (
    <>
      <div className="min-h-full">
        <LoadingNotification loadingText='Fetching user information...' loading={loading} />
        <div className="py-10">
          {user && <Header user={user} isProfile={isProfile} />}

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className={classNames(
              authStore?.user?.role === 'client' ? 'lg:col-span-3' : 'lg:col-span-2',
              "space-y-6 lg:col-start-1"
            )}>
              {/* Description list*/}
             {user && <UserInfo user={user} />}
            </div>

            {user && authStore?.user && authStore?.user?.role === 'admin' && <AssignedClients user={user} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDetail
