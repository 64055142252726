import { getMembers } from "api/expauth"
import Spinner from "components/Icons/Spinner"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const List = ({ clientUid }) => {

  const [loading, setLoading] = useState(false)
  const [members, setMembers] = useState([])

  useEffect(()=>{
    if (clientUid) {
      setLoading(true)
      getMembers(clientUid)
      .then(res=>{
        setMembers(res.data.data)
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [clientUid])

  return (
    <div className="">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {!loading && members.map((member, idx) => (
                    <tr key={member.id} className={idx % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <Link to={`/team/${member.id}`}>
                          {member.user_detail.email}
                        </Link>   
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {member.role === "1" && "Owner"}
                        {member.role === "2" && "Admin"}
                        {member.role === "3" && "User"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loading && <div className="flex justify-center p-4">
                <Spinner />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default List