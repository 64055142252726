import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'stores/auth';
import AssetsTable from './components/AssetsTable';
import { getAssets } from 'api/expdata';
import LoadingNotification from 'components/LoadingNotification';

const AssetsList = () => {
  const componentIsMounted = useRef(true);
  const authStore = useContext(AuthContext)
  let selectedAccount = authStore.selectedAccount

  const [assets, setAssets] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1);
  const [rownum, setRownum] = useState(20)
  const [pageInfo, setPageInfo] = useState({
    'hasNext': false,
    'hasPrev': false,
    'total': 1,
    'pages': 1
  });

  const fetchData = useCallback(() => {
    setLoading(true)
    let externalId = selectedAccount ? selectedAccount.external_publisher_id : null
    getAssets(externalId, page, rownum)
      .then(res=>{
        setAssets(res.data.data)
        setPageInfo({
          'hasNext': res.data.has_next,
          'hasPrev': res.data.has_previous,
          'total': res.data.total,
          'pages': res.data.pages
        })
        setPage(parseInt(res.data.page))
        setLoading(false)
      })
      .catch(err=>{
        // TODO: repalce error logging
        console.log(err.response)
        setLoading(false)
      })
  }, [page])

  useEffect(() => {
    if (!componentIsMounted.current) return;
    fetchData();
    return () => {
      componentIsMounted.current = false;
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return useObserver(() => {
    return (
      <div className="px-4 sm:px-6 lg:px-8 py-5">
        <LoadingNotification loadingText='Fetching assets...' loading={loading} />
        <AssetsTable
          assets={assets}
          page={page}
          setPage={setPage}
          pageInfo={pageInfo} />
      </div>
    )
  });
}

export default AssetsList
