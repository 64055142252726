import React, { useState, useEffect } from 'react';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { classNames } from 'components/utils';

const ClientLogo = ({ client, ...props }) => {
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    if (client?.logo_url) {
      setImageUrl(client.logo_url)
    }
    // } else {
    //   setImageUrl('https://cdn-icons-png.flaticon.com/512/149/149071.png')
    // }
  }, [client]);

  return (imageUrl
    ? <img
      {...props}
      src={imageUrl}
      alt={client?.name}
      />
    : <div
      {...props}
      className={classNames(
        props.className,
        "flex items-center justify-center bg-gray-600"
    )}>
      <BuildingOffice2Icon
        className={classNames(
          "h-10 w-10 text-white"
        )}
      />
    </div>
  );
};

export default ClientLogo;
