import { getAllBankUSDetails } from "api/expclient"
import Spinner from "components/Icons/Spinner"
import { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"

const BankUSDetails = () => {

  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page = searchParams.get('page') ?? 1
  const perPage = searchParams.get('perPage') ?? 10
  const [pageInfo, setPageInfo] = useState({
    data: []
  })

  useEffect(()=>{
    setLoading(true)
    getAllBankUSDetails({ page, perPage })
    .then(res=>{
      setPageInfo(res.data)
    }).catch(err=>{
      console.log(err.response)
    }).finally(()=>{
      setLoading(false)
    })
  }, [page, perPage])

  const handleNext = () => {
    if (pageInfo?.has_next) {
      history.push({
        search: "?" + new URLSearchParams({page: parseInt(page) + 1}).toString()
      })
    }
  };

  const handlePrevious = () => {
    if (pageInfo?.has_previous) {
      if (page === "2") {
        history.push({
          search: ""
        })
      } else {
        history.push({
          search: "?" + new URLSearchParams({page: parseInt(page) - 1}).toString()
        })
      }
    }
  };

  return (
    <div className="">
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Client
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {!loading && pageInfo.data.map((bankUS, idx) => (
                    <tr key={idx} className={idx % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 cursor-pointer sm:pl-6 hover:text-blue-500">
                        <Link to={`/client-requests/bank-us/${bankUS.id}`}>{bankUS.client_details.name}</Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{bankUS.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {loading && <div className="flex justify-center p-6"><Spinner /></div>}
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Page <span className="font-medium">{page}</span>&nbsp;of&nbsp;
                    <span className="font-medium">{pageInfo.pages}</span>
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  <button type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => handlePrevious()}
                  >
                    Previous
                  </button>
                  <button type="button"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => handleNext()}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankUSDetails