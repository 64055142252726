import React, { useEffect, useContext, useState } from "react"
import { useObserver } from "mobx-react"

import { AuthContext } from "stores/auth"
import UserTable from "./components/UserTable"
import { getUsers } from "api/expauth"
import LoadingNotification from "components/LoadingNotification"

const UsersList = () => {
  const authStore = useContext(AuthContext)
  let selectedAccount = authStore.selectedAccount

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pageInfo, setPageInfo] = useState({
    hasNext: false,
    hasPrev: false,
    total: 1,
    pages: 1,
  })

  useEffect(() => {
    setLoading(true)
    let uid = selectedAccount ? selectedAccount.uid : null
    getUsers(uid, { page })
      .then((res) => {
        setUsers(res.data.results)
        setLoading(false)
        setPageInfo({
          hasNext: !!res.data.next,
          hasPrev: !!res.data.previous,
        })
      })
      .catch((err) => {
        // TODO: replace error logging
        console.log(err.response)
        setLoading(false)
      })
  }, [selectedAccount, page])

  return useObserver(() => {
    return (
      <div className="px-4 sm:px-6 lg:px-8 py-5">
        <LoadingNotification
          loadingText="Fetching users..."
          loading={loading}
        />
        <UserTable
          users={users}
          page={page}
          setPage={setPage}
          pageInfo={pageInfo}
        />
      </div>
    )
  })
}

export default UsersList
