import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from "stores/auth";
import Profile from "../Dashboard/components/Profile";


const Settings = ({ loading, client }) => {
  const authStore = useContext(AuthContext);
  return (<>
    {/* Main container */}
    <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 px-4 sm:px-6 lg:px-10 mt-8">
      {/* Left */}
      <div className="grid grid-cols-3 gap-6 lg:col-span-2 -mt-24">
        <div className="col-span-3 bg-white rounded-lg shadow divide-y divide-gray-200">

        </div>
      </div>
      {/* Right */}
      <div className="grid grid-cols-1 gap-6 lg:col-span-1 -mt-24">

      </div>
    </div>
  </>)
}

export default Settings
