import { ChevronRightIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import { Route, Switch, useParams, Link, useLocation } from 'react-router-dom'
import { AuthContext } from 'stores/auth'

import List from "./List"
import MemberDetail from './MemberDetail'

const Team = () => {

  const authStore = useContext(AuthContext);
  const selectedAccount = authStore.selectedAccount;
  const { uid } = useParams();
  const location = useLocation()
  const id = location.pathname.split("/", 4)[2] ?? ""
  const clientUid = selectedAccount ? selectedAccount.uid : uid;

  return (
    <div>
      <nav className="flex bg-white px-8 py-4" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li className="py-2">
            <div>
              <Link to="/team" className="text-gray-400 hover:text-gray-500 hover:text-blue-500">
                <UserGroupIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Team</span>
              </Link>
            </div>
          </li>
          {id && <li className="py-2">
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <div
                  className="ml-4 text-sm font-medium text-gray-500"
                >
                  {id}
                </div>
              </div>
            </li>}
        </ol>
      </nav>

      <main className='mt-4 pb-8'>
        <div className='px-4 sm:px-6 lg:px-8'>
          <Switch>
            <Route exact path="/team">
              <List clientUid={clientUid}/>
            </Route>
            <Route exact path="/team/:id">
              <MemberDetail />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  )
}

export default Team