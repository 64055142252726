import { getBankUSDetails } from 'api/expclient';
import Spinner from 'components/Icons/Spinner';
import { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from 'stores/auth';
import { format, parse} from "date-fns"
import UpdateRequests from './components/UpdateRequests';

const BankUSForm = () => {

  const [loading, setLoading] = useState()
  const authStore = useContext(AuthContext);
  const selectedAccount = authStore.selectedAccount;
  let { uid } = useParams();
  const clientUid = selectedAccount ? selectedAccount.uid : uid;

  const [data, setData] = useState({ data: [] })
  const [bankUS, setBankUS] = useState()

  useEffect(()=>{
    setLoading(true)
    getBankUSDetails(clientUid)
    .then(res=>{
      setBankUS(res.data.bank_us)
      setData(res.data)
    }).finally(()=>{
      setLoading(false)
    })
  }, [clientUid])

  if (loading) return (
    <div className='flex border items-center justify-center space-x-3 p-4'>
      <Spinner /> <span>Fetching US Bank Details</span>
    </div>
  )

  return (
    <section aria-labelledby="client-bank-us-info">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        {/* Header */}
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2 w-full flex justify-between">
              <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Bank US Details</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Must match the details of the bank account owner</p>
              </div>
            </div>
          </div>
        </div>

        {!bankUS && data.data.length === 0 && <div className='px-4 pt-5'>
          <Link to="/dashboard/info/bank-form-us" className='font-medium text-blue-500 hover:text-blue-700'>Request for a us bank detail</Link>
        </div>}

        <div className="px-4 align-middle inline-block min-w-full sm:px-6">
          <div>
            <dl className="sm:divide-y sm:divide-gray-200">
              {bankUS && <>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">First Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.first_name}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Last Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.last_name}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.dob && format(parse(bankUS?.dob, 'MM/dd/yyyy', new Date()), "MMMM d, yyyy")}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Contact Number</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.contact_number}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Social Security # or Tax ID #</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.ssti}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Account Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">{bankUS?.account_type}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Account Number</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.account_number}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Routing Number</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.routing_number}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Bank Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.bank_name}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Branch Contact Number</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.bank_contact_number}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Branch Address / City / State / Zip</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.bank_address}</dd>
                </div>
              </>}
              {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Accomplished W9 form</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">$120,000</dd>
              </div> */}
              {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">About</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              </div> */}

              {data.data.length > 0 && <UpdateRequests
                data={data}
              />}

            </dl>
          </div>
        </div>

      </div>
    </section>
  )
}

export default BankUSForm
