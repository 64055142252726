import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthContext } from 'stores/auth';

const LogoutManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
    <div className="mr-8 my-8">
      <div className="mx-24">
        <div className="flex-1">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Log Out Manual</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="divide-y divide-gray-200">

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                <dt className="text-m font-medium text-gray-700"></dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                  <span className="flex-grow col-span-4">1. On the bottom of the Client Portal sidebar, click the Log Out button below your email adress.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Logout/logout-1.png"/>
                  <span className="flex-grow col-span-4">2. You will be signed out of your account.</span>
                  <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Logout/logout-2.png"/>
                </dd>
              </div>

            </dl>
          </div>
          <div className="py-4 sm:py-5 sm:gap-4 sm:text-right">
            <Link to="/help" className="text-m font-medium text-blue-800">Back</Link>
          </div>
        </div>
      </div>
    </div>
  </div>)
  });
}

export default LogoutManual
