import React from 'react';
import { ArrowPathIcon } from "@heroicons/react/20/solid"

const FilterBox = ({
  register,
  dateFrom,
  dateTo,
  onReset
}) => {
  return (
    <div className="bg-white rounded-lg shadow">
      <form className="divide-y divide-gray-200">
        <h4 className="p-3 text-xs font-medium text-gray-500 uppercase bg-gray-50">Filters</h4>
        <div className="p-2 space-y-2">
          <div className="text-sm font-medium">Sort Options</div>
          <div className="flex items-center">
            <label className="text-sm w-20">Sort by</label>
            <select
              {...register('sort_by')}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full"
            >
              <option value="partner_revenue">Partner Revenue</option>
              <option value="created">Date</option>
            </select>
          </div>
          <div className="flex items-center">
            <label className="text-sm w-20">Order</label>
            <select
              {...register('trend')}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full"
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>
        </div>
        <div className="p-2 space-y-2">
          <div className="text-sm font-medium">Date Range</div>
          <div className="flex items-center">
            <label className="text-sm w-20">From</label>
            <input
              type="date"
              {...register('date_from', {
                validate: val => {
                  if (dateTo) {
                    if (!val) return false
                    if (dateFrom > dateTo) return false
                  }
                  return true
                }
              })}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full"
            />
          </div>
          <div className="flex items-center">
            <label className="text-sm w-20">To</label>
            <input
              type="date"
              {...register('date_to', {
                validate: val => {
                  if (dateFrom) {
                    if (!val) return false
                    if (dateFrom > dateTo) return false
                  }
                  return true
                }
              })}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full"
            />
          </div>
        </div>
        <div />
      </form>
      <div className="p-2 flex justify-end">
        <button onClick={onReset}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >Reset <ArrowPathIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true"/></button>
      </div>
    </div>
  );
}

export default FilterBox;
