import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { editServiceRate, getServices } from 'api/core';
import { useQuery } from 'utils';

const RateForm = ({ defaultValues }) => {
  const query = useQuery();
  const uid = query.get('uid');
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([{ slug: '', name: '---' }]);

  useEffect(() => {
    setLoading(true);
    getServices()
      .then((res) => {
        setServiceOptions(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const onSubmit = (data) => {
    editServiceRate(uid, data)
      .then((res) => {
        history.push(`/clients/${uid}`);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const { control, register, handleSubmit } = useForm({
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'services',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="w-full flex justify-between">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Client Services</h3>
              <p className="mt-1 text-sm text-gray-500">You may edit the details of an existing service.</p>
            </div>
            <div>
              <button
                onClick={() => append({})}
                className='inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 cursor-pointer hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                Add
              </button>
            </div>
          </div>

          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4">
            <div className="sm:col-span-1">
              {fields.map((field, index) => (
                <div key={field.id} className="py-8 space-y-6 sm:space-y-5">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Service #{index+1}
                    <span
                      className="mt-2 px-2 py-2 text-xs text-red-500 cursor-pointer font-medium hover:text-red-900"
                      onClick={() => remove(index)}
                    >
                      Remove
                    </span>
                  </h3>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Service Name
                    </label>
                    {loading ? (
                      <span>Loading...</span>
                    ) : (
                      <select
                        name="slug"
                        id="slug"
                        className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                        {...register(`services.${index}.slug`, { required: true })}
                      >
                        {serviceOptions.map((service) => (
                          <option key={service.slug} value={service.slug}>
                            {service.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Rate</label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      name="rate"
                      id="rate"
                      className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      {...register(`services.${index}.rate`, { required: true })}
                    />
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Is Invoice?
                      <span className="pl-2">
                        <input
                          id="is_invoice"
                          name="is_invoice"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          {...register(`services.${index}.is_invoice`)}
                        />
                      </span>
                    </label>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Is Active?
                      <span className="pl-5">
                        <input
                          id="is_active"
                          name="is_active"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          {...register(`services.${index}.is_active`)}
                        />
                      </span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Link
            to={`/clients/${uid}`}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default RateForm;