import React, { Fragment, useContext } from "react"
import { useHistory } from "react-router"
import { Menu, Transition } from "@headlessui/react"
import { ArrowsRightLeftIcon, CheckIcon } from "@heroicons/react/20/solid"
import { useObserver } from "mobx-react-lite"

import { AuthContext } from "stores/auth"
import { classNames } from "components/utils"

const ClientDropdown = ({ accounts, selectedAccount }) => {
  const authStore = useContext(AuthContext)
  const history = useHistory()

  const resetSelectedAccount = () => {
    authStore.resetSelectedAccount()
    history.push('/')
  }

  return useObserver(() => {
    return (
      <Menu
        as="div"
        className="relative inline-block text-left w-full border-t border-b border-blue-600"
      >
        <div>
          <div className="flex justify-between w-full font-medium text-white focus:outline-none focus:ring-0 focus:ring-offset-0">
            <div className="px-4 py-3">
              <span className="block text-xs text-blue-500">Logged in to</span>
              <span className="block text-md">
                {selectedAccount ? selectedAccount.name : "Select Client"}
              </span>
            </div>
            {accounts?.length > 1 && (
              <button onClick={() => resetSelectedAccount()} className="px-4 py-3 border-l border-blue-600 hover:bg-blue-700">
                <ArrowsRightLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>

        {/* <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-4 z-10 mt-1 w-56 origin-top-right bg-blue-900 shadow-lg ring-0 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-2">
              {accounts &&
                accounts?.map((client) => {
                  const selected = client.uid === selectedAccount.uid
                  return (
                    <Menu.Item key={client.pk}>
                      {({ active }) => (
                        <div
                          onClick={() =>
                            authStore.setSelectedAccount(client.client_detail)
                          }
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-white",
                            selected ? "" : "",
                            "block flex justify-between px-4 py-3 text-sm font-medium"
                          )}
                        >
                          <span>{client.client_detail.name}</span>
                          {selected && (
                            <CheckIcon
                              className="text-green-400 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      )}
                    </Menu.Item>
                  )
                })}
            </div>
          </Menu.Items>
        </Transition> */}
      </Menu>
    )
  })
}

export default ClientDropdown
