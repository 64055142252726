import React from 'react';
import { Link, useParams } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import RelatedAssets from "./components/RelatedAssets";
import AssetStats from "./components/AssetStats";

const ClientAssetDetail = ({ client }) => {
  const { title } =  useParams();

  const pages = [
    { name: 'Assets', href: `/clients/${client.uid}/assets/`, current: false },
    { name: title, href: '#', current: true },
  ];

  return (
    <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
      {/* Left */}
      <div className="grid grid-cols-12 gap-6 lg:col-span-12">
        <div className='col-span-9 bg-white rounded-lg shadow'>
          <div className="p-6">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link to={`/clients/${client.uid}/`} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                      <span>{client.name}</span>
                    </Link>
                  </div>
                </li>
                {pages.map((page) => (
                  <li key={page.name}>
                    <div className="flex items-center">
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <Link
                        to={page.href}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current={page.current ? 'page' : undefined}
                      >
                        {page.name}
                      </Link>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
            <h1 className="mt-2 text-3xl font-bold text-gray-900">{title}</h1>
          </div>
        </div>

        <div className="col-span-3">
          <AssetStats client={client} title={title} />
        </div>

        <div className="col-span-12">
          <RelatedAssets />
        </div>

      </div>
      {/* Right */}
      <div className="grid grid-cols-1 gap-6 lg:col-span-1">
        {/* test */}
      </div>
    </div>
  )
}

export default ClientAssetDetail
