import { getClientStats } from "api/core";
import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router";
import { AuthContext } from "stores/auth";

import Royalties from "./components/Royalties"

const ClientReports = () => {

  const authStore = useContext(AuthContext);
  let { uid } = useParams();
  const selectedAccount = authStore.selectedAccount
  const clientUid = selectedAccount ? selectedAccount.uid : uid;

  const [stats, setStats] = useState()

  const fetchStats = () => {
    // setStats(null);
    const source = localStorage.getItem("clientSource") || 'royalties';
    getClientStats(clientUid, source).then(res => {
      setStats(res.data);
    }).catch(err => {
      console.log(err);
    });
  }

  useCallback(()=>{
    fetchStats()
  }, [clientUid])

  return (
    <div className="p-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <div className="col-span-1">
        <Royalties
          stats={stats}
        />
      </div>
    </div>
  )
}

export default ClientReports
