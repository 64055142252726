import React, { useEffect, useState } from 'react';
import Spinner from 'components/Icons/Spinner';
import { useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import AddTransactionForm from './components/AddTransactionForm';
import { getClient } from 'api/core';

const AddTransaction = () => {
  const { uid } = useParams()

  const [client, setClient] = useState()

  useEffect(() => {
    getClient(uid).then(res => {
      setClient(res.data.data)
    })
  }, [uid])

  return useObserver(() => {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className='px-4 sm:px-6 lg:px-8 py-5'>
          <div className="md:flex md:items-center md:justify-between my-5">
            <div className="flex-1 min-w-0">
              <h2 className="text-3xl leading-6 font-medium text-gray-900">Add Transaction</h2>
            </div>
          </div>
          {!client && <Spinner />}
          {client && <AddTransactionForm client={client} uid={uid} />}
        </div>
      </div>)
    });
}

export default AddTransaction
