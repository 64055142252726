import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AssetsTable = ({ assets, page, setPage, pageInfo }) => {
  const { t } = useTranslation();

  const handleNext = () => {
    if (pageInfo?.hasNext) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (pageInfo?.hasPrev) {
      setPage(page - 1);
    }
  };

  return (<div className="w-full mx-auto">
    <div className="md:flex md:items-center md:justify-between my-5">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{t('Assets')}</h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">&nbsp;</div>
    </div>
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Title')}</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Collectors')}</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Owners')}</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Writers')}</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('Ownership')}</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {assets.length > 0
                  ? (<>
                    {assets.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {item._title}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                          {item.publishers?.map((publisher, idx) => {
                              return (<>
                                <span>{publisher['name']}</span>
                                {item.publishers.length === (idx + 1) ? '' : <br />}
                              </>)
                            })
                            }
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {item.owners?.map((owner, idx) => {
                              return (<>
                                <span>{owner['name']}</span>
                                {item.owners.length === (idx + 1) ? '' : <br />}
                              </>)
                            })
                            }
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.writers?.map((writer, idx) => {
                              return (<>
                                <span>{writer['name']}</span>
                                {item.writers.length === (idx + 1) ? '' : <br />}
                              </>)
                            })
                            }
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            <div className='table-asset-codes'>
                              <span><small>Sync:&nbsp;&nbsp;</small>{item.sr_total ? item.sr_total : '---'}</span><br />
                              <span><small>Mech:&nbsp;&nbsp;</small>{item.mr_total ? item.mr_total : '---'}</span><br />
                              <span><small>Perf:&nbsp;&nbsp;</small>{item.pr_total ? item.pr_total : '---'}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>)
                  : (<tr>
                    <td />
                    <td />
                    <td>{t('No assets to display.')}</td>
                    <td />
                    <td />
                  </tr>)
                }
                
              </tbody>
            </table>
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Page <span className="font-medium">{page}</span>&nbsp;of&nbsp;
                  <span className="font-medium">{pageInfo?.pages}</span>
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <button type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  onClick={() => handlePrevious()}
                >
                  Previous
                </button>
                <button type="button"
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  onClick={() => handleNext()}
                >
                  Next
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default AssetsTable;
