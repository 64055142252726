import React from 'react'
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'

export const InfoBar = ({ text, onClose, status }) => {
  var color = 'blue-50';
  var textColor = 'blue-800';
  var hoverColor = 'blue-100';
  var iconColor = 'blue-400';

  if (status === 'error') {
    color = 'red-200';
    textColor = 'gray-700';
    hoverColor = 'red-400';
    iconColor = 'gray-700';
  }

  return (
    <div className={`mb-5 rounded-md bg-${color} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className={`h-5 w-5 text-${iconColor}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium text-${textColor}`}>{text}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={onClose}
              className={`inline-flex bg-${color} rounded-md p-1.5 text-${textColor} hover:bg-${hoverColor} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-50 focus:ring-blue-600`}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBar
