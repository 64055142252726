import PrivateAPI from "./private"
import PublicAPI from "./public"

export const getUserByEmail = (email) => {
  return PrivateAPI.post(`/api/users/email/`, { email: email })
}

export const assignUserToClient = (email, uid) => {
  return PrivateAPI.post(`/api/clients/${uid}/assign-user/`, { email: email })
}

export const unassignUserToClient = (uid) => {
  return PrivateAPI.delete(`/api/members/${uid}/unassign/`)
}

export const getUserAssignedClients = (id) => {
  return PrivateAPI.get(`/api/users/${id}/assigned-clients/`)
}

export const verifyInviteToken = (inviteToken) => {
  return PublicAPI.post(`/api/users/verify-invite-token/`, {
    'token': inviteToken
  })
}

export const registerInviteToken = (data) => {
  return PublicAPI.post(`/api/users/register-invite-token/`, data)
}
