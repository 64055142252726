import React from 'react'
import { useLocalStore } from 'mobx-react'

import {
  userDetail,
  userUpdate,
  userChangePhoto,
  login as loginApi,
  changePassword as changePasswordApi
} from 'api/auth'

import { getClient } from 'api/core'

export const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const store = useLocalStore(() => ({
    loading: false,
    loggedIn: false,
    user: null,
    userType: 'user',
    selectedAccount: null,

    hasToken: () => {
      return localStorage.getItem('accessToken')
    },

    hasSelectedAccount: () => {
      return localStorage.getItem('selectedAccount')
    },

    isAuthenticated: () => {
      return store.loggedIn
    },

    isAdmin: () => {
      return store.user.scope === 'admin'
    },

    setLoading: (loading) => {
      store.loading = loading
    },

    setLoggedIn: (loggedIn) => {
      store.loggedIn = loggedIn
    },

    setUser: (user) => {
      store.user = user
    },

    setSelectedAccount: (client) => {
      store.selectedAccount = client
    },

    resetSelectedAccount: () => {
      localStorage.removeItem('selectedAccount');
      store.selectedAccount = null;
    },

    login: (payload) => {
      store.loading = true;
      return loginApi({
        'email': payload.email,
        'password': payload.password
      }).then((res) => {
        localStorage.setItem('accessToken', res.data.access)
        store.loggedIn = true
        return store.fetchUser();
      }).catch((err) => {
        store.reset()
        if (err.response) {
          throw err.response
        }
      })
    },

    oauthLogin: (payload) => {
      localStorage.setItem('accessToken', payload.token);
      store.user = payload.user;
      store.loggedIn = true;
      return store.fetchUser();
    },

    setTokens: (access) => {
      localStorage.setItem('accessToken', access)
      store.loggedIn = true
      return store.fetchUser();
    },

    reset: () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('selectedAccount');
      store.user = null;
      store.loggedIn = false;
      store.loading = false;
      store.selectedAccount = null;
    },

    logout: () => store.reset(),

    fetchUser: () => {
      store.loading = true
      return userDetail().then((res) => {
        store.user = res.data.user
        store.loggedIn = true
        store.loading = false
        return res.data
      }).catch((err) => {
        store.reset()
        throw err
      })
    },

    fetchSelectedAccount: () => {
      store.loading = true
      let uid = localStorage.getItem('selectedAccount')
      return getClient(uid).then((res) => {
        store.selectedAccount = res.data.data
        store.loading = false
        return res.data
      }).catch((err) => {
        store.reset()
        throw err
      })
    },

    updateUser: (data) => {
      return userUpdate(data).then((res) => {
        store.user = res.data
        return res.data
      }).catch((err) => {
        throw err
      })
    },

    changePhoto: (data) => {
      return userChangePhoto(data).then((res) => {
        store.user = res.data
        return res.data
      }).catch((err) => {
        throw err
      })
    },

    changePassword: (data) => {
      return changePasswordApi(data).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    }
  }))

  return <AuthContext.Provider value={store}>
    {children}
  </AuthContext.Provider>
}

export default AuthProvider
