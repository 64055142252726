import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';

import LoadingNotification from 'components/LoadingNotification';
import EditClientForm from './components/EditClientForm'
import { getClient } from 'api/core';
import { useQuery } from 'utils';

const EditClient = () => {
  const query = useQuery()
  let uid = query.get('uid')
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState();

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getClient(uid).then(res => {
        setClient(res.data?.data);
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    }
  }, [uid]);

  return useObserver(() => {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className='px-4 sm:px-6 lg:px-8 py-5'>
          <div className="md:flex md:items-center md:justify-between my-5">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Edit Client</h2>
            </div>
          </div>
          <LoadingNotification loadingText='Fetching client data...' loading={loading} />
          {!loading && <EditClientForm uid={uid} defaultValues={client} />}
        </div>
      </div>)
    });
}

export default EditClient
