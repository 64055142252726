import React, { useContext, useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import { useObserver } from "mobx-react"

import { AuthContext } from "stores/auth"
import Form from "./components/Form"
import ClientUserForm from "./components/ClientUserForm"
import LoadingNotification from "components/LoadingNotification"
import { getUser } from "api/expauth"
import { getAllClients } from "api/core"

const UserForm = () => {
  const authStore = useContext(AuthContext)
  let selectedAccount = authStore.selectedAccount
  const { id } = useParams()
  const location = useLocation()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(false)
  const [clients, setClients] = useState()
  const userId = id ? id : authStore.user?.id
  const isProfile = id ? false : true
  const isEditing = location.pathname.includes("edit") ? true : false

  useEffect(() => {
    setLoading(true)
    if (isEditing) {
      getUser(userId).then((res) => {
        setUser({
          ...res.data,
          assigned_clients: res.data.teams.map((team) => ({
            value: team.client_detail.uid,
            label: team.client_detail.slug,
          })),
        })
      })
    }

    getAllClients().then((res) => {
      let values = res.data?.data
      let options = values.map((client, idx) => {
        return { value: client.uid, label: client.name }
      })
      setClients(options)
      setLoading(false)
    })
  }, [location.pathname, isEditing, userId])

  return useObserver(() => {
    if (isEditing && !user) {
      return null
    }
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="px-4 sm:px-6 lg:px-8 py-5">
          <div className="md:flex md:items-center md:justify-between my-5">
            <div className="flex-1 min-w-0">
              <h2 className="text-3xl leading-6 font-medium text-gray-900">
                {isEditing ? "Edit" : "Add"}{" "}
                {isEditing && isProfile ? "Profile" : "User"}
              </h2>
            </div>
          </div>
          <LoadingNotification
            loadingText="Fetching client list..."
            loading={loading}
          />
          {!loading && (
            <>
              {selectedAccount ? (
                <>
                  {isEditing ? (
                    <div>
                      {user && (
                        <ClientUserForm
                          id={userId}
                          user={user}
                          client={selectedAccount?.uid}
                          isProfile={isProfile}
                        />
                      )}
                    </div>
                  ) : (
                    <ClientUserForm client={selectedAccount?.uid} />
                  )}
                </>
              ) : (
                <>
                  {isEditing ? (
                    <div>
                      {user && (
                        <Form
                          id={userId}
                          user={user}
                          clients={clients}
                          isProfile={isProfile}
                        />
                      )}
                    </div>
                  ) : (
                    <Form clients={clients} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    )
  })
}

export default UserForm
