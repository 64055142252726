import React, { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AuthContext } from 'stores/auth';

const ProfileManual = () => {
  const authStore = useContext(AuthContext)

  return useObserver(() => {
    return (<div className="flex-1">
      <div className="mr-8 my-8">
        <div className="mx-24">
          <div className="flex-1">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Profile Manual</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
            </div>
            <div className="mt-5 border-t border-gray-200">
              <dl className="divide-y divide-gray-200">

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">View Profile Information</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the sidebar of the Client Portal, click the Profile button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-1.png"/>
                    <span className="flex-grow col-span-4">2. The profile information is displayed on the Profile page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-2.png"/>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Edit Profile</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the upper right of the profile page, click on the Edit button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-3.png"/>
                    <span className="flex-grow col-span-4">2. Fill out information you want to update.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-4.png"/>
                    <span className="flex-grow col-span-4">3. Click the Save button located on the lower right of the profile page.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-5.png"/>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Delete Profile</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the upper right of the profile page, click on the Delete button. </span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-6.png"/>
                  </dd>
                </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-5 sm:gap-4">
                  <dt className="text-m font-medium text-gray-700">Add to Client</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-1 sm:col-span-4 sm:gap-y-4 text-left">
                    <span className="flex-grow col-span-4">1. On the right side of the profile page, locate the Clients section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-7.png"/>
                    <span className="flex-grow col-span-4">2. Click on the dropdown button in the Clients section.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-8.png"/>
                    <span className="flex-grow col-span-4">3. Select client you want the user to be assigned to.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-9.png"/>
                    <span className="flex-grow col-span-4">4. Click assign to client button.</span>
                    <img className="h-full w-auto col-span-4" src="/assets/images/UserManual/Profile/profile-10.png"/>
                    <span className="flex-grow col-span-4">5. User has been assigned to client.</span>
                  </dd>
                </div>

              </dl>
            </div>
            <div className="py-4 sm:py-5 sm:gap-4 sm:text-right">
              <Link to="/help" className="text-m font-medium text-blue-800">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>)
  });
}

export default ProfileManual
