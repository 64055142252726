import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export function Modal({ isOpen, closeModal, children, dialogTitle }) {
    return <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={closeModal}>

    <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="w-full flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-1/3 max-w-1/2 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
              <div className="">
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {dialogTitle}
                  </Dialog.Title>
                </div>
                <div className="p-4">{children}</div>
              </div>
              </Dialog.Panel> 
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
}