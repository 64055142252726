import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { format, parseISO} from "date-fns";

import { classNames } from 'components/utils';
import { getMonthName } from 'utils';
import { getClientYTMonthlyReport, getClientYTAdRates } from 'api/reports';
import { getMonthlyConflicts, getMonthlyClaims } from 'api/expdata';
import { useTranslation } from 'react-i18next';

const PerformanceSummary = ({ uid, name, startMonth, startYear, endMonth, endYear }) => {
  const { t } = useTranslation();
  const startMonthName = getMonthName(startMonth);
  const endMonthName = getMonthName(endMonth);
  const [loading, setLoading] = useState(false);
  const [generalSummary, setGeneralSummary] = useState();
  const [conflictsData, setConflictsData] = useState();
  const [claimData, setClaimData] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchDetails = async () => {
      try {
        if (uid) {
          const generalSummaryData = await getClientYTMonthlyReport(uid, startYear, startMonth, startYear, startMonth);
          setGeneralSummary(generalSummaryData?.data?.data);
        }

        if (name) {
          const conflicts = await getMonthlyConflicts(startMonth, startYear, null, null, name);
          const claims = await getMonthlyClaims(startMonth, startYear, null, null, name);
          setConflictsData(conflicts?.data);
          setClaimData(claims?.data);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [uid, name, startMonth, startYear]);

  const conflictKeys = [
    {'key': 'title', 'label': t('Title')},
    {'key': 'approx_daily_views', 'label': t('Daily Views')},
    {'key': 'asset_id', 'label': t('SR Asset ID')}
  ]

  return (<>
    <div className="mb-6">
      <div className="text-3xl font-bold tracking-tight text-blue-gray-900">
        {t('Monthly Report Summary for')} <span className="text-blue-800">{t(startMonthName)} {startYear}</span>
        {/* {(startMonth === endMonth && startYear === endYear)
          ? (<>Youtube Monthly Report Summary for <span className="text-blue-800">{startMonthName} {startYear}</span></>)
          : (<>Youtube Report Summary for <span className="text-blue-800">{startMonthName} {startYear}</span> to <span className="text-blue-800">{endMonthName} {endYear}</span></>)
        } */}
      </div>
      {/* {generalSummary && <p className="mt-1 max-w-3xl text-md text-gray-500">
        Data as of {format(parseISO(generalSummary?.created), "MMMM d, yyyy pppp")}
      </p>} */}
    </div>

    <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('YouTube Revenue')}</h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          {generalSummary?.youtube_reports?.map((item, idx) => {
            return (
              <div
                key={'item__' + idx}
                className={
                  classNames(
                    "bg-white",
                    "px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  )
              }>
                <dt className="text-md font-medium text-gray-500">{item.report}</dt>
                <dd className="mt-1 text-md text-gray-500 font-medium sm:col-span-2 sm:mt-0">
                  {!loading ? (
                    item.gross_revenue !== null && item.gross_revenue !== undefined ?
                      <NumberFormat
                        value={parseFloat(item.gross_revenue).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    : 'N/A'
                  ) : (
                    <small>Loading</small>
                  )}
                </dd>
              </div>
            )
          })}
        </dl>
        <dl>
          <div className={"bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"}>
            <dt className="text-md font-medium text-gray-900">{t('YouTube Total Revenue')}</dt>
            <dd className="mt-1 text-md text-gray-900 font-medium sm:col-span-2 sm:mt-0">
              {!loading ? (
                generalSummary?.youtube_total_revenue !== null && generalSummary?.youtube_total_revenue !== undefined ?
                  <NumberFormat
                    value={parseFloat(generalSummary?.youtube_total_revenue).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                : 'N/A'
              ) : (
                <small>Loading</small>
              )}
            </dd>
          </div>
        </dl>
      </div>
    </div>

    {generalSummary?.mechanical_reports?.length > 0
      ? (<div className="overflow-hidden bg-white shadow sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Mechanicals Revenue')}</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {generalSummary?.mechanical_reports.map((item, idx) => {
              return (
                <div
                  key={'item__' + idx}
                  className={
                    classNames(
                      "bg-white",
                      "px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                    )
                }>
                  <dt className="text-md font-medium text-gray-500">{item.report}</dt>
                  <dd className="mt-1 text-md text-gray-500 font-medium sm:col-span-2 sm:mt-0">
                    {!loading ? (
                      item.gross_revenue !== null && item.gross_revenue !== undefined ?
                        <NumberFormat
                          value={parseFloat(item.gross_revenue).toFixed(2)}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      : 'N/A'
                    ) : (
                      <small>Loading</small>
                    )}
                  </dd>
                </div>
              )
            })}
          </dl>
          <dl>
            <div className={"bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"}>
              <dt className="text-md font-medium text-gray-900">{t('Mechanicals Total Revenue')}</dt>
              <dd className="mt-1 text-md text-gray-900 font-medium sm:col-span-2 sm:mt-0">
                {!loading ? (
                  generalSummary?.mechanicals_total_revenue !== null && generalSummary?.mechanicals_total_revenue !== undefined ?
                    <NumberFormat
                      value={parseFloat(generalSummary?.mechanicals_total_revenue).toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  : 'N/A'
                ) : (
                  <small>Loading</small>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>) : null
    }

    <div id='conflict-resolution' className="overflow-hidden bg-white shadow sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Conflict Resolutions')}</h3>
      </div>

      <div className="border-t border-b border-gray-200">
        <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden bg-white md:grid-cols-2 md:divide-y-0 md:divide-x">
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{t('Total assets resolved in our favor')}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                <NumberFormat
                  value={conflictsData?.total_assets || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </div>
            </dd>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{t('Total daily views resolved')}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                <NumberFormat
                  value={conflictsData?.total_daily_views || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                />
              </div>
            </dd>
          </div>
        </dl>
      </div>

      <div className="mt-2">
        <div className="px-6 py-3 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Notable Resolutions')}</h3>
        </div>

        <div className="px-4 sm:px-6 pb-4">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-white">
              <tr>
                {conflictKeys.map((k, idx) => {
                  return (
                    <th key={k.key} scope="col"
                      className={
                        classNames(
                          idx === 0 ? '' : '',
                          "py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        )
                      }
                    >
                      {k.label}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {conflictsData && conflictsData.notable_resolutions.map((rowData, idx) => (
                <tr key={'rowData__' + idx}>
                  {conflictKeys.map((k, kIdx) => {
                    return (
                      <td key={'conflicts_' + idx + '_' + kIdx}
                        className={
                          classNames(
                            k.key === 'title' ? 'font-medium' : '',
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                          )
                        }
                      >
                        {k.key === 'title' && rowData.title}
                        {k.key === 'asset_id' && rowData.asset_id}
                        {k.key === 'approx_daily_views' && <NumberFormat
                          value={rowData.approx_daily_views}
                          displayType={'text'}
                          thousandSeparator={true}
                        />}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>);
}

export default PerformanceSummary;
