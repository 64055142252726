import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import UnderConstruction from "./static/UnderConstruction"

import Dashboard from "./Dashboard"
// import EmailConfirmation from './Auth/EmailConfirmation';

import ClientDashboard from "./ClientDashboard"

import ClientsList from "./Clients/List"
import AddClient from "./Clients/AddClient"
import EditClient from "./Clients/EditClient"
import ClientDetail from "./Clients/Detail"
import ClientInformation from "./Clients/ClientInformation"
import BankFormUS from "./Clients/BankInformation/BankFormUS"
import BankFormInt from "./Clients/BankInformation/BankFormInt"
import W9 from "./Clients/BankInformation/W9"
import W8BEN from "./Clients/BankInformation/W8BEN"
import W8BENE from "./Clients/BankInformation/W8BENE"
import AddTransaction from "./Clients/AddTransaction"
import AddFile from "./Clients/AddFile"
import AddRates from "./Clients/AddRates/"
import EditRates from "./Clients/EditRates/"

import ContractPage from "./Contracts/ContractPage"
import ContractForms from "./Contracts/ContractForms"

import AssetsList from "./Assets/List"

import UsersList from "./Users/UsersList"
import UserForm from "./Users/UserForm"
import UserDetail from "./Users/UserDetail"
import MainWrapper from "containers/MainWrapper"

import Reports from "./Reports/List"
import RoyaltySummaries from "./Reports/RoyaltySummaries"
import MonthlyAnnualReports from "./Reports/MonthlyAnnualReports"
import PlatformReports from "./Reports/PlatformReports"
import AssetReports from "./Reports/AssetReports"
import ConflictsReports from "./Reports/ConflictsReports"
import ClaimingReports from "./Reports/ClaimingReports"

import UserManual from "./UserManual"
import ClientInformationManual from "./UserManual/ClientInformationManual"
import ClientsManual from "./UserManual/ClientsManual"
import DashboardManual from "./UserManual/DashboardManual"
import LoginManual from "./UserManual/LoginManual"
import LogOutManual from "./UserManual/LogOutManual"
import ProfileManual from "./UserManual/ProfileManual"
import UsersManual from "./UserManual/UsersManual"
import AdminHome from "./Admin/Home"
import AssetLabels from "./Admin/AssetLabels"
import ClientRequests from "./Admin/ClientRequests"
import MemberDetail from "./Team/MemberDetail"
import DownloadView from "./Download"
import RequestView from "./Request"

import ReportTypes from "./Admin/ReportTypes/List"
import ReportTypesForm from "./Admin/ReportTypes/Form"
import CategoriesForm from "./Admin/ReportTypes/CategoryForm"

import Tasks from "./Admin/Tasks"

import Search from "./Assets/Search"

import MyDownloads from "./MyDownloads"

const AdminRoutes = () => {
  return (
    <MainWrapper>
      <div className="h-full container__wrap">
        <Switch>
          {/* <Route path="/account/verify" component={EmailConfirmation} /> */}
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/my-downloads" component={MyDownloads} />

          <Route exact path="/clients" component={ClientsList} />
          <Route exact path="/clients/add" component={AddClient} />
          <Route exact path="/clients/edit" component={EditClient} />
          <Route exact path="/clients/rates/add" component={AddRates} />
          <Route exact path="/clients/rates/edit" component={EditRates} />

          <Route
            // exact
            path="/clients/:uid"
            component={ClientDashboard}
          />

          <Route
            exact
            path="/clients/contracts/view"
            component={ContractPage}
          />
          <Route
            exact
            path="/clients/contracts/form"
            component={ContractForms}
          />
          <Route
            exact
            path="/clients/:uid/transactions"
            component={AddTransaction}
          />
          <Route exact path="/clients/:uid/files" component={AddFile} />
          <Route
            exact
            path="/clients/:uid/info/bank-form-us"
            component={BankFormUS}
          />

          <Route exact path="/users" component={UsersList} />
          <Route exact path="/users/add" component={UserForm} />
          <Route exact path="/users/:id" component={UserDetail} />
          <Route exact path="/users/edit/:id" component={UserForm} />
          <Route exact path="/profile/edit/" component={UserForm} />
          <Route exact path="/profile/" component={UserDetail} />

          <Route exact path="/info/" component={ClientInformation} />
          <Route exact path="/info/bank-info/form/us" component={BankFormUS} />
          <Route exact path="/info/bank-info/form/us/w9" component={W9} />
          <Route
            exact
            path="/info/bank-info/form/int"
            component={BankFormInt}
          />
          <Route
            exact
            path="/info/bank-info/form/int/w8ben"
            component={W8BEN}
          />
          <Route
            exact
            path="/info/bank-info/form/int/w8bene"
            component={W8BENE}
          />

          <Route path="/help" component={UserManual} />
          <Route
            path="/support/clientinfo"
            component={ClientInformationManual}
          />
          <Route path="/support/clients" component={ClientsManual} />
          <Route path="/support/dashboard" component={DashboardManual} />
          <Route path="/support/login" component={LoginManual} />
          <Route path="/support/logout" component={LogOutManual} />
          <Route path="/support/profile" component={ProfileManual} />
          <Route path="/support/users" component={UsersManual} />

          <Route exact path="/assets" component={AssetsList} />

          <Route exact path="/team/:id" component={MemberDetail} />

          <Route exact path="/admin" component={AdminHome} />
          <Route exact path="/admin/asset-labels" component={AssetLabels} />
          <Route exact path="/admin/report-types" component={ReportTypes} />
          <Route
            exact
            path="/admin/report-types/add"
            component={ReportTypesForm}
          />
          <Route
            exact
            path="/admin/report-types/:id/edit"
            component={ReportTypesForm}
          />
          <Route
            exact
            path="/admin/report-categories/add"
            component={CategoriesForm}
          />
          <Route exact path="/admin/tasks" component={Tasks} />

          <Route exact path="/search/assets" component={Search} />

          <Route exact path="/reports" component={AssetReports} />
          <Route
            exact
            path="/reports/royalty-summaries"
            component={RoyaltySummaries}
          />
          <Route
            exact
            path="/reports/monthly-annual-reports"
            component={MonthlyAnnualReports}
          />
          <Route
            exact
            path="/reports/platform-reports"
            component={PlatformReports}
          />
          <Route exact path="/reports/asset-reports" component={AssetReports} />
          <Route
            exact
            path="/reports/conflicts-reports"
            component={ConflictsReports}
          />
          <Route
            exact
            path="/reports/claiming-reports"
            component={ClaimingReports}
          />

          <Route path="/client-requests" component={ClientRequests} />
          <Route exact path="/downloads" component={DownloadView} />
          <Route exact path="/requests" component={RequestView} />

          <Route path="/team" component={UnderConstruction} />

          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </MainWrapper>
  )
}

export default AdminRoutes
