import React from 'react';

import { classNames } from '../../../../components/utils';

const Header = ({ currentTab, setCurrentTab }) => {
  const tabs = [
    { name: 'Reports', href: '#', current: currentTab === 'Reports' },
    { name: 'Clients', href: '#', current: currentTab === 'Clients' },
    { name: 'Asset Labels', href: '#', current: currentTab === 'Asset Labels' },
    { name: 'Users', href: '#', current: currentTab === 'Users' },
  ]

  return (<div className="pb-5 border-b border-gray-200 sm:pb-0">
    <h2 className="mt-5 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Admin</h2>
    <div className="mt-3 sm:mt-4">
      <div className="sm:hidden">
        <label htmlFor="current-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="current-tab"
          name="current-tab"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              onClick={() => setCurrentTab(tab.name)}
              className={classNames(
                tab.current
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  </div>);
}

export default Header;
