import React from "react";

import Spinner from "components/Icons/Spinner";
import { monthName } from "components/utils";

const RelatedAssetTable = ({
  assets,
  loading,
  page,
  pageInfo,
  handlePrevious,
  handleNext
}) => {

  const headers = [
    {'label': 'Asset ID'},
    {'label': 'Report Name'},
    {'label': 'Month'},
    {'label': 'Year'},
    {'label': 'Partner Revenue'},
    {'label': 'Views'}
  ]

  return (<>
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {headers.map((header, idx) => {
                    return (<th
                      key={idx}
                      scope="col"
                      className="sticky top-0 z-10 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header.label}
                    </th>)
                  })}
                </tr>
              </thead>
              <tbody className="bg-white">
                {loading && (
                  <tr className={'bg-gray-50'}>
                    <td colSpan={headers.length} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <Spinner /> Loading...
                    </td>
                  </tr>
                )}
                {!loading && assets?.map((asset, assetIdx) => {
                  return (<tr key={assetIdx} className={assetIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {asset.asset_id}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">{asset.report?.name}</td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">{monthName(asset.report_feed?.month)}</td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">{asset.report_feed?.year}</td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                      {parseFloat(asset.partner_revenue).toFixed(5)}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                      {asset.data && Object.entries(asset.data).map(([key, value]) => {
                        return (key.toLowerCase()).includes('owned view') && <span key={key} className='flex'>{value}</span>
                      })}
                    </td>
                  </tr>)
                })}
              </tbody>
            </table>
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Page <span className="font-medium">{page}</span>&nbsp;of&nbsp;
                  <span className="font-medium">{pageInfo.pages}</span>
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <button type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  onClick={() => handlePrevious()}
                >
                  Previous
                </button>
                <button type="button"
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  onClick={() => handleNext()}
                >
                  Next
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default RelatedAssetTable
