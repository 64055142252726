import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from 'routes/Router';
import { AuthContext } from 'stores/auth';

// Importing firebase initialises the client
// eslint-disable-next-line no-unused-vars
import 'firebase';

import './styles/index.css';
import '@yaireo/tagify/dist/tagify.css';
import { useTranslation } from 'react-i18next';

function App() {
  const authStore = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (authStore.hasToken()) {
      authStore.fetchUser().then(() => {
        if (isMounted) {
          i18n.changeLanguage(authStore.user?.preferred_language);
          setLoading(false);
        }
      }).catch(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [authStore]);

  return (
    <BrowserRouter>
      <Router loading={loading} />
    </BrowserRouter>
  );
}

export default App;
