import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { classNames } from 'components/utils';

const AssetSearchBar = ({ loading, setQuery, query }) => {
  const history = useHistory();
  const { search } = useLocation();
  const [searchQ, setSearchQ] = useState(query || '');

  const handleSearch = (evt) => {
    if (evt.key === 'Enter') {
      const val = evt.target.value;
      setSearchQ(val);
      if (setQuery) setQuery(val);

      let params = new URLSearchParams(search);
      if (val && val !== '') {
        params.set('q', val);
      } else {
        params.delete('q', val);
      }
      history.push({pathname: '/search/assets', search: params.toString()});
    }
  }

  const handleChange = (evt) => {
    setSearchQ(evt.target.value);
  }

  useEffect(() => {
    if (query === null || query === undefined) {
      setSearchQ('')
    }
  }, [query]);

  return (<>
    <div className="relative flex items-stretch flex-grow min-w-24 focus-within:z-10">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        name="search"
        id="search"
        className={classNames(
          "focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300",
          loading ? "" : ""
        )}
        placeholder="Search"
        onKeyPress={(e) => handleSearch(e)}
        onChange={handleChange}
        value={searchQ}
        disabled={loading}
      />
      <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
          ⌘ Enter
        </kbd>
      </div>
    </div>
  </>);
}

export default AssetSearchBar;
