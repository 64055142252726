import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { kFormatter } from 'components/utils';
import { chartOverview } from 'api/charts';
import Spinner from 'components/Icons/Spinner';
import SortDropdown from './SortDropdown';
import FilterDropdown from './FilterDropdown';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OverviewChart = () => {
  const { t } = useTranslation();
  const sortOptions = [
    { value: 'date', label: 'Date' },
    { value: 'amount', label: 'Amount' }
  ]

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState();
  const [selected, setSelected] = useState(sortOptions[0]);
  const [isAscending, setIsAscending] = useState(true);
  const [optionsFrom, setOptionsFrom] = useState();
  const [optionsTo, setOptionsTo] = useState();
  const [selectedFrom, setSelectedFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [labels, setLabels] = useState();
  const [grossRevenue, setGrossRevenue] = useState();
  const [explorationShare, setExplorationShare] = useState();

  useEffect(() => {
    setLoading(true);
    chartOverview(selected.value, isAscending).then(res => {
      const chartLabels = res.data?.labels //.map((lbl) => lbl.split(' ')[0])
      setLabels(chartLabels)
      let labelOptions = chartLabels.map((label) => { return { value: label, label: label } })
      setOptionsFrom(labelOptions)
      setOptionsTo(labelOptions)
      setSelectedFrom(labelOptions[0])
      setSelectedTo(labelOptions[labelOptions.length - 1])
      setGrossRevenue(res.data?.gross_revenue)
      setExplorationShare(res.data?.exploration_share)
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: 'Exploration Share',
            data: res.data?.exploration_share,
            backgroundColor: 'rgb(255,99,132)',
            borderRadius: 2
          },
          {
            label: t('Gross Revenue'),
            data: res.data?.gross_revenue,
            backgroundColor: 'rgb(50,222,190)',
            borderRadius: 5
          },
        ],
      });
      setLoading(false);
    })
  }, [isAscending, selected]);

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return 'client ' + value;
          }
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return kFormatter(value);
          }
        }
      },
    }
  };

  const handleFilter = (start, end) => {
    setSelectedFrom(start)
    setSelectedTo(end)
    let startIndex = labels.indexOf(start.value)
    let endIndex = labels.indexOf(end.value) + 1
    let chartLabels = labels.slice(startIndex, endIndex)

    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: 'Exploration Share',
          data: explorationShare,
          backgroundColor: 'rgb(255,99,132)',
        },
        {
          label: t('Gross Revenue'),
          data: grossRevenue,
          backgroundColor: 'rgb(50,222,190)',
        },
      ],
    });
  }

  return (<div className="p-6">
    {!loading && chartData &&
      <div className="w-1/3 float-right">
        <SortDropdown
          options={sortOptions}
          selected={selected}
          setSelected={setSelected}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
        />
      </div>
    }
    Recent Month Performance
    {!loading && chartData && <Bar options={options} data={chartData} />}
    {loading && (<div>
      <Spinner /><span className="">Loading data...</span>
    </div>)}
  </div>);
}

export default OverviewChart;

