import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Avatar from 'react-avatar';

import Spinner from "components/Icons/Spinner"
import { AuthContext } from "stores/auth"
import { useTranslation } from 'react-i18next';
import AccountClientSearch from "./AccountClientSearch";

const AccountSelectCard = ({ loading, assignedClients }) => {
  const { t } = useTranslation();
  let history = useHistory()
  const authStore = useContext(AuthContext)
  const [query, setQuery] = useState('')

  const selectAccount = (client) => {
    localStorage.setItem("selectedAccount", client.client_detail.uid)
    history.go(0)
  }

  const handleLogout = () => {
    authStore.logout()
  }

  const filteredClients =
    query === ''
      ? assignedClients
      : assignedClients.filter((client) => {
          return client.client_detail.name.toLowerCase().includes(query.toLowerCase())
    })
  
  return (
    <div className="bg-blue-800">
      <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
        <div className="text-center">
          <p className="mt-2 text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            Select Account
          </p>
          <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-100 sm:mt-5 sm:text-2xl">
            Choose which client dashboard to view.
          </p>
        </div>
      </div>

      <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
        <div className="relative z-0">
          <div className="absolute inset-0 h-5/6 bg-blue-800 lg:h-2/3" />
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative lg:grid lg:grid-cols-7">
              <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <AccountClientSearch
                  loading={loading}
                  setQuery={setQuery}
                  query={query}
                />
                {/* <div className="relative z-10 rounded-lg shadow-xl">
                  <div
                    className="pointer-events-none absolute inset-0 rounded-lg border-2"
                    aria-hidden="true"
                  />
                  <div className="max-h-80 overflow-y-auto border-t bg-white rounded-md">
                    <ul className="divide-y divide-gray-200">
                      {loading && (
                        <div className="bg-white py-3 flex items-center justify-center">
                          <Spinner />
                        </div>
                      )}

                      {!loading && assignedClients.length === 0 && (
                        <>
                          <div className="bg-white py-3 flex items-center justify-center text-sm text-gray-500">
                            No assigned clients.&nbsp;
                            <span
                              onClick={() => handleLogout()}
                              className="block text-sm font-medium text-blue-300 group-hover:text-white cursor-pointer"
                            >
                              {t('Log out')}
                            </span>
                          </div>
                        </>
                      )}

                      {!loading && filteredClients.length === 0 && (
                        <>
                          <div className="bg-white py-3 flex items-center justify-center text-sm text-gray-500">
                            No matching clients found.&nbsp;
                            <span
                              onClick={() => handleLogout()}
                              className="block text-sm font-medium text-blue-300 group-hover:text-white cursor-pointer"
                            >
                              {t('Log out')}
                            </span>
                          </div>
                        </>
                      )}

                      {filteredClients.map((client) => (
                        <li
                          onClick={() => selectAccount(client)}
                          key={client.uid}
                          className="px-6 py-4 cursor-pointer hover:bg-gray-200 rounded-md flex"
                        >
                          <img
                            className="h-10 w-10 rounded-full"
                            src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            alt=""
                          />
                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                              {client.client_detail.name}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div> */}

                {loading && (
                  <div className="bg-white py-3 flex items-center justify-center sm:rounded-xl">
                    <Spinner />
                  </div>
                )}

                {!loading && assignedClients.length === 0 && (
                  <>
                    <div className="bg-white py-3 flex items-center justify-center text-sm text-gray-500 sm:rounded-xl">
                      No assigned clients.&nbsp;
                      <span
                        onClick={() => handleLogout()}
                        className="block text-sm font-medium text-blue-300 group-hover:text-white cursor-pointer"
                      >
                        {t('Log out')}
                      </span>
                    </div>
                  </>
                )}

                <ul role="list"
                  className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                    {filteredClients.map((client) => {
                      return (<li key={client.id} className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6">
                      <div className="flex min-w-0 gap-x-4">
                        <Avatar className="flex-none rounded-full" size="50px" name={client.client_detail.name} />
                        <div className="min-w-0 flex-auto content-center">
                          <p className="text-lg font-semibold leading-6 text-gray-900">
                            {/* <span className="absolute inset-x-0 -top-px bottom-0" /> */}
                            <span
                              className="cursor-pointer hover:underline"
                              onClick={() => selectAccount(client)}
                            >
                              {client.client_detail.name}
                            </span>
                          </p>
                          {/* <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p className="whitespace-nowrap">
                              Due on <time dateTime={'2023-03-17T00:00Z'}>{'March 17, 2023'}</time>
                            </p>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            <p className="truncate">Created by {'Leslie Alexander'}</p>
                          </div> */}
                        </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-4">
                        <button
                          type="button"
                          className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => selectAccount(client)}
                        >
                          View account
                        </button>
                        {/* <div
                          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-800 sm:block cursor-pointer"
                          onClick={() => selectAccount(client)}
                        >
                          View account<span className="sr-only">, {client.client_detail.name}</span>
                        </div> */}
                        {/* <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" /> */}
                      </div>
                      </li>)
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSelectCard
