import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'stores/auth';
import { getUserAssignedClients } from 'api/users';
import AccountSelectCard from './components/AccountSelectCard';

const AccountSelect = () => {
  const authStore = useContext(AuthContext);
  const user = authStore.user
  const [loading, setLoading] = useState()
  const [assignedClients, setAssignedClients] = useState([]);

  useEffect(() => {
    setLoading(true)
    getUserAssignedClients(user?.id).then(res => {
      setAssignedClients(res?.data)
      setLoading(false)
    })
  }, [user]);

  return (<div className='bg-white h-screen'>
    <AccountSelectCard loading={loading} assignedClients={assignedClients} />
  </div>)
}

export default AccountSelect;

