import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

import { useQuery } from 'utils';
import { searchAsset } from 'api/core';

import AssetSearchBar from './components/AssetSearchBar';
import AssetTable from './components/AssetTable';

const Search = () => {
  const componentIsMounted = useRef(true);
  let query = useQuery();
  const [loading, setLoading] = useState(false);
  const [searchQ, setSearchQ] = useState(query.get('q') || '');
  const [assets, setAssets] = useState([]);

  const fetchData = useCallback(() => {
    // setLoading(true);
    const filters = {
      asset_id: searchQ,
      show: query.get('show') || 'all'
    };

    console.log('Get', filters)

    searchAsset(filters).then(res => {
      console.log(res.data?.data);
      setAssets(res.data?.data);
      // setLoading(false);
    }).catch(err => {
      console.log(err.response);
      // setLoading(false);
    });
  }, [searchQ])

  useEffect(() => {
    fetchData();
  }, [searchQ]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      <div className="max-w-full">
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">Search Asset</h2>
          </div>
        </div>
        <div className="md:flex md:items-center md:justify-between my-5">
          <div className="mt-4 flex-1 flex md:mt-0">
            <AssetSearchBar
              loading={loading}
              setQuery={setSearchQ}
              query={searchQ}
            />
          </div>
        </div>
        <AssetTable
          loading={loading}
          assets={assets}
        />
      </div>
    </div>
  )
}

export default Search
