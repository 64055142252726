import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';

import { AuthContext } from 'stores/auth';
import ClientDetailRoutes from './ClientDetailRoutes';

const Detail = () => {
  const authStore = useContext(AuthContext);
  let { uid } = useParams();

  return useObserver(() => {
    const selectedAccount = authStore.selectedAccount;
    const clientUid = selectedAccount ? selectedAccount.uid : uid;

    return (<ClientDetailRoutes clientUid={clientUid} />);
  });
}

export default Detail;
