import React from 'react';
import { useLocalStore } from 'mobx-react-lite';

export const CommonContext = React.createContext();

const CommonStore = ({ children }) => {
  const store = useLocalStore(() => ({
    ready: false,
    sampleId: null,
    reportAvailable: true,

    setSampleId: (sampleId) => {
      store.sampleId = sampleId
    },

    setReportAvailable: (val) => {
      store.reportAvailable = val
    }
  }));

  return <CommonContext.Provider value={store}>
    {children}
  </CommonContext.Provider>
};

export default CommonStore;
