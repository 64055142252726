import PrivateAPI from './private'

export const getAssets = (page) => {
  return PrivateAPI.get('/api/assets/',  {
    'page': page
  });
};

export const getClientAssets = (page, uid) => {
  return PrivateAPI.get('/api/assets/client/',  {
    'page': page,
    'uid': uid
  });
};

export const getClientReports = (page, uid) => {
  return PrivateAPI.get('/api/assets/client/reports/',  {
    'page': page,
    'uid': uid
  });
};

export const getClientAssetStat = (uid, title) => {
  return PrivateAPI.get('/api/assets/client/asset-stats/',  {
    'title': title,
    'uid': uid
  });
};
