import React from 'react';
import NumberFormat from 'react-number-format';

const TableRow = ({ data, rates }) => {
  const isYoutube = data?.report?.is_youtube;
  const rateKey = isYoutube ? 'youtube' : data?.report?.rate_key;
  const rate = rates.find(e => e.rate_type === rateKey);

  return (<tr key={data.id} className="border-b border-gray-200">
    <td className="py-4 pl-4 pr-3 text-md sm:pl-6 md:pl-0">
      <div className="font-medium text-gray-900">{data.report?.name}</div>
    </td>
    <td className="hidden py-4 px-3 text-right text-md text-gray-500 sm:table-cell">
      <NumberFormat
        value={parseFloat(data.gross_revenue).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
    </td>
    <td className="hidden py-4 px-3 text-right text-md text-gray-500 sm:table-cell">
      {rate ? rate.rate : parseInt((parseFloat(data.exploration_share) / parseFloat(data.gross_revenue)) * 100)}%
    </td>
    <td className="hidden py-4 px-3 text-right text-md text-red-500 sm:table-cell">
      <NumberFormat
        value={parseFloat(data.exploration_share).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
    </td>
    <td className="hidden py-4 px-3 text-right text-md text-gray-700 sm:table-cell">
      <NumberFormat
        value={parseFloat(data.net_revenue).toFixed(2)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
    </td>
  </tr>);
}

export default TableRow;
