import React from 'react';

import AssetLabelsTable from './components/AssetLabelsTable';

const AssetLabels = () => {

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-5">
      <div className="md:flex md:items-center md:justify-between my-5">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Asset Labels</h2>
        </div>
      </div>
      <div className="my-5">
        <AssetLabelsTable />
      </div>
    </div>
  )
}

export default AssetLabels;
