import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom"
import { classNames } from 'components/utils';

const Filters = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [filter, setFilter] = useState('all');

  const tabs = [
    { name: 'All', key: 'all' },
    // { name: 'Active', key: 'active' },
    // { name: 'Archived', key: 'archived' }
  ]

  const handleFilter = (key) => {
    setFilter(key);
  };

  useEffect(() => {
    let params = new URLSearchParams(search);
    if (filter) {
      params.set("show", filter);
    } else {
      params.delete("show");
    };
    history.push({search: params.toString()});
  }, [filter, history, search]);

  return (<>
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={filter}
        >
          {tabs.map((tab) => (
            <option key={tab.key}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => {
            const current = tab.key === filter;
            return (
              <span
                key={tab.name}
                className={classNames(
                  current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:bg-gray-200 hover:text-gray-700',
                  'px-3 py-2 font-medium text-sm rounded-md cursor-pointer'
                )}
                aria-current={current ? 'page' : undefined}
                onClick={() => handleFilter(tab.key)}
              >
                {tab.name}
              </span>
          )})}
        </nav>
      </div>
    </div>
  </>);
}

export default Filters;
