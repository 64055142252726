import { fetchBankUSDetails, updateBankUSDetailStatus } from "api/expclient"
import { Fragment, useEffect, useState, useRef, useContext } from "react"
import { useParams } from "react-router-dom"
import { format, parse} from "date-fns"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from "@heroicons/react/20/solid"
import classNames from "styles/classNames"
import { AuthContext } from "stores/auth"

const BankUSDetail = () => {

  const { id } = useParams()
  const [bankUS, setBankUS] = useState()
  const authStore = useContext(AuthContext);

  const [open, setOpen] = useState(false)
  const [action, setAction] = useState("")

  const cancelButtonRef = useRef(null)

  useEffect(()=>{
    fetchBankUSDetails(id)
    .then(res=>{
      setBankUS(res.data)
    }).catch(err=>{
      console.log(err.response)
    })
  }, [id])

  const onApprove = e => {
    e.preventDefault()
    setOpen(true)
    setAction("approved")
  }

  const onReject = e => {
    e.preventDefault()
    setOpen(true)
    setAction("rejected")
  }

  const onUpdate = e => {
    e.preventDefault()
    updateBankUSDetailStatus(id, { status: action })
    .then(res=>{
      console.log("res", res)
    }).catch(err=>{
      console.log(err.response)
    }).finally(()=>{
      setOpen(false)
    })
  }

  return (
    <>
    <section aria-labelledby="client-bank-us-info">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        {/* Header */}
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2 w-full flex justify-between">
              <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Bank US Details</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Must match the details of the bank account owner</p>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 align-middle inline-block min-w-full sm:px-6">
          <div>
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">First Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.first_name}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Last Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.last_name}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.dob && format(parse(bankUS?.dob, 'MM/dd/yyyy', new Date()), "MMMM d, yyyy")}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Contact Number</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.contact_number}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Social Security # or Tax ID #</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.ssti}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Account Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">{bankUS?.account_type}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Account Number</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.account_number}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Routing Number</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.routing_number}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Bank Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.bank_name}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Branch Contact Number</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.bank_contact_number}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Branch Address / City / State / Zip</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{bankUS?.bank_address}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </section>

    {['superuser', 'admin'].includes(authStore?.user.role) && <div className="flex justify-center space-x-6 mt-4">
      <button
        onClick={onApprove}
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >Approve</button>
      <button
        onClick={onReject}
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >Reject</button>
    </div>}

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className={classNames(
                    "mx-auto flex items-center justify-center h-12 w-12 rounded-full",
                    action === "approved" ? "bg-green-100" : "bg-red-100"
                  )}>
                    {action === "approved" && <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />}
                    {action === "rejected" && <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Status Update
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to {action} this request?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={onUpdate}
                  >
                    {action === "approved" ? "Approve" : "Reject"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    </>
  )
}

export default BankUSDetail
